import React, { memo, useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';
import PriceTab from '../../../ProductEntries/Details/ProductsTab/ProductsTable/DialogProductCost/PriceTab';
import calculateProductCost from '../../../../helpers/calculateProductCost';
import ValuesFromCost from '../../../ProductEntries/Details/ProductsTab/ProductsTable/DialogProductCost/ValuesFromCost';
import ValuesFromPrice from '../../../ProductEntries/Details/ProductsTab/ProductsTable/DialogProductCost/ValuesFromPrice';
import newEntryProduct from '../../../../constant/newEntryProduct';
import ConversionTab from '../../../ProductEntries/Details/ProductsTab/ProductsTable/DialogProductCost/ConversionTab';
import getTasks from '../../../../services/melhorGestao/tasks';
import { useAuth } from '../../../../contexts/auth';
import getProductPrice from '../../../../helpers/getProductPrice';
import DiscountsFromPrice from '../../../ProductEntries/Details/ProductsTab/ProductsTable/DialogProductCost/DiscountsFromPrice';

const useStyles = makeStyles({
  pricePaper: {
    height: 75,
    marginRight: 10,
    padding: 10,
  },
  priceTypography: {
    fontSize: '1.30rem',
  },
  offerPricePaper: {
    height: 75,
    marginRight: 10,
    padding: 10,
  },
  offerLabelTypography: {
    color: '#fff',
  },
  offerPriceTypography: {
    color: '#fff',
    fontSize: '1.30rem',
  },
  offerPriceSmallTypography: {
    color: '#fff',
    fontSize: '0.85rem',
  },
});

function PriceficationTab({ productForm, setProductForm }) {
  const classes = useStyles();

  const loggedUser = useAuth().user;
  const [loading, setLoading] = useState(true);
  const [bouthCostValue, setBouthCostValue] = useState(0);
  const [priceValue, setPriceValue] = useState(0);
  const [costValue, setCostValue] = useState(0);
  const [ipiValue, setIpiValue] = useState(0);
  const [stValue, setStValue] = useState(0);
  const [othersCostValue, setOthersCostValue] = useState(0);
  const [shippingValue, setShippingValue] = useState(0);
  const [simplesValue, setSimplesValue] = useState(0);
  const [profitValue, setProfitValue] = useState(0);
  const [mlPriceValue, setMlPriceValue] = useState(0);
  const [difalValue, setDifalValue] = useState(0);
  const [salesCostValue, setSalesCostValue] = useState(0);
  const [oldProductForm, setOldProductForm] = useState(productForm);

  const [taxes, setTaxes] = useState({
    ...newEntryProduct,
    ...(productForm.taxFields?.taxes || {}),
    ...(productForm.taxFields?.unity?.unityId
      ? {
          unityId: productForm.taxFields.unity.unityId,
          unity: productForm.taxFields.unity.name,
          unityRate: productForm.taxFields.unity.quantity,
        }
      : {}),
    ...(productForm.taxFields?.entryUnity?.unityId
      ? {
          entryUnityId: productForm.taxFields.entryUnity.unityId,
          entryUnity: productForm.taxFields.entryUnity.name,
          entryUnityRate: productForm.taxFields.entryUnity.quantity,
        }
      : {}),
  });

  const handleChangePriceConfirmed = useCallback(() => {
    setTaxes((oldFields) => ({
      ...oldFields,
      priceConfirmed: !taxes.priceConfirmed,
    }));
  }, [taxes.priceConfirmed, setTaxes]);

  const handleChangeUnityRate = useCallback(
    (unityRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        unityRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeEntryUnityRate = useCallback(
    (entryUnityRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        entryUnityRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeUnity = useCallback(
    (unity) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        unity: unity?.name,
        unityId: unity?.unityId,
      })),
    [setTaxes],
  );

  const handleChangeEntryUnity = useCallback(
    (entryUnity) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        entryUnity: entryUnity?.name,
        entryUnityId: entryUnity?.unityId,
      })),
    [setTaxes],
  );

  const handleChangeEntryUnitaryCost = useCallback(
    (entryUnitaryCost) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        entryUnitaryCost,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeIpiRate = useCallback(
    (ipiRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        ipiRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeStRate = useCallback(
    (stRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        stRate,
        hasSt: stRate > 0,
        simples: stRate > 0 ? 0.04 : 0.1,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeShippingRate = useCallback(
    (shippingRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        shippingRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeOthersCostRate = useCallback(
    (othersCostRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        othersCostRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeDifalValue = useCallback(
    (difalRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        difalRate,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeSimples = useCallback(
    (simples) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        simples,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeProfitMargin = useCallback(
    (profitMargin) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        profitMargin,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeAdditionalOnline = useCallback(
    (additionalOnline) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        additionalOnline,
        hasAdditionalOnline: additionalOnline !== 0,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangeHasAdditionalOnline = useCallback(
    () =>
      setTaxes((oldFields) => ({
        ...oldFields,
        hasAdditionalOnline: !oldFields.hasAdditionalOnline,
        priceConfirmed: false,
      })),
    [setTaxes],
  );

  const handleChangePhysicalStoreDiscount = useCallback(
    (physicalStoreDiscount) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        physicalStoreDiscount,
      })),
    [setTaxes],
  );

  const handleChangePhysicalDiscountRate = useCallback(
    (physicalDiscountRate) =>
      setTaxes((oldFields) => ({
        ...oldFields,
        physicalDiscountRate,
      })),
    [setTaxes],
  );

  const {
    paymentOfferPrice,
    paymentOfferName,
    paymentOffer,
    offerPercentage,
    paymentOfferPercentage,
  } = getProductPrice(productForm);

  useEffect(() => {
    let offerPriceField = {};

    if (taxes.priceConfirmed) {
      setOldProductForm(productForm);

      if (productForm.offer) {
        offerPriceField = {
          offerPrice: priceValue * (1 - Math.abs(offerPercentage) / 100),
        };
      }

      setProductForm((oldFields) => ({
        ...oldFields,
        ...offerPriceField,
        price: priceValue,
        cost: bouthCostValue,
        taxFields: {
          ...oldFields.taxFields,
          unity: {
            unityId: taxes.unityId,
            name: taxes.unity,
            quantity: taxes.unityRate,
          },
          entryUnity: {
            unityId: taxes.entryUnityId,
            name: taxes.entryUnity,
            quantity: taxes.entryUnityRate,
          },
          taxes: {
            ...taxes,
            salesCost: salesCostValue,
          },
        },
      }));
    }

    if (!taxes.priceConfirmed) {
      setProductForm(oldProductForm);
    }
  }, [taxes.priceConfirmed]);

  useEffect(() => {
    const productCost = calculateProductCost(taxes);

    let mlPrice = productCost.price + 6;
    if (mlPrice < 20) {
      mlPrice += 5;
    }

    setDifalValue(productCost.difal);
    setBouthCostValue(productCost.bouthCost || productForm.cost);
    setMlPriceValue(mlPrice);
    setCostValue(productCost.cost);
    setIpiValue(productCost.ipi);
    setStValue(productCost.st);
    setOthersCostValue(productCost.othersCost);
    setShippingValue(productCost.shipping);
    setSimplesValue(productCost.simples);
    setProfitValue(productCost.profit);
    setPriceValue(productCost.price);
    setSalesCostValue(productCost.salesCost);
  }, [taxes]);

  const [priceManagerTask, setPriceManagerTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const stockManager = tasksStaff.find((task) => task.name === 'Gerenciar preço');
        if (stockManager && stockManager.active) {
          setPriceManagerTask(stockManager.active);
        }
      }

      if (productForm.productId) {
        const response = await Axios.get('/entries/by-product', {
          params: {
            productId: productForm.productId,
            status: 'Pedido concluído',
          },
        });

        const entryOrder = response.data;

        if (entryOrder) {
          setTaxes(
            ...entryOrder.products
              .filter(
                (entryProduct) =>
                  entryProduct.sincProductId &&
                  entryProduct.sincProductId === productForm.productId,
              )
              .map((entryProduct) => ({
                ...newEntryProduct,
                ...entryProduct,
                priceConfirmed: false,
              })),
          );
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Precificação</Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Paper elevation={2} className={classes.pricePaper}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">Preço normal</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="baseline">
                        <Typography>R$</Typography>
                        <Typography variant="h5" className={classes.priceTypography}>
                          {numberToRealWithPeriod(productForm.price)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              {productForm.offer ? (
                <Grid item>
                  <Paper
                    elevation={2}
                    className={classes.offerPricePaper}
                    sx={{ backgroundColor: 'primary.main' }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption" className={classes.offerLabelTypography}>
                          Preço no cartão
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline">
                          <Typography className={classes.offerPriceSmallTypography}>R$</Typography>
                          <Typography variant="h5" className={classes.offerPriceTypography}>
                            {numberToRealWithPeriod(productForm.offerPrice)}
                          </Typography>
                          <Typography variant="caption" className={classes.offerLabelTypography}>
                            ({offerPercentage}%)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ) : null}
              {paymentOffer ? (
                <Grid item>
                  <Paper
                    elevation={2}
                    className={classes.pricePaper}
                    sx={{ backgroundColor: 'primary.main' }}
                  >
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="caption" className={classes.offerLabelTypography}>
                          Preço no {paymentOfferName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline">
                          <Typography className={classes.offerPriceSmallTypography}>R$</Typography>
                          <Typography variant="h5" className={classes.offerPriceTypography}>
                            {numberToRealWithPeriod(paymentOfferPrice)}
                          </Typography>
                          <Typography variant="caption" className={classes.offerLabelTypography}>
                            ({paymentOfferPercentage}%)
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading ? (
        <>
          <Grid item>
            <ConversionTab
              selectedProduct={taxes}
              setSelectedProduct={setTaxes}
              handleChangeUnityRate={handleChangeUnityRate}
              handleChangeUnity={handleChangeUnity}
              handleChangeEntryUnityRate={handleChangeEntryUnityRate}
              handleChangeEntryUnity={handleChangeEntryUnity}
              handleChangeEntryUnitaryCost={handleChangeEntryUnitaryCost}
              finalQuantityValue={taxes.quantity}
              costValue={costValue}
              blockFields={!priceManagerTask}
            />
          </Grid>
          <Grid item>
            <ValuesFromCost
              selectedProduct={taxes}
              handleChangeIpiRate={handleChangeIpiRate}
              handleChangeStRate={handleChangeStRate}
              handleChangeShippingRate={handleChangeShippingRate}
              handleChangeOthersCostRate={handleChangeOthersCostRate}
              handleChangeDifalValue={handleChangeDifalValue}
              ipiValue={ipiValue}
              stValue={stValue}
              shippingValue={shippingValue}
              othersCostValue={othersCostValue}
              bouthCostValue={bouthCostValue}
              difalValue={difalValue}
              blockFields={!priceManagerTask}
            />
          </Grid>
          <Grid item>
            <ValuesFromPrice
              selectedProduct={taxes}
              handleChangeSimples={handleChangeSimples}
              handleChangeProfitMargin={handleChangeProfitMargin}
              handleChangeAdditionalOnline={handleChangeAdditionalOnline}
              blockFields={!priceManagerTask}
            />
          </Grid>
          <Grid item>
            <DiscountsFromPrice
              priceValue={priceValue}
              selectedProduct={taxes}
              handleChangePhysicalStoreDiscount={handleChangePhysicalStoreDiscount}
              handleChangePhysicalDiscountRate={handleChangePhysicalDiscountRate}
            />
          </Grid>
          <Grid item>
            <PriceTab
              selectedProduct={taxes}
              priceValue={priceValue}
              costValue={costValue}
              difalValue={difalValue}
              ipiValue={ipiValue}
              stValue={stValue}
              shippingValue={shippingValue}
              othersCostValue={othersCostValue}
              simplesValue={simplesValue}
              profitValue={profitValue}
              mlPriceValue={mlPriceValue}
              bouthCostValue={bouthCostValue}
              salesCostValue={salesCostValue}
              handleChangePriceConfirmed={handleChangePriceConfirmed}
              handleChangeHasAdditionalOnline={handleChangeHasAdditionalOnline}
              blockFields={!priceManagerTask}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}

export default memo(PriceficationTab);
