import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CustomInput from '../../../CustomInput';
import { formatHandleError } from '../../../../helpers/formatData';
import getModifiedKeys from '../../../../helpers/getModifiedKeys';

function IntegrationsPaper({ integration, selectedCompany, setSelectedCompany, setSnackbar }) {
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(integration.configuration);
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const keys = getModifiedKeys(fields, integration.configuration, []);

    if (keys.length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [fields, integration.configuration]);

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setFields((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  const saveIntegrationConfig = async () => {
    if (integration.key) {
      setLoading(true);

      try {
        const response = await Axios.put(`/configuration/companies/${selectedCompany.companyId}`, {
          [integration.key]: fields,
        });

        setSelectedCompany(response.data);
      } catch (error) {
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao salvar a configuração',
          error,
        });
      }

      setLoading(false);
    }
  };

  return (
    <Paper>
      <Box padding={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5">Integração {integration.name}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {Object.keys(fields)
                .filter((key) => key !== '_id')
                .map((key) => (
                  <Grid item key={key} xs={6}>
                    <CustomInput
                      label={key}
                      name={key}
                      onChange={handleChange}
                      variant="outlined"
                      value={fields[key] || ''}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              onClick={saveIntegrationConfig}
              disabled={!formChanged}
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default IntegrationsPaper;
