import Axios from 'axios';
import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SquareWidget from './SquareWidget';
import FinanceResumeTopBar from './TopBar';
import FabGroupButtons from '../../Common/FabGroupButtons';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import {
  formatHandleError,
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../helpers/formatData';
import SimpleBackdrop from '../../Common/SimpleBackdrop';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

const emptyResume = {
  year: {
    totalReceipt: 0,
    totalPayment: 0,
    countReceipt: 0,
    countPayment: 0,
  },
  months: [],
};

function FinanceResume() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [company, setCompany] = useState(null);
  const [preferenceDate, setPreferenceDate] = useState(new Date());
  const [refreshToken, setRefreshToken] = useState(Math.random());

  const [loadingAllFinances, setLoadingAllFinances] = useState(true);
  const [totalFinances, setTotalFinances] = useState(emptyResume);

  const handleRefreshResume = () => setRefreshToken(Math.random());

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingAllFinances(true);
        const responseTotal = await Axios.get('/financial/resume/values', {
          params: {
            company: company?._id,
            date: preferenceDate,
          },
        });
        const financesResumeTotal = responseTotal.data.financesResume;
        setTotalFinances(financesResumeTotal);
        setLoadingAllFinances(false);
      } catch (error) {
        setLoadingAllFinances(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao buscar resumo do financeiro',
          error,
        });
      }
    };
    fetchData();
  }, [company, preferenceDate, refreshToken]);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [openDialogCapital, setOpenDialogCapital] = useState(false);
  const handleOpenDialogCapital = useCallback(() => setOpenDialogCapital(true), []);
  const handleCloseDialogCapital = useCallback(() => setOpenDialogCapital(false), []);

  const [type, setType] = useState('Entrada');
  const [capitalValue, setCapitalValue] = useState(0);

  const handleChangeValue = (event) => {
    const newTotalValue = onlyNumbersValue(event.target.value);
    setCapitalValue(formatReal(newTotalValue));
  };

  const handleAddCapitalValue = async () => {
    try {
      await Axios.post('/financial/capital', {
        value: capitalValue,
        type,
      });

      handleRefreshResume();
      handleCloseDialogCapital();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao adicionar o capital',
        error,
      });
    }
  };

  return (
    <Box position="relative">
      <SimpleBackdrop loading={loadingAllFinances} />
      <Box marginTop={2}>
        <FinanceResumeTopBar
          company={company}
          setCompany={setCompany}
          preferenceDate={preferenceDate}
          setPreferenceDate={setPreferenceDate}
        />
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={2}>
          {!loadingAllFinances ? (
            <>
              <Grid item>
                <SquareWidget
                  darkMode
                  title={totalFinances.year.yearNumber}
                  totalReceipt={totalFinances.year.totalReceipt}
                  totalPayment={totalFinances.year.totalPayment}
                  countReceipt={totalFinances.year.countReceipt}
                  countPayment={totalFinances.year.countPayment}
                  lastTitle={totalFinances.year.lastYearNumber}
                  lastClosingValue={totalFinances.year.lastYearClosing}
                  cumulativeClosing={totalFinances.year.cumulativeClosing}
                />
              </Grid>
              {totalFinances.months.map((month) => (
                <Grid item key={month.month}>
                  <SquareWidget
                    title={month.month}
                    totalReceipt={month.totalReceipt}
                    totalPayment={month.totalPayment}
                    countReceipt={month.countReceipt}
                    countPayment={month.countPayment}
                    lastTitle={month.lastMonth}
                    lastClosingValue={month.lastMonthClosing}
                    cumulativeClosing={month.cumulativeClosing}
                  />
                </Grid>
              ))}
            </>
          ) : null}
        </Grid>
      </Box>
      {openDialogCapital ? (
        <SimpleDialog
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogCapital}
          handleConfirmButton={handleAddCapitalValue}
          disableConfirmButton={!capitalValue}
          content={
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="standard" size="small">
                  <InputLabel>Tipo</InputLabel>
                  <Select onChange={(event) => setType(event.target.value)} value={type}>
                    <MenuItem value="Entrada">Entrada</MenuItem>
                    <MenuItem value="Saída">Saída</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  label="Valor"
                  value={capitalValue ? numberToRealWithPeriod(capitalValue) : '0,00'}
                  onChange={handleChangeValue}
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  variant="standard"
                />
              </Grid>
            </Grid>
          }
          dialogTitle="Adicionar capital"
          handleClose={handleCloseDialogCapital}
          openDialog={openDialogCapital}
        />
      ) : null}
      <FabGroupButtons
        fabButtons={[
          {
            title: 'Adicionar capital',
            onClick: () => handleOpenDialogCapital(),
            icon: <AddIcon />,
            featured: true,
          },
        ]}
      />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default FinanceResume;
