import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { memo, useState, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import { numberToReal, numberToRealWithPeriod } from '../../../../../../../helpers/formatData';
import UnityAutocomplete from '../../../../../../Common/UnityAutocomplete';
import CustomInput from '../../../../../../CustomInput';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function ConversionTab({
  selectedProduct,
  setSelectedProduct,
  handleChangeUnityRate,
  handleChangeUnity,
  handleChangeEntryUnityRate,
  handleChangeEntryUnity,
  handleChangeEntryUnitaryCost,
  finalQuantityValue,
  costValue,
  blockFields,
}) {
  const classes = useStyles();

  const handleChangeEntryQuantity = (event) => {
    setSelectedProduct((oldFields) => ({
      ...oldFields,
      entryQuantity: event.target.value,
    }));
  };

  const changeEntryUnitaryCost = (event) => {
    handleChangeEntryUnitaryCost(event.target.value);
  };

  const [selectedEntryUnity, setSelectecEntryUnity] = useState(
    selectedProduct.entryUnityId
      ? {
          unityId: selectedProduct.entryUnityId,
          name: selectedProduct.entryUnity,
          quantity: selectedProduct.entryUnityRate,
        }
      : null,
  );

  const [selectedUnity, setSelectecUnity] = useState(
    selectedProduct.unityId
      ? {
          unityId: selectedProduct.unityId,
          name: selectedProduct.unity,
          quantity: selectedProduct.unityRate,
        }
      : null,
  );

  const handleSelectedEntryUnity = useCallback((event, newValue) => {
    handleChangeEntryUnityRate(newValue?.quantity || 1);
    handleChangeEntryUnity(newValue);
    setSelectecEntryUnity(newValue);
  });

  const handleSelectedUnity = useCallback((event, newValue) => {
    handleChangeUnityRate(newValue?.quantity || 1);
    handleChangeUnity(newValue);
    setSelectecUnity(newValue);
  });

  return (
    <Paper>
      <Box padding={2}>
        <Box marginBottom={1}>
          <Typography variant="h3">Conversão de quantidade</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Qtd. compra"
                      value={selectedProduct.entryQuantity}
                      onChange={handleChangeEntryQuantity}
                      size="medium"
                      error={selectedProduct.entryQuantity <= 0}
                      disabled={blockFields}
                      number
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <UnityAutocomplete
                      setSnackbar
                      handleOnChange={handleSelectedEntryUnity}
                      selectedUnity={selectedEntryUnity}
                      label={selectedEntryUnity ? 'Unidade compra' : 'Unidade'}
                      variant="filled"
                      disabled={blockFields}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <CustomInput
                      label="Qtd. final"
                      value={finalQuantityValue}
                      size="medium"
                      disabled
                      number
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <UnityAutocomplete
                      setSnackbar
                      handleOnChange={handleSelectedUnity}
                      selectedUnity={selectedUnity}
                      label={selectedUnity ? 'Unidade venda' : 'Unidade'}
                      variant="filled"
                      disabled={blockFields}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomInput
                      label="Preço (entrada)"
                      value={selectedProduct.entryUnitaryCost}
                      onChange={changeEntryUnitaryCost}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      error={selectedProduct.entryUnitaryCost <= 0}
                      disabled={blockFields}
                      number
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      label="Total (entrada)"
                      value={numberToRealWithPeriod(
                        selectedProduct.entryUnitaryCost * selectedProduct.entryQuantity,
                      )}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <CustomInput
                      label="Preço base"
                      value={numberToReal(costValue + selectedProduct.additionalOnline)}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomInput
                      label="Total base"
                      value={numberToRealWithPeriod(
                        (costValue + selectedProduct.additionalOnline) * selectedProduct.quantity,
                      )}
                      size="medium"
                      InputStartAdornment="R$"
                      InputAdornmentStyle={classes.inputAdornment}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default memo(ConversionTab);
