import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { numberToReal } from '../../../../../helpers/formatData';
import SearchField from '../../../../Common/SearchField';
import SimpleImage from '../../../../Common/SimpleImage';
import { createProduct } from '../../../../../services/melhorGestao/products';
import newProduct from '../../../../../constant/newProduct';
import SimpleDialog from '../../../../Common/SimpleDialog';

const useStyles = makeStyles(() => ({
  smallCell: {
    width: 40,
  },
  mediumCell: {
    width: 120,
  },
  noPaddingCell: {
    padding: 0,
  },
  img: {
    width: '100%',
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
  },
  searchTopRight: {
    position: 'fixed',
    top: 20,
  },
  fabLeftAbove: {
    position: 'fixed',
    bottom: 60,
  },
  fabLeftBelow: {
    position: 'fixed',
    bottom: 20,
  },
  tableRowEmptyStock: {
    backgroundColor: '#ffefef',
    '&:hover': {
      backgroundColor: '#ffe4e4',
    },
    cursor: 'pointer',
  },
  tableRowProduct: {
    cursor: 'pointer',
  },
  tableColumnCash: {
    width: 90,
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
  inputPropsCenter: {
    textAlign: 'center',
  },
}));
function TableProductsModal({
  additionalTitle,
  productsList,
  handleChangePage,
  page,
  limit,
  sortDirection,
  setSortDirection,
  submitSearchProducts,
  handleClickItem,
}) {
  const classes = useStyles();

  const [loadingCreateProduct, setLoadingCreateProduct] = useState(false);
  const [openComfirmCreateProductDialog, setOpenComfirmCreateProductDialog] = useState(false);
  const handleCloseComfirmCreateProductDialog = () => setOpenComfirmCreateProductDialog(false);

  const handleOpenComfirmCreateProductDialog = () => {
    setOpenComfirmCreateProductDialog(true);
  };

  const handleCreateProduct = async () => {
    setLoadingCreateProduct(true);
    const productName = additionalTitle.split(' ').slice(1).join(' ');

    const createdProduct = await createProduct({
      product: { ...newProduct, name: productName, technicalName: productName },
    });

    if (createdProduct) {
      handleClickItem(createdProduct);
    }
    setLoadingCreateProduct(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6">Selecione o produto</Typography>
            {additionalTitle ? <Typography variant="caption">{additionalTitle}</Typography> : null}
          </Grid>
          {additionalTitle && productsList.length === 0 ? (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleOpenComfirmCreateProductDialog}
              >
                Criar novo produto
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paperTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell}>SKU</TableCell>
                <TableCell className={classes.smallCell}>Imagem</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Estoque</TableCell>
                <TableCell>Preço</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList.map((product) => (
                <TableRow
                  className={
                    product.stock - product.reservedStock > 0
                      ? classes.tableRowProduct
                      : classes.tableRowEmptyStock
                  }
                  key={product.productId}
                  onClick={() => handleClickItem(product)}
                >
                  <TableCell>{product.productId}</TableCell>
                  <TableCell>
                    {product.images && product.images.length > 0 ? (
                      <SimpleImage
                        src={product.images[0].src}
                        height={75}
                        width={75}
                        alt={product.name}
                        className={classes.img}
                      />
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography>{product.name}</Typography>
                      </Grid>
                      {product.suppliers[0] && product.suppliers[0].factoryId && (
                        <Grid item>
                          {product.suppliers.map((supplier) => (
                            <Tooltip
                              key={supplier.userId + supplier.factoryId}
                              title="Código de fábrica"
                            >
                              <Chip size="small" label={supplier.factoryId} />
                            </Tooltip>
                          ))}
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        <>
                          <Typography align="center">Estoque: {product.stock}</Typography>
                          <Typography align="center">Reservado: {product.reservedStock}</Typography>
                          <Typography align="center">
                            Disponível: {product.stock - product.reservedStock}
                          </Typography>
                        </>
                      }
                    >
                      <Box>{product.stock - product.reservedStock}</Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>R${numberToReal(product.price)}</TableCell>
                </TableRow>
              ))}

              {productsList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography>Nenhum produto para exibir</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                      <Typography>Página: {page}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={page === 1}
                        className={classes.closeButton}
                        onClick={() => handleChangePage()}
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        disabled={productsList.length < limit}
                        className={classes.closeButton}
                        onClick={() => handleChangePage({ next: true })}
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </Grid>
                    {productsList.length === limit && (
                      <>
                        <Grid item>
                          <Typography>Sentido: {sortDirection}endente</Typography>
                        </Grid>
                        <Grid item>
                          {sortDirection === 'desc' && (
                            <IconButton onClick={() => setSortDirection('asc')}>
                              <KeyboardArrowUpIcon />
                            </IconButton>
                          )}
                          {sortDirection === 'asc' && (
                            <IconButton onClick={() => setSortDirection('desc')}>
                              <KeyboardArrowDownIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </Grid>

      <Box position="relative" right="235px">
        <Box className={classes.searchTopRight}>
          <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar produto" />
        </Box>
      </Box>

      {openComfirmCreateProductDialog && (
        <SimpleDialog
          openDialog={openComfirmCreateProductDialog}
          handleClose={handleCloseComfirmCreateProductDialog}
          dialogTitle="Novo produto"
          dialogText="Um novo produto será criado, esta ação não pode ser desfeita."
          content={additionalTitle}
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          confirmButtonLoading={loadingCreateProduct}
          handleCancelButton={handleCloseComfirmCreateProductDialog}
          handleConfirmButton={handleCreateProduct}
        />
      )}
    </Grid>
  );
}

export default memo(TableProductsModal);
