import { Alert, Box, Snackbar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import React, { useState, useEffect, useCallback, memo } from 'react';
import newEntryOrder from '../../constant/newEntryOrder';
import searchEntriesOrders from '../../services/melhorGestao/productEntries';
import FabGroupButtons from '../Common/FabGroupButtons';
import ProductEntry from './Details';
import TableEntries from './Table';
import TopBarEntries from './TopBar';

function ProductEntries() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(40);
  const [entriesList, setEntriesList] = useState([]);
  const [countEntries, setCountEntries] = useState(0);
  const [loadingEntriesList, setLoadingEntriesList] = useState(false);
  const [refreshSearchToken, setRefreshSearchToken] = useState(Math.random());
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [filterOrderStatus, setFilterOrderStatus] = useState([
    {
      name: 'Orçamento',
      active: false,
    },
    {
      name: 'Pedido confirmado',
      active: false,
    },
    {
      name: 'Pedido faturado',
      active: false,
    },
    {
      name: 'Pedido concluído',
      active: false,
    },
  ]);

  useEffect(() => {
    searchEntriesOrders({
      searchText: searchTextSubmited,
      skip: page * limit - limit,
      limit,
      filterOrderStatus,
      filterOrderCompany,
      setLoadingEntriesList,
      setEntriesList,
      setCountEntries,
      setSnackbar,
    });
  }, [searchTextSubmited, page, limit, filterOrderStatus, filterOrderCompany, refreshSearchToken]);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, entriesList]
  );

  const handleRefresh = () => {
    setPage(1);
    setSearchTextSubmited('');
    setRefreshSearchToken(Math.random());
  };

  const handleRefreshSearch = () => setRefreshSearchToken(Math.random());
  const [selectedEntryOrder, setSelectedEntryOrder] = useState(newEntryOrder);
  const [openProductEntry, setOpenProductEntry] = useState(false);

  const handleOpenProductEntry = useCallback(() => {
    setSelectedEntryOrder(newEntryOrder);
    setOpenProductEntry(true);
  }, []);

  const handleCloseProductEntry = useCallback(() => {
    setOpenProductEntry(false);
    handleRefreshSearch();
  }, []);

  const [fabButtons, setFabButtons] = useState([]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Atualizar listagem',
        onClick: handleRefreshSearch,
        icon: <RefreshIcon />,
      },
      {
        title: 'Nova entrada',
        onClick: handleOpenProductEntry,
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  const selectEntryOrder = useCallback((entry) => {
    setSelectedEntryOrder(entry);
    setOpenProductEntry(true);
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <TopBarEntries
          filterOrderCompany={filterOrderCompany}
          setFilterOrderCompany={setFilterOrderCompany}
          filterOrderStatus={filterOrderStatus}
          setFilterOrderStatus={setFilterOrderStatus}
          submitSearchOrders={setSearchTextSubmited}
        />
      </Box>
      <Box marginTop={2}>
        <TableEntries
          entriesList={entriesList}
          countEntries={countEntries}
          selectEntryOrder={selectEntryOrder}
          selectedEntryOrder={selectedEntryOrder}
          loadingEntriesList={loadingEntriesList}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
        />
      </Box>

      {openProductEntry ? (
        <ProductEntry
          openProductEntry={openProductEntry}
          handleCloseProductEntry={handleCloseProductEntry}
          selectedEntryOrder={selectedEntryOrder}
          handleRefresh={handleRefresh}
        />
      ) : null}

      <FabGroupButtons fabButtons={fabButtons} />
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default memo(ProductEntries);
