import {
  Box,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Link as LinkMaterial,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { formatDate, numberToRealWithPeriod } from '../../../helpers/formatData';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import CustomPagination from '../../Common/CustomPagination';

const useStyles = makeStyles({
  smallCell: {
    width: 70,
  },
  mediumCell: {
    width: 125,
  },
  bigCell: {
    width: 300,
  },
  tableClick: {
    cursor: 'pointer',
  },
});

function TableFinances({
  financesList,
  selectFinance,
  totalFinancesValue,
  quantityFinances,
  loadingFinances,
  handleChangePage,
  page,
  limit,
  checkedFinances,
  setCheckedFinances,
  categoriesList,
}) {
  const classes = useStyles();

  const getLabelColorStatus = (payment) => {
    if (payment.payed) {
      return { color: '#00c600' };
    }
    return { color: '#ff2222' };
  };

  const handleCheckFinance = ({ financeId }) => {
    let newCheckedFinances = checkedFinances;
    newCheckedFinances = newCheckedFinances.filter(
      (checkedFinanceId) => checkedFinanceId !== financeId,
    );
    newCheckedFinances.push(financeId);

    return setCheckedFinances(newCheckedFinances);
  };

  const handleUncheckFinance = ({ financeId }) => {
    let newCheckedFinances = checkedFinances;
    newCheckedFinances = newCheckedFinances.filter(
      (checkedFinanceId) => checkedFinanceId !== financeId,
    );

    return setCheckedFinances(newCheckedFinances);
  };

  const isCheckedFinance = ({ financeId }) => {
    if (checkedFinances.filter((checkedFinanceId) => checkedFinanceId === financeId).length > 0) {
      return true;
    }
    return false;
  };

  const handleUncheckAll = () => setCheckedFinances([]);

  const handleCheckAll = () => {
    const financesIds = financesList.map((finance) => finance.financeId);
    return setCheckedFinances(financesIds);
  };

  const findById = (list, id) => list.find((item) => item._id === id);

  return (
    <Box marginBottom={5}>
      <SimpleBackdrop loading={loadingFinances} absolutePosition />

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallCell} align="center">
                  <Tooltip
                    title={
                      <Typography align="center">
                        {checkedFinances.length > 0
                          ? 'Desselecionar todos os itens'
                          : 'Selecionar todos os itens desta página'}
                      </Typography>
                    }
                  >
                    <Checkbox
                      onClick={checkedFinances.length > 0 ? handleUncheckAll : handleCheckAll}
                      checked={checkedFinances.length > 0}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell className={classes.smallCell}>Código</TableCell>
                <TableCell className={classes.mediumCell}>Tipo</TableCell>
                <TableCell className={classes.mediumCell}>Método</TableCell>
                <TableCell className={classes.bigCell}>Categoria</TableCell>
                <TableCell className={classes.bigCell}>Usuário</TableCell>
                <TableCell className={classes.mediumCell}>Valor</TableCell>
                <TableCell className={classes.mediumCell}>Data</TableCell>
                <TableCell className={classes.mediumCell}>Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {financesList && financesList.length > 0 ? (
                financesList.map((finance) => (
                  <TableRow
                    key={finance._id}
                    onClick={() =>
                      selectFinance({
                        ...finance,
                        categories: finance.categories.map((category) =>
                          findById(categoriesList, category),
                        ),
                      })
                    }
                    className={classes.tableClick}
                  >
                    <TableCell align="center">
                      <Checkbox
                        checked={isCheckedFinance({ financeId: finance.financeId })}
                        onClick={(event) => {
                          if (isCheckedFinance({ financeId: finance.financeId })) {
                            handleUncheckFinance({ financeId: finance.financeId });
                          } else {
                            handleCheckFinance({ financeId: finance.financeId });
                          }
                          event.stopPropagation();
                        }}
                      />
                    </TableCell>
                    <TableCell>{finance.financeId}</TableCell>
                    <TableCell>{finance.type}</TableCell>
                    <TableCell>
                      {finance.method}{' '}
                      {finance.link && !finance.payed ? (
                        <Tooltip title="Link para pagamento">
                          <LinkMaterial
                            href={finance.link}
                            onClick={(event) => event.stopPropagation()}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconButton size="small">
                              <DescriptionOutlinedIcon />
                            </IconButton>
                          </LinkMaterial>
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {finance.categories && finance.categories.length > 0
                        ? finance.categories
                            .map((category) => findById(categoriesList, category))
                            .pop().name
                        : 'Sem categoria'}
                    </TableCell>
                    <TableCell>{finance.user.name}</TableCell>
                    <TableCell>R$ {numberToRealWithPeriod(finance.value)}</TableCell>
                    <TableCell>{formatDate(finance.payDate)}</TableCell>
                    <TableCell>
                      <Typography
                        style={{
                          color: getLabelColorStatus(finance).color,
                        }}
                      >
                        {finance.payed ? 'Pago' : 'Não pago'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>
                          {loadingFinances ? 'Carregando' : 'Nenhum financeiro nesta página'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <Grid container alignItems="center" justifyContent="space-around">
                    <Grid item>
                      <Typography variant="h3">
                        Total R$ {numberToRealWithPeriod(totalFinancesValue)} de {quantityFinances}{' '}
                        registros
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CustomPagination
                        page={page}
                        total={quantityFinances}
                        limit={limit}
                        handleChangePage={handleChangePage}
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default memo(TableFinances);
