import { Chip, Grid, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { grey } from '@mui/material/colors';
import { formatCpfCpnj, numberToRealWithPeriod } from '../../../../helpers/formatData';
import LimitTypography from '../../../Common/LimitTypography';

const useStyles = makeStyles((theme) => ({
  typographyCursor: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelectedMain: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: `${theme.palette.grey[300]} !important`,
    },
  },
}));

function UsersTableBody({
  usersList,
  selectedUser,
  handleClickUser,
  page,
  handleChangePage,
  selectedUserInList,
  loadingUsersList,
  handleOnlyOpenDetail,
  docAlongWithName,
}) {
  const classes = useStyles();

  const getColorSelectedUser = (userSelected, userSelectedInList, user) => {
    if (userSelectedInList && userSelectedInList.userId === user.userId) {
      return classes.tableRowSelectedMain;
    }
    if (userSelected && userSelected.userId === user.userId) {
      return classes.tableRowSelected;
    }
    return classes.tableRow;
  };

  const creditValueTypography = (value) => {
    let color = '#0d00fc';

    if (value < 0) {
      color = '#ff2222';
    }

    return (
      <Typography variant="body2" style={{ color }}>
        R$ {numberToRealWithPeriod(value)}
      </Typography>
    );
  };

  return (
    <TableBody>
      {usersList.length > 0 &&
        usersList.map((user) => (
          <TableRow
            key={user.userId}
            className={getColorSelectedUser(selectedUser, selectedUserInList, user)}
            onClick={() => handleClickUser(user)}
          >
            <TableCell>
              {handleOnlyOpenDetail ? (
                <Chip
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOnlyOpenDetail(user);
                  }}
                  label={user.userId}
                  color="primary"
                  size="small"
                />
              ) : (
                `${user.userId}`
              )}
            </TableCell>
            <TableCell>
              <Grid container direction="column">
                <Grid item>
                  <LimitTypography
                    text={user.documents?.cnpj ? user.documents.razaoSocial : user.name}
                    maxLength={docAlongWithName ? 30 : 60}
                    variant="caption"
                  />
                </Grid>
                {docAlongWithName && (user.documents?.cpf || user.documents?.cnpj) ? (
                  <Grid container spacing={1}>
                    {user.documents?.cpf ? (
                      <Grid item>
                        <Typography variant="caption" color={grey[500]}>
                          {formatCpfCpnj(user.documents.cpf)}
                        </Typography>
                      </Grid>
                    ) : null}
                    {user.documents?.cnpj ? (
                      <Grid item>
                        <Typography variant="caption" color={grey[500]}>
                          {formatCpfCpnj(user.documents.cnpj)}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                ) : null}
              </Grid>
            </TableCell>
            {!docAlongWithName ? (
              <TableCell>
                <Typography variant="caption">
                  {formatCpfCpnj(user.documents?.cpf || user.documents?.cnpj || '')}
                </Typography>
              </TableCell>
            ) : null}
            <TableCell>
              <Typography variant="caption">{user.addresses?.[0]?.city || ''}</Typography>
            </TableCell>
            <TableCell>
              {docAlongWithName ? (
                <Typography variant="body2" style={{ color: '#ff2222' }}>
                  R$ {numberToRealWithPeriod(user.credit?.totalPending || 0)}
                </Typography>
              ) : (
                creditValueTypography(user.credit?.totalValue || 0)
              )}
            </TableCell>
            {!docAlongWithName ? (
              <TableCell>
                <Typography variant="body2" style={{ color: '#ff2222' }}>
                  R$ {numberToRealWithPeriod(user.credit?.totalPending || 0)}
                </Typography>
              </TableCell>
            ) : null}
            {!docAlongWithName ? (
              <TableCell>
                <Typography variant="body2" style={{ color: '#00c600' }}>
                  R$ {numberToRealWithPeriod(user.credit?.negativeCreditLimit || 0)}
                </Typography>
              </TableCell>
            ) : null}
          </TableRow>
        ))}

      {page !== 1 && usersList.length === 0 && (
        <TableRow>
          <TableCell colSpan={7}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography onClick={() => handleChangePage()} className={classes.typographyCursor}>
                  Nenhuma pessoa nesta página, clique aqui para voltar a página.
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}

      {page === 1 && usersList.length === 0 && (
        <TableRow>
          <TableCell colSpan={7}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography>
                  {loadingUsersList ? 'Carregando' : 'Nenhuma pessoa para exibir'}
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default UsersTableBody;
