import { Box, Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { searchProducts } from '../../../../services/melhorGestao/products';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import TableProductsModal from './Table';

const useStyles = makeStyles({
  paper: {
    height: '90vh',
  },
});

function DialogSelectOneProduct({
  openDialog,
  handleClose,
  handleConfirmSelect,
  productName = '',
  preSearchText = '',
}) {
  const classes = useStyles();

  const [submitedSearchText, setSubmitedSearchText] = useState(preSearchText);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    searchProducts({
      searchText: submitedSearchText,
      skip: page * limit - limit,
      limit,
      sortDirection,
      setLoadingProductsList,
      setProductsList,
      filtersOptions: [{ id: 'showOnlyNormalProducts', active: true }],
    });
  }, [page, limit, sortDirection, submitedSearchText]);

  const handleChangePage = useCallback(
    (next) => {
      let nextTablePage = 0;

      if (next) {
        if (productsList.length < limit) {
          nextTablePage = page;
        } else {
          nextTablePage = page + 1;
        }
      } else {
        nextTablePage = page - 1;
        if (nextTablePage <= 0) {
          nextTablePage = 1;
        }
      }

      setPage(nextTablePage);
    },
    [limit, page, productsList],
  );

  const handleClickItem = useCallback(
    (product) => {
      handleConfirmSelect(product);
      return handleClose();
    },
    [handleClose, handleConfirmSelect],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openDialog}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Box margin={2}>
        <TableProductsModal
          additionalTitle={productName}
          productsList={productsList}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          handleClickItem={handleClickItem}
          onClose={handleClose}
          submitSearchProducts={setSubmitedSearchText}
        />
      </Box>
      <SimpleBackdrop loading={loadingProductsList} />
    </Dialog>
  );
}

export default memo(DialogSelectOneProduct);
