import { Box, Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import ProductsModal from '../../../Products/TableModal';
import ProductsTable from './ProductsTable';
import ProfitabilityBlok from './ProfitabilityBlok';
import InvoiceActonsBlok from '../InvoiceActionsBlok';
import OccurrencesDialog from './OccurrencesDialog';

function ProductsTab({
  orderForm,
  setOrderForm,
  initialOrderForm,
  setInitialOrderForm,
  userItemsBought,
  devolutions,
  setSnackbar,
}) {
  const [openOccurrencesDialog, setOpenOccurrencesDialog] = useState(false);
  const handleOpenOccurrencesDialog = () => setOpenOccurrencesDialog(true);
  const handleCloseOccurrencesDialog = () => setOpenOccurrencesDialog(false);

  const [openDialogProducts, setOpenDialogProducts] = useState(false);
  const handleOpenDialogProducts = useCallback(() => setOpenDialogProducts(true), []);
  const handleCloseDialogProducts = useCallback(() => setOpenDialogProducts(false), []);

  const handleSelectProductsToOrder = useCallback(
    ({ selectedProducts }) => {
      setOrderForm((oldFields) => ({
        ...oldFields,
        items: [...selectedProducts],
      }));
      return handleCloseDialogProducts();
    },
    [handleCloseDialogProducts],
  );

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Itens no pedido</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              {orderForm.status !== 'Orçamento' ? (
                <Grid item>
                  <Tooltip
                    title={
                      <Typography align="center">
                        Mostra e adiciona ocorrências no pedido
                      </Typography>
                    }
                  >
                    <Box>
                      <Button variant="contained" onClick={handleOpenOccurrencesDialog}>
                        Ocorrências
                      </Button>
                    </Box>
                  </Tooltip>
                </Grid>
              ) : null}
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {orderForm.source && orderForm.status === 'Orçamento'
                        ? 'Altere ou adicione items ao pedido'
                        : null}
                      {orderForm.source && orderForm.status !== 'Orçamento'
                        ? 'Não pode alterar os items de um pedido já finalizado'
                        : null}
                      {!orderForm.source ? 'Selecione uma origem' : null}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleOpenDialogProducts}
                      disabled={orderForm.status !== 'Orçamento' || !orderForm.source}
                    >
                      Escolher Produtos
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orderForm.items?.length > 0 && (
        <Grid item xs={12}>
          <ProductsTable itemsList={orderForm.items} />
        </Grid>
      )}
      {devolutions?.length > 0 && orderForm.items?.length === 0 && (
        <Grid item xs={12}>
          <ProductsTable itemsList={orderForm.items} devolution />
        </Grid>
      )}
      {orderForm.items?.length > 0 && (
        <Grid item xs={12}>
          <ProfitabilityBlok
            orderForm={orderForm}
            setOrderForm={setOrderForm}
            initialOrderForm={initialOrderForm}
          />
        </Grid>
      )}
      {orderForm.nfe || (orderForm.invoice && orderForm.invoice.key) ? (
        <InvoiceActonsBlok
          orderId={orderForm.orderId}
          invoice={orderForm.invoice}
          setOrderForm={setOrderForm}
          setInitialOrderForm={setInitialOrderForm}
          setSnackbar={setSnackbar}
        />
      ) : null}
      {orderForm.items && orderForm.items.length > 0 ? (
        <Grid item>
          <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
            <Grid item>Peso dos produtos:</Grid>
            <Grid item>
              <Typography variant="h3">
                {orderForm.items
                  .map((item) => item.quantity * parseFloat(item.package.weight))
                  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                  .toFixed(3)
                  .replace(/\./g, ',')}{' '}
                Kg
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {openOccurrencesDialog ? (
        <OccurrencesDialog
          openOccurrencesDialog={openOccurrencesDialog}
          handleCloseOccurrencesDialog={handleCloseOccurrencesDialog}
          orderForm={orderForm}
        />
      ) : null}
      {openDialogProducts ? (
        <ProductsModal
          openProductsModal={openDialogProducts}
          handleCloseProductsModal={handleCloseDialogProducts}
          handleConfirmSelect={handleSelectProductsToOrder}
          alreadySelectedProducts={orderForm.items}
          userItemsBought={userItemsBought}
          orderSource={orderForm.source}
        />
      ) : null}
    </Grid>
  );
}

export default ProductsTab;
