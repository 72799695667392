import { Box, Grid, IconButton, Menu, MenuItem, SvgIcon, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MailIcon from '@mui/icons-material/Mail';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  menuButtom: {
    padding: '5px',
    width: '70px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomActive: {
    padding: '5px',
    width: '70px',
    height: '64px',
    borderBottom: '2px solid #ffb000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomInside: {
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 16%)',
    },
    borderRadius: 6,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#fff',
  },
  menuIconActive: {
    width: '1.5em',
    height: '1.5em',
    color: '#ffb000',
  },
  menuIconSmall: {
    color: '#fff',
  },
  menuIconActiveSmall: {
    color: '#ffb000',
  },
  userLoggedName: {
    cursor: 'pointer',
  },
});

function DesktopMenu({
  logout,
  loggedUser,
  menuItems,
  activeMenu,
  setActiveMenu,
  className,
  openUserDialogDetail,
}) {
  const classes = useStyles();

  const [menuOrigin, setMenuOrigin] = useState(null);

  const handleOpenMenu = (event) => setMenuOrigin(event.currentTarget);
  const handleCloseMenu = () => setMenuOrigin(null);

  return (
    <>
      <Grid item className={className}>
        <Grid container alignItems="center">
          {menuItems.map((menuItem) => (
            <Grid item key={menuItem.name}>
              <Link
                to={menuItem.link}
                className={classes.link}
                onClick={() => setActiveMenu(menuItem.link)}
              >
                <Tooltip title={<Typography align="center">{menuItem.name}</Typography>}>
                  <Box
                    className={
                      activeMenu === menuItem.link ? classes.menuButtomActive : classes.menuButtom
                    }
                  >
                    <Box className={classes.menuButtomInside}>
                      <SvgIcon
                        component={menuItem.icon}
                        className={
                          activeMenu === menuItem.link ? classes.menuIconActive : classes.menuIcon
                        }
                      />
                    </Box>
                  </Box>
                </Tooltip>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item className={className}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item onClick={openUserDialogDetail} className={classes.userLoggedName}>
            Olá {loggedUser.name.split(' ')[0]}
          </Grid>
          <Grid item>
            <IconButton onClick={handleOpenMenu}>
              <SettingsIcon
                className={
                  activeMenu === '/configuracoes'
                    ? classes.menuIconActiveSmall
                    : classes.menuIconSmall
                }
              />
            </IconButton>
          </Grid>

          <Grid item>
            <Menu
              id="basic-menu"
              anchorEl={menuOrigin}
              open={Boolean(menuOrigin)}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MenuItem component={Link} to="/mercado-livre" onClick={handleCloseMenu}>
                <CheckCircleIcon />
                Mercado Livre
              </MenuItem>
              <MenuItem component={Link} to="/configuracoes" onClick={handleCloseMenu}>
                <SettingsIcon />
                Configurações
              </MenuItem>
              <MenuItem component={Link} to="/emailmarketing" onClick={handleCloseMenu}>
                <MailIcon />
                E-mail
              </MenuItem>
              <MenuItem onClick={logout}>
                <ExitToAppIcon /> Sair/Deslogar
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default DesktopMenu;
