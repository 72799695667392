import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Axios from 'axios';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import searchDiscountCoupons from '../../../../services/melhorGestao/discountCoupons';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import CouponDialog from './CouponDialog';
import newDiscountCoupon from '../../../../constant/newDiscountCoupon';
import SimpleDialog from '../../../Common/SimpleDialog';
import { formatDate, formatHandleError } from '../../../../helpers/formatData';
import CustomPagination from '../../../Common/CustomPagination';

const useStyles = makeStyles(() => ({
  paper: {
    width: 650,
    padding: 10,
  },
  tableRowAndDelete: {
    cursor: 'pointer',
    marginTop: 5,
  },
  typographyCursor: {
    cursor: 'pointer',
  },
}));

function DiscountCoupon() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [fieldsDiscountCoupon, setFieldsDiscountCoupon] = useState(newDiscountCoupon);
  const [openDialogDiscountCoupon, setOpenDialogDiscountCoupon] = useState(false);
  const [selectCouponToDelete, setSelectCouponToDelete] = useState();
  const [openDialogConfirmDeleteCoupon, setOpenDialogConfirmDeleteCoupon] = useState(false);

  const handleOpenDialogDiscountCoupon = () => {
    setOpenDialogDiscountCoupon(true);
  };

  const handleOpenNewDiscountCouponDialog = () => {
    setOpenDialogDiscountCoupon(true);
    setFieldsDiscountCoupon(newDiscountCoupon);
  };

  const handleCloseDialogDiscountCoupon = () => {
    setOpenDialogDiscountCoupon(false);
  };

  const handleOpenDialogConfirmDeleteDiscountCoupon = (coupon) => {
    setSelectCouponToDelete(coupon);
    setOpenDialogConfirmDeleteCoupon(true);
  };

  const handleCloseDialogConfirmDeleteDiscountCoupon = () => {
    setOpenDialogConfirmDeleteCoupon(false);
  };

  const handleEditDiscountCoupon = (coupon) => {
    setFieldsDiscountCoupon(coupon);
    handleOpenDialogDiscountCoupon();
  };

  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [discountCouponsList, setDiscountCouponsList] = useState([]);
  const [loadingDiscountCouponsList, setLoadingDiscountCouponsList] = useState(false);
  const [totalDiscountCoupons, setTotalDiscountCoupons] = useState(0);

  const [refreshToken, setRefreshToken] = useState(Math.random());

  const handleRefreshDiscountCoupons = () => setRefreshToken(Math.random());

  const getDiscountCoupons = async () => {
    searchDiscountCoupons(
      {
        limit,
        skip: page * limit - limit,
        setLoadingDiscountCouponsList,
        setDiscountCouponsList,
        setTotalDiscountCoupons,
      },
      [page, limit],
    );
  };

  useEffect(() => {
    getDiscountCoupons();
  }, [totalDiscountCoupons, refreshToken]);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, discountCouponsList],
  );

  const handleDeleteCoupon = async () => {
    try {
      await Axios.delete(`/discountCoupon`, {
        params: {
          id: selectCouponToDelete._id,
        },
      });
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao excluir o cupom de desconto',
        error,
      });
    }
    setOpenDialogConfirmDeleteCoupon(false);
    handleRefreshDiscountCoupons();
  };

  return (
    <Paper>
      <Box padding={2}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h5">Cupom de desconto no carrinho</Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleOpenNewDiscountCouponDialog}>
              Novo cupom
            </Button>
          </Grid>
        </Grid>
        <Box marginTop={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Desconto no</TableCell>
                <TableCell align="center">Código do Cupom</TableCell>
                <TableCell align="center">Desconto</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Validade</TableCell>
                <TableCell align="center">Nº de Usos</TableCell>
                <TableCell align="center">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loadingDiscountCouponsList && discountCouponsList.length > 0
                ? discountCouponsList.map((coupon) => (
                    <TableRow
                      key={coupon._id}
                      align="center"
                      onClick={() => handleEditDiscountCoupon(coupon)}
                      className={classes.tableRowAndDelete}
                    >
                      <TableCell align="center">{coupon.couponId}</TableCell>
                      <TableCell align="center">{coupon.discountType}</TableCell>
                      <TableCell align="center">{coupon.code}</TableCell>
                      <TableCell align="center">{coupon.discountPercentage} %</TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            <Typography align="center">
                              {coupon.active ? 'Ativo' : 'Inativo'}
                            </Typography>
                          }
                        >
                          <FiberManualRecordIcon color={coupon.active ? 'primary' : 'disabled'} />
                        </Tooltip>
                      </TableCell>
                      <TableCell align="center">
                        {`${formatDate(coupon.startDate)} - ${
                          coupon.endDate ? formatDate(coupon.endDate) : 'Ilimitado'
                        }`}
                      </TableCell>
                      <TableCell align="center">{coupon.usedBy.length}</TableCell>
                      <TableCell>
                        <Tooltip title={<Typography align="center">Apagar</Typography>}>
                          <DeleteForeverIcon
                            onClick={(event) => {
                              event.stopPropagation();
                              return handleOpenDialogConfirmDeleteDiscountCoupon(coupon);
                            }}
                            className={classes.tableRowAndDelete}
                          />
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                : null}

              {page !== 1 && discountCouponsList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography
                          onClick={() => handleChangePage()}
                          className={classes.typographyCursor}
                        >
                          Nenhum cupom nesta página, clique aqui para voltar a página.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}

              {page === 1 && discountCouponsList?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>
                          {loadingDiscountCouponsList ? 'Carregando' : 'Nenhum cupom para exibir'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <CustomPagination
                    page={page}
                    total={totalDiscountCoupons}
                    limit={limit}
                    handleChangePage={handleChangePage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <SimpleBackdrop loading={loadingDiscountCouponsList} absolutePosition />
        </Box>
      </Box>

      {openDialogDiscountCoupon && (
        <CouponDialog
          openDialogDiscountCoupon={openDialogDiscountCoupon}
          handleOpenDialogDiscountCoupon={handleOpenDialogDiscountCoupon}
          handleCloseDialogDiscountCoupon={handleCloseDialogDiscountCoupon}
          fieldsDiscountCoupon={fieldsDiscountCoupon}
          setFieldsDiscountCoupon={setFieldsDiscountCoupon}
          handleRefreshDiscountCoupons={handleRefreshDiscountCoupons}
          setSnackbar={setSnackbar}
        />
      )}
      {openDialogConfirmDeleteCoupon && (
        <SimpleDialog
          openDialog={openDialogConfirmDeleteCoupon}
          handleClose={handleCloseDialogConfirmDeleteDiscountCoupon}
          dialogTitle="Excluir Cupom de Desconto"
          dialogText="Confirma a exclusão do cupom? (A ação não poderá ser desfeita)"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmDeleteDiscountCoupon}
          handleConfirmButton={handleDeleteCoupon}
        />
      )}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Paper>
  );
}

export default DiscountCoupon;
