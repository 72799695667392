import { Box, Button, Grid, Tooltip, Typography, Link as LinkMaterial } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { formatCpfCpnj, formatPhone, onlyNumbersValue } from '../../../../helpers/formatData';
import newOrder from '../../../../constant/newOrder';
import CustomInput from '../../../CustomInput';
import OtherDocumentDialog from './OtherDocumentDialog';
import CustomerDialog from './CustomerDialog';
import { isValidIe } from '../../../../services/melhorGestao/validator';
import ItemsBoughtButton from './ItemsBoughtButton';
import UserOrdersButton from './UserOrdersButton';
import ProfitabilityButton from './ProfitabilityButton';
import CustomerCreditBlok from './CustomerCreditBlok';
import ProfitabilityBlok from './ProfitabilityBlok';

function CustomerTab({
  orderForm,
  setOrderForm,
  userItemsBought,
  setUserItemsBought,
  setSnackbar,
}) {
  const [userOrders, setUserOrders] = useState([]);

  const [openDialogCustomer, setOpenDialogCustomer] = useState(false);
  const handleOpenDialogCustomer = useCallback(() => setOpenDialogCustomer(true), []);
  const handleCloseDialogCustomer = useCallback(() => setOpenDialogCustomer(false), []);

  const [openDialogOtherDocument, setOpenDialogOtherDocument] = useState(false);
  const handleOpenOtherDocumentDialog = useCallback(() => setOpenDialogOtherDocument(true), []);
  const handleCloseOtherDocumentDialog = useCallback(() => setOpenDialogOtherDocument(false), []);

  const [officialDocument, setOfficialDocument] = useState(orderForm.customer.officialDocument);
  const [othersDocuments, setOthersDocuments] = useState(
    orderForm.customer.documents.othersDocuments
      ? orderForm.customer.documents.othersDocuments
      : [],
  );

  const handleSelectCustomerToOrder = useCallback(
    ({ user }) => {
      if (user?.registrationObservations && user?.registrationObservations.length > 0) {
        const findUnlockedReason = user.registrationObservations.find(
          (observation) => !observation.blockedCustomer && observation.showMessageInList,
        );

        if (findUnlockedReason) {
          setSnackbar({
            message: findUnlockedReason.reason,
            open: true,
            type: 'info',
          });
        }
      }

      let newOfficialDocument = {
        name: user.name,
      };

      if (user.documents.pessoa === 'PF') {
        newOfficialDocument = {
          ...newOfficialDocument,
          cpf: user.documents.cpf,
        };
      }
      if (user.documents.pessoa === 'PJ') {
        newOfficialDocument = {
          ...newOfficialDocument,
          cnpj: user.documents.cnpj,
          inscricaoEstadual: user.documents.inscricaoEstadual,
        };
      }

      if (user.documents.othersDocuments) {
        setOthersDocuments(user.documents.othersDocuments);
      }

      setOfficialDocument(newOfficialDocument);

      const shipping = {
        ...newOrder.shipping,
        address: {
          postalCode: user.addresses?.[0]?.postalCode || '',
          complement: user.addresses?.[0]?.complement || '',
          streetNumber: user.addresses?.[0]?.streetNumber || '',
          street: user.addresses?.[0]?.street || '',
          city: user.addresses?.[0]?.city || '',
          state: user.addresses?.[0]?.state || '',
          district: user.addresses?.[0]?.district || '',
        },
        deliveryRange: {
          min: 0,
          max: 0,
        },
        method: 'Retirar na Loja',
        company: 'Cliente',
        companyPicture: '/assets/imgs/retirar.png',
        price: 0,
      };

      setOrderForm((oldFields) => ({
        ...oldFields,
        customer: {
          userId: user.userId,
          name: user.name,
          email: user.email,
          phones: user.phones,
          documents: { ...user.documents, othersDocuments },
          addresses: user.addresses,
          credit: user.credit,
          officialDocument: newOfficialDocument,
        },
        shipping,
        onlyCompanyStock: user._id === '642d546c7f392a4eb477558e',
      }));

      return handleCloseDialogCustomer();
    },
    [handleCloseDialogCustomer, orderForm],
  );

  const [isInvalidIe, setIsInvalidIe] = useState(false);
  const [timeoutValidatorIeId, setTimeoutValidatorIeId] = useState();
  const [loadingValidatorIe, setLoadingValidatorIe] = useState(false);

  const handleChangeIe = (event) => {
    const ie = event.target.value;

    setOrderForm((oldFields) => ({
      ...oldFields,
      customer: {
        ...oldFields.customer,
        documents: {
          ...oldFields.customer?.documents,
          inscricaoEstadual: onlyNumbersValue(ie),
        },
      },
    }));
  };

  const handleCheckIe = async (ie) => {
    if (ie) {
      const billingState = orderForm.customer?.billingAddress?.state;
      const shippingState = orderForm.shipping?.address?.state;

      const uf = billingState || shippingState || '';
      const pd = orderForm.customer?.documents?.pessoa === 'PF';

      const result = await isValidIe(ie, uf, pd);

      if (!result) {
        setIsInvalidIe(true);
      } else {
        setIsInvalidIe(false);
      }
    } else {
      setIsInvalidIe(false);
    }
  };

  useEffect(() => {
    setLoadingValidatorIe(true);

    if (timeoutValidatorIeId) {
      clearTimeout(timeoutValidatorIeId);
    }

    const newTimeoutId = setTimeout(() => {
      handleCheckIe(orderForm.customer?.documents?.inscricaoEstadual);
      setLoadingValidatorIe(false);
    }, 1000);

    setTimeoutValidatorIeId(newTimeoutId);
  }, [orderForm.customer?.documents?.inscricaoEstadual]);

  const [totalSelected, setTotalSelected] = useState(0);
  const [totalCost, setCostTotal] = useState(0);

  const [profitability, setProfitability] = useState(0);
  const [profitabilityPercentage, setProfitabilityPercentage] = useState(0);

  const [loadingProfitability, setLoadingProfitability] = useState(false);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">Cliente</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <ProfitabilityButton
                  orderForm={orderForm}
                  setSnackbar={setSnackbar}
                  totalSelected={totalSelected}
                  setTotalSelected={setTotalSelected}
                  totalCost={totalCost}
                  setCostTotal={setCostTotal}
                  profitability={profitability}
                  setProfitability={setProfitability}
                  profitabilityPercentage={profitabilityPercentage}
                  setProfitabilityPercentage={setProfitabilityPercentage}
                  setLoadingProfitability={setLoadingProfitability}
                />
              </Grid>
              {orderForm.customer && orderForm.customer.userId ? (
                <Grid item>
                  <ItemsBoughtButton
                    orderForm={orderForm}
                    setUserOrders={setUserOrders}
                    userItemsBought={userItemsBought}
                    setUserItemsBought={setUserItemsBought}
                    setSnackbar={setSnackbar}
                  />
                </Grid>
              ) : null}
              {orderForm.customer && orderForm.customer.userId ? (
                <Grid item>
                  <UserOrdersButton userOrders={userOrders} />
                </Grid>
              ) : null}
              <Grid item>
                <Tooltip
                  title={
                    <Typography align="center">
                      {orderForm.invoice?.status === 'aprovado'
                        ? 'Este pedido já foi faturado, não pode alterar o cliente.'
                        : null}
                      {!orderForm.company || !orderForm.source
                        ? 'Escolha a empresa e a origem antes de prosseguir.'
                        : null}
                      {(orderForm.status === 'Orçamento' ||
                        orderForm.status === 'Pedido criado' ||
                        orderForm.status === 'Pedido confirmado') &&
                      orderForm.company &&
                      orderForm.source
                        ? 'Selecione o cliente para este pedido.'
                        : null}
                    </Typography>
                  }
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleOpenDialogCustomer}
                      disabled={
                        !orderForm.company ||
                        !orderForm.source ||
                        orderForm.invoice?.status === 'aprovado'
                      }
                    >
                      Escolher Cliente
                    </Button>
                  </Box>
                </Tooltip>
              </Grid>
              {orderForm.customer.userId ? (
                <Grid item>
                  <Tooltip
                    title={
                      <Typography align="center">
                        {orderForm.invoice?.status === 'aprovado'
                          ? 'Este pedido já foi faturado, não pode alterar o documento.'
                          : 'Selecionar outro documento para este pedido.'}
                      </Typography>
                    }
                  >
                    <Box>
                      <Button
                        variant="contained"
                        onClick={handleOpenOtherDocumentDialog}
                        disabled={
                          !orderForm.company ||
                          !orderForm.source ||
                          orderForm.invoice?.status === 'aprovado'
                        }
                      >
                        Dados fiscais
                      </Button>
                    </Box>
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {orderForm.customer.name && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <CustomerCreditBlok customer={orderForm.customer} />
            <ProfitabilityBlok
              totalSelected={totalSelected}
              totalCost={totalCost}
              profitability={profitability}
              profitabilityPercentage={profitabilityPercentage}
              loadingProfitability={loadingProfitability}
            />
            <Grid item xs={8}>
              <Grid container direction="column">
                <Typography variant="caption">Comprador</Typography>
                <Typography>
                  {officialDocument && officialDocument.name
                    ? officialDocument.name
                    : orderForm.customer.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                label="Inscrição estadual"
                value={orderForm.customer?.documents?.inscricaoEstadual || ''}
                onChange={handleChangeIe}
                disabled={orderForm.invoice?.status === 'aprovado'}
                loading={loadingValidatorIe}
                error={isInvalidIe}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="column">
                <Typography variant="caption">Nome/Razão</Typography>
                <Typography>
                  {orderForm.customer.userId ? `${orderForm.customer.userId} - ` : ''}
                  {orderForm.customer.documents.pessoa === 'PF'
                    ? orderForm.customer.name
                    : orderForm.customer.documents.razaoSocial}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction="column">
                <Typography variant="caption">
                  {orderForm.customer.documents.pessoa === 'PF' ? 'CPF' : 'CPNJ'}
                </Typography>
                <Typography>
                  {orderForm.customer.documents.pessoa === 'PF'
                    ? formatCpfCpnj(orderForm.customer.documents.cpf)
                    : formatCpfCpnj(orderForm.customer.documents.cnpj)}
                </Typography>
              </Grid>
            </Grid>
            {orderForm.customer.email && (
              <Grid item xs={8}>
                <Grid container direction="column">
                  <Typography variant="caption">E-mail</Typography>
                  <Typography noWrap>{orderForm.customer.email}</Typography>
                </Grid>
              </Grid>
            )}
            {orderForm.customer.phones && orderForm.customer.phones[0] && (
              <Grid item xs={4}>
                <Grid container direction="column">
                  <Typography variant="caption">Telefone/Whatsapp</Typography>
                  <Typography>
                    <LinkMaterial
                      href={`https://api.whatsapp.com/send?phone=55${orderForm.customer.phones[0].number}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="none"
                    >
                      {formatPhone(orderForm.customer.phones[0].number)}
                    </LinkMaterial>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {orderForm.customer &&
      orderForm.customer.billingAddress &&
      orderForm.customer.billingAddress.postalCode ? (
        <Grid item xs={12}>
          <Grid container direction="column">
            <Typography variant="caption">Endereço de Faturamento</Typography>
            <Typography>
              {orderForm.customer.billingAddress.street
                ? `${orderForm.customer.billingAddress.street}, `
                : null}
              {orderForm.customer.billingAddress.streetNumber
                ? `${orderForm.customer.billingAddress.streetNumber}, `
                : null}
              {orderForm.customer.billingAddress.complement
                ? `${orderForm.customer.billingAddress.complement}, `
                : null}
              {orderForm.customer.billingAddress.district
                ? `${orderForm.customer.billingAddress.district}, `
                : null}
              {orderForm.customer.billingAddress.city}/{orderForm.customer.billingAddress.state} -
              CEP: {orderForm.customer.billingAddress.postalCode}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      {openDialogCustomer ? (
        <CustomerDialog
          openCustomerDialog={openDialogCustomer}
          handleCloseCustomerDialog={handleCloseDialogCustomer}
          handleSelectCustomerToOrder={handleSelectCustomerToOrder}
        />
      ) : null}
      {openDialogOtherDocument ? (
        <OtherDocumentDialog
          openDialogOtherDocument={openDialogOtherDocument}
          handleCloseOtherDocumentDialog={handleCloseOtherDocumentDialog}
          orderForm={orderForm}
          setOrderForm={setOrderForm}
          officialDocument={officialDocument}
          othersDocuments={othersDocuments}
        />
      ) : null}
    </Grid>
  );
}

export default CustomerTab;
