import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import Axios from 'axios';
import { AuthProvider } from './contexts/auth';
import Pages from './Pages';
import 'dayjs/locale/pt-br';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1C325F',
    },
    secondary: {
      main: '#ffb000',
    },
    error: {
      main: '#ff604f',
    },
    default: {
      main: grey[300],
    },
    background: {
      default: '#f0f2f5',
    },
    mode: 'light', // 'light' ou 'dark'
  },
  typography: {
    h1: {
      fontSize: '1.50rem',
      fontWeight: '700',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: '700',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: '700',
    },
  },
  components: {
    MuiTableRow: {
      styleOverrides: {
        head: {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
        root: {
          '&:hover': {
            backgroundColor: grey[100],
          },
        },
        footer: {
          '&:hover': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 4,
        },
        footer: {
          border: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 'max(0px, min(8px, ((100vw - 4px) - 100%) * 9999)) / 8px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      },
    },
  },
});

const environment = process.env.REACT_APP_ENVIRONMENT;

Axios.defaults.baseURL =
  environment === 'production'
    ? process.env.REACT_APP_REST_URL_PROD
    : process.env.REACT_APP_REST_URL_DEV;

Axios.defaults.headers.common['token-mg-admin'] = process.env.REACT_APP_API_TOKEN_ADMIN;

const token = localStorage.getItem('token');

if (token) {
  Axios.defaults.headers.common['token-mg'] = token;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Pages />
      </ThemeProvider>
    </LocalizationProvider>
  </AuthProvider>
);
