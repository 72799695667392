export const onlyNumbersValue = (value) => value.replace(/\D/g, '');
export const bigIntToReal = (value) => (value / 100).toFixed(2).replace(/\./g, ',');
export const realToNumber = (value) => (value ? parseFloat(value.replace(/,/g, '.')) : 0);

export const numberToReal = (value) => {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!parsedValue) {
    return '0,00';
  }

  return parsedValue.toFixed(2).replace(/\./g, ',');
};

export const numberToRealWithPeriod = (value) => {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!parsedValue) {
    return '0,00';
  }

  return parsedValue.toLocaleString('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
};

export const toFixedFloat = (value) => {
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!parsedValue) {
    return 0.0;
  }

  const fixedValue = parsedValue
    .toLocaleString('pt-BR', {
      maximumFractionDigits: 5,
      minimumFractionDigits: 2,
    })
    .replace(/\./g, '')
    .replace(/,/g, '.');

  return parseFloat(fixedValue);
};

export const formatReal = (value) => {
  let parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!parsedValue) {
    parsedValue = 0.0;
  }

  const onlyNumbers = String(parsedValue).replace(/\D/g, '');
  const { length } = onlyNumbers;

  if (length === 1) {
    return onlyNumbers.replace(/(\d{1})/g, '0.0$1');
  }
  if (length === 2) {
    return onlyNumbers.replace(/(\d{1})(\d{1})/g, '0.$1$2');
  }
  if (length === 3) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})/g, '$1.$2$3');
  }
  if (length === 4) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1$2.$3$4');
  }
  if (length === 5) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1$2$3.$4$5');
  }
  if (length === 6) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1$2$3$4.$5$6');
  }
  if (length === 7) {
    return onlyNumbers.replace(
      /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
      '$1$2$3$4$5.$6$7',
    );
  }
  if (length === 8) {
    return onlyNumbers.replace(
      /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
      '$1$2$3$4$5$6.$7$8',
    );
  }
  if (length === 9) {
    return onlyNumbers.replace(
      /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
      '$1$2$3$4$5$6$7.$8$9',
    );
  }
  return '9999999.99';
};

export const formatWeight = (value) => {
  let parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!parsedValue) {
    parsedValue = 0;
  }
  if (parsedValue > 99999999) {
    parsedValue = 99999999;
  }

  const onlyNumbers = String(parsedValue).replace(/\D/g, '');
  const { length } = onlyNumbers;

  if (length === 1) {
    return onlyNumbers.replace(/(\d{1})/g, '0.00$1');
  }
  if (length === 2) {
    return onlyNumbers.replace(/(\d{1})(\d{1})/g, '0.0$1$2');
  }
  if (length === 3) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})/g, '0.$1$2$3');
  }
  if (length === 4) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1.$2$3$4');
  }
  if (length === 5) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1$2.$3$4$5');
  }
  if (length === 6) {
    return onlyNumbers.replace(/(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g, '$1$2$3.$4$5$6');
  }
  if (length === 7) {
    return onlyNumbers.replace(
      /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
      '$1$2$3$4.$5$6$7',
    );
  }
  if (length === 8) {
    return onlyNumbers.replace(
      /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
      '$1$2$3$4$5.$6$7$8',
    );
  }
  return onlyNumbers.replace(
    /(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})(\d{1})/g,
    '$1$2$3$4$5.$6$7$8',
  );
};

export const formatDate = (value) => {
  const formatedDate = new Date(value);
  return formatedDate.toLocaleDateString('pt-br');
};

export const formatDateWithHour = (value) => {
  const formatedDate = new Date(value);
  return formatedDate.toLocaleString('pt-br');
};

export const cleanUpDuplicateSpaces = (value) => value.trim().replace(/\s{2,}/g, ' ');

export const cleanUpDuplicateSpacesInText = (text) => {
  if (text) {
    return text
      .split(/\r?\n/)
      .map((a) => {
        if (a === '') {
          return '\n';
        }
        return `${a.trim().replace(/\s{2,}/g, ' ')}\n`;
      })
      .join('')
      .trim();
  }
  return '';
};

export const capitalizeString = (value) => {
  if (value) {
    return value
      .split(' ')
      .map((word) => {
        if (word.toLowerCase() === 'de' || word.toLowerCase() === 'da') {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(' ')
      .trim();
  }
  return '';
};

export const extractFirstAndLastName = (fullName) => {
  if (fullName) {
    const words = fullName.split(' ');

    if (words.length === 1) {
      return words[0];
    }

    if (words.length === 2 || words[1].length > 2) {
      return words.slice(0, 2).join(' ');
    }

    return words.slice(0, 3).join(' ');
  }
  return '';
};

export const skipFirstWord = (value) => {
  if (value) {
    const words = value.split(' ');

    if (words.length === 1) {
      return words[0];
    }

    if (words.length > 1) {
      return words.slice(1).join(' ');
    }
  }
  return '';
};

export const formatPhone = (value) => {
  const onlyNumbers = String(value).replace(/\D/g, '');
  const { length } = onlyNumbers;
  if (length < 10) {
    if (length === 3) {
      return onlyNumbers.replace(/(\d{2})(\d{1})/g, '($1) $2');
    }
    if (length === 4) {
      return onlyNumbers.replace(/(\d{2})(\d{2})/g, '($1) $2');
    }
    if (length === 5) {
      return onlyNumbers.replace(/(\d{2})(\d{3})/g, '($1) $2');
    }
    if (length === 6) {
      return onlyNumbers.replace(/(\d{2})(\d{4})/g, '($1) $2');
    }
    if (length === 7) {
      return onlyNumbers.replace(/(\d{2})(\d{5})/g, '($1) $2');
    }
    if (length === 8) {
      return onlyNumbers.replace(/(\d{2})(\d{6})/g, '($1) $2');
    }
    if (length === 9) {
      return onlyNumbers.replace(/(\d{2})(\d{7})/g, '($1) $2');
    }
  } else {
    return onlyNumbers.replace(/(\d{2})(\d{4,5})(\d{4})/g, '($1) $2-$3');
  }
  return value;
};

export const formatCpfCpnj = (value) => {
  if (value) {
    const onlyNumbers = value.replace(/\D/g, '');
    if (onlyNumbers.length === 11) {
      return onlyNumbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    }
    return onlyNumbers.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  }
  return '';
};

export const formatHandleError = ({ setSnackbar, defaultMessage, error }) => {
  let errorObject = {};
  let errorMessage = defaultMessage;

  if (error.response && error.response.data) {
    errorObject = error.response.data;
  }
  if (errorObject.message) {
    errorMessage = `${errorObject.message}${errorObject.message !== errorObject.data ? `\n\n\n${errorObject.data}` : ''}`;
  }

  console.log(errorMessage);

  setSnackbar({
    message: errorMessage,
    open: true,
    type: 'error',
  });
};

export const formatProductToOrder = (product) => ({
  productId: product.productId,
  name: product.name,
  technicalName: product.technicalName,
  slug: product.slug,
  price: product.price,
  stock: product.stock,
  reservedStock: product.reservedStock,
  minimumStock: product.minimumStock,
  maximumStock: product.maximumStock,
  offer: product.offer || false,
  offerPrice: product.offerPrice || 0,
  offers: product.offers,
  package: product.package,
  images: product.images,
  othersIds: product.othersIds,
  cashPrice: product.cashPrice,
  composition: product.composition,
  productInputs: product.productInputs,
  cost: product.cost,
  localization: product.localization,
  taxFields: product.taxFields,
  suppliers: product.suppliers,
});
