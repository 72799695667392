import { Box } from '@mui/material';
import React, { useState } from 'react';
import SimpleDialog from '../../../../../Common/SimpleDialog';
import CustomInput from '../../../../../CustomInput';

function UnblockDialog({ openDialogUnblock, handleCloseDialogUnblock, setOrderForm }) {
  const [unblockKey, setUnblockKey] = useState('');
  const [unblockWrongKey, setUnblockWrongKey] = useState(false);

  const handleConfirmUnblock = () => {
    if (unblockKey === '748596') {
      setOrderForm((oldFields) => ({
        ...oldFields,
        unblockDiscount: true,
      }));

      handleCloseDialogUnblock();
    } else {
      setUnblockWrongKey(true);
    }
  };

  const handleChangeUnblockKey = (event) => setUnblockKey(event.target.value);

  return (
    <SimpleDialog
      openDialog={openDialogUnblock}
      content={
        <Box minWidth="250px">
          <CustomInput
            value={unblockKey}
            onChange={handleChangeUnblockKey}
            label="Chave"
            error={unblockWrongKey}
            helperText={unblockWrongKey ? 'Chave inválida' : null}
            password
          />
        </Box>
      }
      dialogTitle="Digite a chave"
      handleClose={handleCloseDialogUnblock}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogUnblock}
      handleConfirmButton={handleConfirmUnblock}
    />
  );
}

export default UnblockDialog;
