import Axios from 'axios';
import {
  Box,
  Chip,
  Fab,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import InputIcon from '@mui/icons-material/Input';
import RefreshIcon from '@mui/icons-material/Refresh';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ChequesTable from '../Table';
import ChequesTopBar from '../TopBar';
import FabGroupButtons from '../../Common/FabGroupButtons';
import newCheque from '../../../constant/newCheque';
import { searchCheques } from '../../../services/melhorGestao/cheques';
import ChequeDetails from '../Details';
import SimpleDialog from '../../Common/SimpleDialog';
import {
  capitalizeString,
  formatDateWithHour,
  numberToRealWithPeriod,
} from '../../../helpers/formatData';
import { getUsers } from '../../../services/melhorGestao/users';
import MassProcessCheques from '../MassProcessCheques';

const useStyles = makeStyles((theme) => ({
  fabLeftBelow: {
    position: 'fixed',
    bottom: 10,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(12),
  },
  boxCheckedQuantity: {
    bottom: -15,
    right: 0,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    width: 30,
    height: 30,
    position: 'absolute',
  },
}));

function ChequesPage({ sendToPaymentFabButton, handleSelectChequeToPayment }) {
  const classes = useStyles();

  const [chequesList, setChequesList] = useState([]);
  const [countCheques, setChequesCount] = useState(0);
  const [loadingChequesList, setLoadingChequesList] = useState(false);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [selectedChequeDetail, setSelectedChequeDetail] = useState(newCheque);
  const [refreshToken, setRefreshToken] = useState();

  const refreshSearch = useCallback(() => setRefreshToken(Math.random()), []);

  const [user, setUser] = useState(null);
  const [formatedFiltersSituations, setFormatedFiltersSituations] = useState([]);

  const getCheques = useCallback(() => {
    searchCheques({
      limit,
      skip: page * limit - limit,
      searchText: searchTextSubmited,
      user: user?._id ? user._id : null,
      situations: formatedFiltersSituations,
      setLoadingChequesList,
      setChequesList,
      setChequesCount,
    });
  }, [page, limit, searchTextSubmited, user, formatedFiltersSituations]);

  useEffect(() => {
    getCheques();
  }, [page, limit, searchTextSubmited, user, formatedFiltersSituations, refreshToken]);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, chequesList]
  );

  const [blockEdit, setBlockEdit] = useState(true);
  const handleOpenDialogDetail = useCallback(() => setOpenDialogDetail(true), []);
  const handleCloseDialogDetail = useCallback(() => setOpenDialogDetail(false), []);

  const [openDialogMovements, setOpenDialogMovements] = useState(false);
  const handleOpenDialogMovements = useCallback(() => setOpenDialogMovements(true), []);
  const handleCloseDialogMovements = useCallback(() => setOpenDialogMovements(false), []);

  useEffect(() => {
    if (selectedChequeDetail.barCode) {
      setBlockEdit(true);
    } else {
      setBlockEdit(false);
    }
  }, [selectedChequeDetail.barCode]);

  const [openDialogProcessCheques, setOpenDialogProcessCheques] = useState();
  const handleOpenDialogProcessCheques = () => setOpenDialogProcessCheques(true);
  const handleCloseDialogProcessCheques = () => setOpenDialogProcessCheques(false);
  const [checkedCheques, setCheckedCheques] = useState([]);

  const [fabButtons, setFabButtons] = useState([]);
  const [selectedChequeToPayment, setSelectChequeToPayment] = useState();

  const handleClickCheque = useCallback(
    (cheque) => {
      if (sendToPaymentFabButton) {
        setSelectChequeToPayment(cheque);
      } else {
        setSelectedChequeDetail(cheque);
        return handleOpenDialogDetail();
      }
      return null;
    },
    [handleOpenDialogDetail, sendToPaymentFabButton]
  );

  const handleClickMovements = useCallback(
    async (cheque) => {
      const findById = (list, id) => list.find((item) => item._id === id);
      const movementsWithUserNames = [];

      const users = await getUsers([
        ...cheque.movements.map((movement) => movement.from),
        ...cheque.movements.map((movement) => movement.to),
      ]);

      cheque.movements.forEach((movement) => {
        const userFrom = findById(users, movement.from);
        const userTo = findById(users, movement.to);

        let fromName = userFrom?.name || 'Desconhecido';
        let toName = userTo?.name || 'Desconhecido';

        if (userFrom?.documents?.pessoa === 'PJ') {
          fromName = userFrom.documents.razaoSocial;
        }
        if (userTo?.documents?.pessoa === 'PJ') {
          toName = userTo.documents.razaoSocial;
        }

        movementsWithUserNames.push({
          ...movement,
          from: movement.from ? capitalizeString(fromName) : 'Desconhecido',
          to: movement.to ? capitalizeString(toName) : 'Desconhecido',
        });
      });

      setSelectedChequeDetail({ ...cheque, movements: movementsWithUserNames });
      handleOpenDialogMovements();
    },
    [handleOpenDialogMovements]
  );

  const handleSendChequeToPayment = useCallback(() => {
    if (selectedChequeToPayment) {
      return handleSelectChequeToPayment(selectedChequeToPayment);
    }

    return null;
  }, [selectedChequeToPayment]);

  useEffect(() => {
    if (sendToPaymentFabButton && handleSelectChequeToPayment) {
      setFabButtons([
        {
          title: 'Atualizar listagem',
          onClick: refreshSearch,
          icon: <RefreshIcon />,
        },
        {
          title: 'Enviar cheque selecionado para o pagamento',
          onClick: handleSendChequeToPayment,
          icon: <InputIcon />,
          featured: true,
        },
      ]);
    }
  }, [selectedChequeToPayment]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Atualizar listagem',
        onClick: refreshSearch,
        icon: <RefreshIcon />,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  const [checkedChequesValue, setCheckedChequesValue] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const responseCheques = await Axios.patch('/financial/cheques', {
        ids: checkedCheques,
      });
      const cheques = responseCheques.data;

      const totalValue = cheques
        .map((cheque) => cheque.value)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

      setCheckedChequesValue(totalValue);
    };
    fetchData();
  }, [checkedCheques]);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <ChequesTopBar
          user={user}
          setUser={setUser}
          setFormatedFiltersSituations={setFormatedFiltersSituations}
          submitSearch={setSearchTextSubmited}
        />
      </Box>
      <Box marginTop={2}>
        <ChequesTable
          chequesList={chequesList}
          countCheques={countCheques}
          selectedCheque={selectedChequeDetail}
          handleClickCheque={handleClickCheque}
          handleClickMovements={handleClickMovements}
          page={page}
          handleChangePage={handleChangePage}
          limit={limit}
          selectedChequeInList={selectedChequeToPayment}
          checkedCheques={checkedCheques}
          setCheckedCheques={setCheckedCheques}
          loadingChequesList={loadingChequesList}
        />
      </Box>
      <Box position="relative" right="45px">
        <Chip
          className={classes.fabLeftBelow}
          label={
            <Typography>
              Total selecionado: R$ {numberToRealWithPeriod(checkedChequesValue)}
            </Typography>
          }
        />
      </Box>

      <Box className={classes.fab}>
        <Slide
          direction="down"
          in={checkedCheques.length > 0}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Grid item>
            <Tooltip
              placement="left"
              title={<Typography align="center">Cheques selecionados</Typography>}
            >
              <Box position="relative">
                <Fab onClick={handleOpenDialogProcessCheques} color="primary">
                  <DoneAllIcon />
                  <Box className={classes.boxCheckedQuantity}>
                    <Typography color="black" marginTop="4px">
                      {checkedCheques.length}
                    </Typography>
                  </Box>
                </Fab>
              </Box>
            </Tooltip>
            {openDialogProcessCheques ? (
              <MassProcessCheques
                handleCloseDialogProcessCheques={handleCloseDialogProcessCheques}
                openDialogProcessCheques={openDialogProcessCheques}
                checkedCheques={checkedCheques}
                handleRefreshAll={refreshSearch}
              />
            ) : null}
          </Grid>
        </Slide>
      </Box>
      <FabGroupButtons fabButtons={fabButtons} />

      {openDialogDetail && (
        <Box>
          <ChequeDetails
            openDialogDetail={openDialogDetail}
            handleCloseDialogDetail={handleCloseDialogDetail}
            selectedCheque={selectedChequeDetail}
            blockEdit={blockEdit}
            refreshOnSave={refreshSearch}
          />
        </Box>
      )}

      {openDialogMovements ? (
        <SimpleDialog
          maxWidth="sm"
          openDialog={openDialogMovements}
          content={
            <Grid>
              {selectedChequeDetail.movements && selectedChequeDetail.movements.length > 0 ? (
                selectedChequeDetail.movements.map((movement, index) => (
                  <List key={movement._id}>
                    <ListItem>
                      <ListItemIcon>
                        <Typography variant="h4">{index + 1}</Typography>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${movement.reason} de ${movement.from} para ${movement.to}`}
                        secondary={`${
                          movement.details ? `${movement.details} ` : ''
                        }${formatDateWithHour(movement.occurredDate || new Date())}`}
                      />
                    </ListItem>
                  </List>
                ))
              ) : (
                <Typography>Nenhuma movimentação foi registrada ainda</Typography>
              )}
            </Grid>
          }
          dialogTitle="Movimentações do cheque"
          handleClose={handleCloseDialogMovements}
        />
      ) : null}
    </Box>
  );
}

export default memo(ChequesPage);
