import React from 'react';
import { Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarSettings() {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" className={classes.topBarTitle}>
            Configurações
          </Typography>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default TopBarSettings;
