import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { formatDate, numberToReal } from '../../../../helpers/formatData';
import SimpleDialog from '../../../Common/SimpleDialog';

function CreditHistoryDialog({
  openDialogCreditHistory,
  handleCloseDialogCreditHistory,
  userForm,
}) {
  return (
    <SimpleDialog
      maxWidth="sm"
      content={
        <Grid>
          {userForm.credit?.descriptionHistory?.length > 0 ? (
            userForm.credit.descriptionHistory.map((history, index) => (
              <List key={history._id}>
                <ListItem>
                  <Grid container>
                    <Grid item>
                      <ListItemIcon>
                        <Typography variant="h4">{index + 1}</Typography>
                      </ListItemIcon>
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <ListItemText
                            primary={`${history.creditType} de crédito`}
                            secondary={`R$ ${numberToReal(history.value)}`}
                          />
                        </Grid>
                        <Grid item>
                          <ListItemText
                            primary={history.description}
                            secondary={formatDate(history.updatedAt)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            ))
          ) : (
            <Typography>Nenhuma movimentação foi realizada</Typography>
          )}
        </Grid>
      }
      dialogTitle="Movimentação de crédito"
      handleClose={handleCloseDialogCreditHistory}
      openDialog={openDialogCreditHistory}
    />
  );
}

export default CreditHistoryDialog;
