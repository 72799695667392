import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { numberToReal } from '../../../../../helpers/formatData';
import getModifiedKeys from '../../../../../helpers/getModifiedKeys';
import UnblockDialog from './UnblockDialog';

const useStyles = makeStyles((theme) => ({
  redText: {
    color: theme.palette.error.main,
  },
  orderMobile: {
    [theme.breakpoints.down('xs')]: {
      width: 140,
      marginLeft: -280,
    },
  },
}));

function ProfitabilityBlok({ orderForm, setOrderForm, initialOrderForm }) {
  const classes = useStyles();

  const [normalTotal, setNormalTotal] = useState(0);
  const [normalTotalOffer, setNormalTotalOffer] = useState(0);
  const [totalSelected, setTotalSelected] = useState(0);

  const [maxDiscount, setMaxDiscount] = useState(15);
  const [totalFinancialCost, setTotalFinancialCost] = useState(0);

  const [totalCost, setTotalCost] = useState(0);
  const [profitability, setProfitability] = useState(0);

  useEffect(() => {
    let financialCost = 0;

    let normalTotalItems = 0;
    let normalTotalOfferItems = 0;

    let itemsTotalSelected = 0;
    let salesCostTotalItems = 0;

    let totalDiscountPercent = 0;
    let offerItemCount = 0;

    orderForm.payments.forEach((payment) => {
      if ((!payment.type || payment.type === 'Recebimento') && payment.status === 'Pago') {
        financialCost += payment.financialCost;
      }
    });

    orderForm.items.forEach((item) => {
      const salesCost = item.taxFields?.taxes?.salesCost || 0;

      normalTotalItems += item.price * item.quantity;
      normalTotalOfferItems += item.offerPrice * item.quantity;

      itemsTotalSelected += item.selectedPrice * item.quantity;
      salesCostTotalItems += salesCost * item.quantity;

      const discountPercent = ((item.price - item.offerPrice) / item.price) * 100;
      totalDiscountPercent += discountPercent;
      offerItemCount += 1;
    });

    setTotalFinancialCost(financialCost);

    setNormalTotal(normalTotalItems);
    setNormalTotalOffer(normalTotalOfferItems);

    setTotalSelected(itemsTotalSelected);
    setTotalCost(salesCostTotalItems);

    setProfitability(itemsTotalSelected - salesCostTotalItems - financialCost);

    let localMaxDiscount = 15;

    const averageDiscountPercent = Math.round(totalDiscountPercent / offerItemCount);
    if (averageDiscountPercent) {
      localMaxDiscount =
        averageDiscountPercent > localMaxDiscount ? averageDiscountPercent : localMaxDiscount;
    }

    let discountTotal = 0;

    if (normalTotalItems - itemsTotalSelected > 0) {
      discountTotal = normalTotalItems - itemsTotalSelected;
    }

    let unblockDiscount = { unblockDiscount: true };

    if (getModifiedKeys(orderForm.items, initialOrderForm.items).length > 0) {
      if (Math.round((1 - (1 - discountTotal / normalTotalItems)) * 100) > localMaxDiscount) {
        unblockDiscount = { unblockDiscount: false };
      }
    }

    setMaxDiscount(localMaxDiscount);
    setOrderForm((oldFields) => ({
      ...oldFields,
      ...unblockDiscount,
    }));
  }, [orderForm.shipping, orderForm.payments, orderForm.items]);

  const [openDialogUnblock, setOpenDialogUnblock] = useState(false);
  const handleOpenDialogUnblock = () => setOpenDialogUnblock(true);
  const handleCloseDialogUnblock = () => setOpenDialogUnblock(false);

  return (
    <Grid container justifyContent="space-between">
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography>Preço s/ desconto:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(normalTotal)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography>Preço c/ desconto:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(normalTotalOffer)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="baseline">
              <Grid item>
                <Typography>Desconto permitido:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">{maxDiscount}%</Typography>
              </Grid>
            </Grid>
          </Grid>
          {!orderForm.unblockDiscount ? (
            <Grid item style={{ padding: 15 }}>
              <Button
                className={classes.orderMobile}
                variant="contained"
                onClick={handleOpenDialogUnblock}
              >
                Liberar pedido
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          {orderForm.discountTotal && normalTotal ? (
            <Grid item>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
                alignItems="baseline"
                className={
                  !orderForm.unblockDiscount ||
                  Math.round((1 - (1 - orderForm.discountTotal / normalTotal)) * 100) > maxDiscount
                    ? classes.redText
                    : null
                }
              >
                <Grid item>
                  <Typography>Desconto aplicado:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3">
                    R$ {numberToReal(orderForm.discountTotal)} (
                    {Math.round((1 - (1 - orderForm.discountTotal / normalTotal)) * 100)}
                    %)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          <Grid item>
            <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
              <Grid item>
                <Typography>Preço de venda:</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">R$ {numberToReal(totalSelected)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          {totalCost ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Custo de venda:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.redText}>
                    R$ {numberToReal(totalCost)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {totalFinancialCost ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Taxas:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={classes.redText}>
                    R$ {numberToReal(totalFinancialCost)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
          {profitability ? (
            <Grid item style={{ padding: 15 }}>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                  <Typography>Rentabilidade:</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h3" className={profitability < 0 ? classes.redText : null}>
                    R$ {numberToReal(profitability)} (
                    {Math.round((1 - (1 - profitability / totalSelected)) * 100)}
                    %)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {openDialogUnblock ? (
        <UnblockDialog
          openDialogUnblock={openDialogUnblock}
          handleCloseDialogUnblock={handleCloseDialogUnblock}
          setOrderForm={setOrderForm}
        />
      ) : null}
    </Grid>
  );
}

export default ProfitabilityBlok;
