import Axios from 'axios';
import {
  Alert,
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Slider,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SimpleDialog from '../../Common/SimpleDialog';
import OfferPaper from './OfferPaper';
import newOffer from '../../../constant/newOffer';
import { searchAllSuppliers } from '../../../services/melhorGestao/users';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import DiscountCoupon from './DiscountCoupon';
import CustomDatePicker from '../../CustomDatePicker';
import marksUpTo50 from '../../../constant/marksUpTo50';
import { formatHandleError } from '../../../helpers/formatData';
import SearchField from '../../Common/SearchField';
import CustomPagination from '../../Common/CustomPagination';

const useStyles = makeStyles((theme) => ({
  addCompanyIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  paper: {
    width: 650,
    padding: 10,
  },
  menuItemSelected: {
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function FixedOffers() {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const [offerForm, setOfferForm] = useState(newOffer);
  const [offerFor, setOfferFor] = useState('');
  const [offerForCode, setOfferForCode] = useState('');
  const [fixedOffer, setFixedOffer] = useState(true);
  const [considerOnMarketplaces, setConsiderOnMarketplaces] = useState(false);
  const [fixedOfferPercentage, setFixedOfferPercentage] = useState(0);

  const [openDiscountCoupon, setOpenDiscountCoupon] = useState(false);
  const handleOpenDiscountCoupon = () => {
    setOpenDiscountCoupon(true);
    setOfferFor(null);
  };

  const handleOfferFor = (categoryOffer) => {
    setOfferFor(categoryOffer);
    setOpenDiscountCoupon(false);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 5)));
  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const [openDialogFixedOffer, setOpenDialogFixedOffer] = useState(false);
  const handleOpenDialogFixedOffer = useCallback(() => setOpenDialogFixedOffer(true), []);
  const handleCloseDialogFixedOffer = useCallback(() => setOpenDialogFixedOffer(false), []);

  const handleChangeFixedOfferSlider = useCallback((event, newValue) => {
    setFixedOfferPercentage(newValue);
  }, []);

  const [offersList, setOffersList] = useState([]);
  const [countOffers, setCountOffers] = useState(0);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const [refreshToken, setRefreshToken] = useState(Math.random());
  const [searchTextSubmited, setSearchTextSubmited] = useState('');

  const refreshOffers = useCallback(() => setRefreshToken(Math.random()), []);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, offersList],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (offerFor === '') {
        setOfferForm((oldFields) => ({
          ...oldFields,
          name: 'Desconto para todos produtos',
          description: 'Desconto criado pelo painel de promoções fixas',
        }));
      }
      if (offerFor === 'supplier') {
        setOfferForm((oldFields) => ({
          ...oldFields,
          name: 'Desconto por fornecedor',
        }));
      }
      if (offerFor === 'category') {
        setOfferForm((oldFields) => ({
          ...oldFields,
          name: 'Desconto por categoria',
        }));
      }
      if (offerFor === 'payment') {
        setOfferForm((oldFields) => ({
          ...oldFields,
          name: 'Desconto por forma de pagamento',
        }));
      }

      try {
        setLoadingOffers(true);
        const response = await Axios.get('/offers', {
          params: {
            skip: page * limit - limit,
            limit,
            searchText: searchTextSubmited,
            offerFor,
          },
        });

        const { offers, totalResults } = response.data;

        setOffersList(offers);
        setCountOffers(totalResults);

        setLoadingOffers(false);
      } catch (error) {
        setLoadingOffers(false);
        formatHandleError({
          setSnackbar,
          defaultMessage: 'Ocorreu algum erro ao carregar as promoções',
          error,
        });
      }
    };
    fetchData();
  }, [refreshToken, offerFor, page, limit, searchTextSubmited]);

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(false);

  useEffect(() => {
    if (startDate < new Date(new Date().setDate(new Date().getDate() - 1))) {
      return setStartDate(new Date());
    }
    if (offerFor === 'supplier' && offerForCode === '') {
      setErrorMessage('Selecione o fornecedor');
      return setDisableConfirmButton(true);
    }
    if (offerFor === 'category' && offerForCode === '') {
      setErrorMessage('Selecione a categoria');
      return setDisableConfirmButton(true);
    }
    if (offerFor === 'payment' && offerForCode === '') {
      setErrorMessage('Selecione o pagamento');
      return setDisableConfirmButton(true);
    }
    if (startDate > new Date(new Date().setDate(new Date().getDate() + 60))) {
      setErrorMessage('A data de início deve ser dentro de 60 dias');
      return setDisableConfirmButton(true);
    }
    if (!fixedOffer) {
      if (startDate > endDate) {
        setErrorMessage('A data de início deve ser menor que a de término');
        return setDisableConfirmButton(true);
      }
      if (endDate > new Date(new Date().setDate(new Date().getDate() + 90))) {
        setErrorMessage('A data de término deve contar no máximo 60 dias a partir do início');
        return setDisableConfirmButton(true);
      }
    }
    if (fixedOfferPercentage === 0) {
      setErrorMessage('A valor da porcentagem deve ser no mínimo 1%');
      return setDisableConfirmButton(true);
    }
    if (offerFor === 'supplier') {
      setErrorMessage(`Isso será feito nos produtos do fornecedor selecionado`);
      return setDisableConfirmButton(false);
    }
    if (offerFor === 'category') {
      setErrorMessage(`Isso será feito nos produtos da categoria selecionada`);
      return setDisableConfirmButton(false);
    }
    setErrorMessage(`Isso será feito em todos os produtos`);
    return setDisableConfirmButton(false);
  }, [offerFor, offerForCode, startDate, endDate, fixedOfferPercentage]);

  const handleChangeSupplier = (event, newValue) => {
    if (newValue?.userId) {
      setOfferForCode(newValue.userId);

      return setOfferForm((oldFields) => ({
        ...oldFields,
        description: newValue.name,
      }));
    }
    return setOfferForCode('');
  };

  const handleChangeCategory = (event, newValue) => {
    if (newValue?.slug) {
      setOfferForCode(newValue.slug);

      return setOfferForm((oldFields) => ({
        ...oldFields,
        description: newValue.name,
      }));
    }
    return setOfferForCode('');
  };

  const handleChangePayment = (event, newValue) => {
    if (newValue?.slug) {
      setOfferForCode(newValue.slug);

      return setOfferForm((oldFields) => ({
        ...oldFields,
        description: newValue.name,
      }));
    }
    return setOfferForCode('');
  };

  const handleChangeTypeOffer = () => setFixedOffer(!fixedOffer);

  const handleChangeConsiderOnMarketplaces = () =>
    setConsiderOnMarketplaces(!considerOnMarketplaces);

  const handleCreateOffer = async () => {
    handleCloseDialogFixedOffer();

    try {
      setLoadingOffers(true);
      let status = 'Inativo';

      if (fixedOffer) {
        if (startDate <= new Date() && endDate >= new Date()) {
          status = 'Ativo';
        }
      } else if (startDate <= new Date()) {
        status = 'Ativo';
      }

      let considerOnMarketplacesObject = {};

      if (considerOnMarketplaces) {
        considerOnMarketplacesObject = {
          considerOnMarketplaces,
        };
      }

      await Axios.post('/offers', {
        ...offerForm,
        ...considerOnMarketplacesObject,
        type: fixedOffer ? 'Fixo' : 'Temporário',
        status,
        startDate,
        endDate: fixedOffer ? undefined : endDate,
        discountPercentage: fixedOfferPercentage,
        offerFor,
        offerForCode,
      });

      setOfferForCode('');
      setStartDate(new Date());
      setEndDate(new Date(new Date().setDate(new Date().getDate() + 5)));
      setFixedOffer(true);
      setFixedOfferPercentage(0);
      setLoadingOffers(false);
      refreshOffers();
    } catch (error) {
      setLoadingOffers(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao excluir a promoção',
        error,
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [suppliersList, setSuppliersList] = useState([]);
  const [loadingSuppliersList, setLoadingSuppliersList] = useState(false);

  const handleSearchAllSuppliers = useCallback(
    async () =>
      searchAllSuppliers({
        setLoadingSuppliersList,
        setSuppliersList,
        setSnackbar,
      }),
    [],
  );

  const filterSupplier = createFilterOptions({
    stringify: (option) => option.userId + option.name,
  });

  const [categoriesList, setCategoriesList] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);

  const getAllCategories = async () => {
    try {
      setLoadingCategories(true);

      const response = await Axios.get('/catalog/categories');
      const { categories } = response.data;

      setCategoriesList(categories);
      setLoadingCategories(false);
    } catch (error) {
      setLoadingCategories(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao carregar as categorias',
        error,
      });
    }
  };

  const filterCategory = createFilterOptions({
    stringify: (option) => option.name + option.slug,
  });

  useEffect(() => {
    handleSearchAllSuppliers();
    getAllCategories();
  }, []);

  return (
    <Box marginTop={3} marginBottom={5}>
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Paper>
            <Box padding={2}>
              <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                  <Typography variant="h5">Promoções fixas</Typography>
                </Grid>
                {offerFor !== 'endsIn24h' ? (
                  <Grid item>
                    <Tooltip title={<Typography>Adicionar uma promoção</Typography>}>
                      <IconButton onClick={handleOpenDialogFixedOffer}>
                        <AddCircleIcon className={classes.addCompanyIcon} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>
            </Box>

            <MenuList>
              <MenuItem
                onClick={() => handleOfferFor('')}
                className={offerFor === '' ? classes.menuItemSelected : null}
              >
                Todos produtos
              </MenuItem>
              <MenuItem
                onClick={() => handleOfferFor('supplier')}
                className={offerFor === 'supplier' ? classes.menuItemSelected : null}
              >
                Por fornecedor
              </MenuItem>
              <MenuItem
                onClick={() => handleOfferFor('category')}
                className={offerFor === 'category' ? classes.menuItemSelected : null}
              >
                Por categoria
              </MenuItem>
              <MenuItem
                onClick={() => handleOfferFor('payment')}
                className={offerFor === 'payment' ? classes.menuItemSelected : null}
              >
                Por pagamento
              </MenuItem>
              <MenuItem
                onClick={() => handleOfferFor('endsIn24h')}
                className={offerFor === 'endsIn24h' ? classes.menuItemSelected : null}
              >
                Termina em 24h
              </MenuItem>
              <MenuItem
                onClick={() => handleOpenDiscountCoupon()}
                className={openDiscountCoupon ? classes.menuItemSelected : null}
              >
                Cupom de desconto
              </MenuItem>
            </MenuList>
          </Paper>
        </Grid>
        {!openDiscountCoupon ? (
          <Grid item sm={8}>
            <Paper>
              <Box padding={2}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {offerFor === '' && (
                      <Typography variant="h5">Promoções para todos produtos</Typography>
                    )}
                    {offerFor === 'supplier' && (
                      <Typography variant="h5">Promoções por fornecedor</Typography>
                    )}
                    {offerFor === 'category' && (
                      <Typography variant="h5">Promoções por categoria</Typography>
                    )}
                    {offerFor === 'payment' && (
                      <Typography variant="h5">Promoções por forma de pagamento</Typography>
                    )}
                    {offerFor === 'endsIn24h' && (
                      <Typography variant="h5">Promoções que terminam em 24h</Typography>
                    )}
                  </Grid>
                  <Grid item>
                    <SearchField
                      submitSearch={(searchText) => {
                        setPage(1);
                        setSearchTextSubmited(searchText);
                      }}
                      labelSearch="Pesquisar promoção"
                    />
                  </Grid>
                </Grid>
                <Box marginTop={2}>
                  <Grid container spacing={1}>
                    {!loadingOffers && offersList.length > 0
                      ? offersList.map((offer) => (
                          <Grid item key={offer._id || Math.random()}>
                            <OfferPaper
                              offer={offer}
                              setLoadingOffers={setLoadingOffers}
                              refreshOffers={refreshOffers}
                              setSnackbar={setSnackbar}
                            />
                          </Grid>
                        ))
                      : null}

                    {!loadingOffers && offersList.length > 0 ? (
                      <Grid item xs={12} marginTop={1}>
                        <CustomPagination
                          page={page}
                          total={countOffers}
                          limit={limit}
                          handleChangePage={handleChangePage}
                        />
                      </Grid>
                    ) : null}

                    {!loadingOffers && offersList.length === 0 ? (
                      <Paper elevation={3} className={classes.paper} align="center">
                        <Typography>Nenhuma promoção para exibir</Typography>
                      </Paper>
                    ) : null}

                    <SimpleBackdrop loading={loadingOffers} absolutePosition />
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ) : (
          <Grid item sm={8}>
            <DiscountCoupon />
          </Grid>
        )}
      </Grid>

      {openDialogFixedOffer && (
        <SimpleDialog
          openDialog={openDialogFixedOffer}
          handleClose={handleCloseDialogFixedOffer}
          dialogTitle={
            <Grid container spacing={3} justifyContent="space-between" alignItems="center">
              <Grid item>Criar promoção</Grid>
              <Grid item>
                <Box width={280}>
                  <Grid container direction="column" className={classes.selectBackground}>
                    {!loadingSuppliersList && offerFor === 'supplier' && (
                      <Autocomplete
                        options={suppliersList}
                        filterOptions={filterSupplier}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Typography {...{ ...props, key: option._id }}>
                            {option.userId} - {option.name}
                          </Typography>
                        )}
                        onChange={handleChangeSupplier}
                        noOptionsText="Opção não encontrada"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Buscar e selecionar fornecedor"
                            variant="filled"
                          />
                        )}
                      />
                    )}

                    {!loadingCategories && offerFor === 'category' && (
                      <Autocomplete
                        options={categoriesList}
                        filterOptions={filterCategory}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Typography {...{ ...props, key: option._id }}>{option.name}</Typography>
                        )}
                        onChange={handleChangeCategory}
                        noOptionsText="Opção não encontrada"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Buscar e selecionar categoria"
                            variant="filled"
                          />
                        )}
                      />
                    )}

                    {offerFor === 'payment' && (
                      <Autocomplete
                        options={[
                          { name: 'Pix', slug: 'pix' },
                          { name: 'Boleto', slug: 'boleto' },
                        ]}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option) => option.slug}
                        renderOption={(props, option) => (
                          <Typography {...props}>{option.name}</Typography>
                        )}
                        onChange={handleChangePayment}
                        noOptionsText="Opção não encontrada"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Buscar e selecionar pagamento"
                            variant="filled"
                          />
                        )}
                      />
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          }
          dialogText="Informe o valor do desconto em porcentagem. Máximo 50%"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogFixedOffer}
          handleConfirmButton={handleCreateOffer}
          disableConfirmButton={disableConfirmButton}
          content={
            <Grid container direction="column">
              <Grid item>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          value={fixedOffer}
                          onChange={handleChangeTypeOffer}
                          checked={fixedOffer}
                          name="type"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="caption">
                          Tipo {fixedOffer ? 'Fixo' : 'Temporário'}
                        </Typography>
                      }
                    />
                  </Grid>
                  {offerFor === 'payment' ? (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            value={considerOnMarketplaces}
                            onChange={handleChangeConsiderOnMarketplaces}
                            checked={considerOnMarketplaces}
                            name="type"
                            color="primary"
                          />
                        }
                        label={
                          <Typography variant="caption">Considere nos Marketplaces</Typography>
                        }
                      />
                    </Grid>
                  ) : null}
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Desconto em %</Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="baseline" justifyContent="center">
                          <Typography variant="h6">{fixedOfferPercentage}%</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Inicia em</Typography>
                      </Grid>
                      <Grid item>
                        <CustomDatePicker
                          variant="standard"
                          size="small"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={handleChangeStartDate}
                          error={startDate > endDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item variant="caption">
                        <Typography variant="caption">Termina em</Typography>
                      </Grid>
                      <Grid item>
                        <CustomDatePicker
                          variant="standard"
                          size="small"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={handleChangeEndDate}
                          disabled={fixedOffer}
                          error={endDate < startDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box marginTop={2} padding="0px 14px">
                  <Slider
                    min={0}
                    max={50}
                    step={1}
                    value={fixedOfferPercentage}
                    onChange={handleChangeFixedOfferSlider}
                    aria-labelledby="input-slider"
                    marks={marksUpTo50}
                  />
                </Box>
              </Grid>
              <Grid item>
                {errorMessage && disableConfirmButton && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {errorMessage && !disableConfirmButton && <Typography>{errorMessage}</Typography>}
              </Grid>
            </Grid>
          }
        />
      )}
    </Box>
  );
}

export default FixedOffers;
