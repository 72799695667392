import { Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { numberToRealWithPeriod } from '../../../../helpers/formatData';

function ValuesBlock({ loading, data }) {
  let profitPercentage = 0;
  const gainValue = data.value - data.salesCost - data.financialCost - data.shippingCost;

  if (gainValue && data.value) {
    profitPercentage = Math.round((gainValue / data.value) * 100);
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={0.4}>
              <Grid item>
                <Typography variant="caption">Total R$</Typography>
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {loading ? <Skeleton width={100} /> : numberToRealWithPeriod(data.value)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0.4}>
              <Grid item>
                <Typography variant="caption">Custo R$</Typography>
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {loading ? <Skeleton width={100} /> : numberToRealWithPeriod(data.salesCost)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0.4}>
              <Grid item>
                <Typography variant="caption">Frete/Taxas R$</Typography>
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {loading ? (
                    <Skeleton width={70} />
                  ) : (
                    numberToRealWithPeriod(data.financialCost + data.shippingCost)
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0.4}>
              <Grid item>
                <Typography variant="caption">Ganho R$</Typography>
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {loading ? <Skeleton width={100} /> : numberToRealWithPeriod(gainValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={0.4}>
              <Grid item>
                <Typography variant="caption">Pago R$</Typography>
              </Grid>
              <Grid item>
                <Typography variant="button">
                  {loading ? <Skeleton width={100} /> : numberToRealWithPeriod(data.valuePaid)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="caption">Rentabilidade</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">{loading ? <Skeleton /> : `${profitPercentage}%`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">Pedidos</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5">
              {loading ? <Skeleton /> : parseInt(data.count, 10)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ValuesBlock;
