import React, { memo, useEffect } from 'react';
import { Box, Checkbox, Grid, ListItemText, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchField from '../Common/SearchField';
import FilterSelectOptions from '../Common/FilterSelectOptions';
import UserAutocomplete from '../Common/UserAutocomplete';
import CompanyAutocomplete from '../Common/CompanyAutocomplete';
import FilterCategoriesOptions from '../Common/FilterCategoriesOptions';
import FilterOrdenateOptions from '../Common/FilterOrdenateOptions';
import FilterBrandsOptions from '../Common/FilterBrandsOptions';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarProducts({
  submitSearchProducts,
  filterByCompany,
  setFilterByCompany,
  filtersStatus,
  setFiltersStatus,
  filterBySupplier,
  setFilterBySupplier,
  filtersOrdenateOptions,
  setFiltersOrdenateOptions,
  setFiltersByCategories,
  setFiltersByBrands,
  onlySku,
  setOnlySku,
  setPage,
}) {
  const classes = useStyles();

  useEffect(() => {
    setPage(1);
  }, [filterByCompany, filtersStatus, filterBySupplier]);

  return (
    <Toolbar>
      <Grid container direction="row" spacing={1} marginTop={1}>
        <Grid item xs={1}>
          <Typography variant="h5" className={classes.topBarTitle}>
            Produtos
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(_, newValue) => setFilterByCompany(newValue)}
                  selectedCompany={filterByCompany}
                  setSelectedCompany={setFilterByCompany}
                  variant="filled"
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={200}>
                <UserAutocomplete
                  handleOnChange={(_, newValue) => setFilterBySupplier(newValue)}
                  selectedUser={filterBySupplier}
                  label="Fornecedor"
                  variant="filled"
                  roles={['supplier']}
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
            <Grid item>
              <FilterOrdenateOptions
                inputLabel="Ordenar"
                filtersOrdenateOptions={filtersOrdenateOptions}
                setFiltersOrdenateOptions={setFiltersOrdenateOptions}
              />
            </Grid>
            <Grid item>
              <FilterCategoriesOptions
                inputLabel="Filtrar por categorias"
                handleChangeCategoriesFilter={setFiltersByCategories}
              />
            </Grid>
            <Grid item>
              <FilterBrandsOptions
                inputLabel="Filtrar por marcas"
                handleChangeBrandsFilter={setFiltersByBrands}
              />
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <SearchField
                  startAdornment={
                    <Grid
                      container
                      alignItems="center"
                      onClick={() => setOnlySku(!onlySku)}
                      marginLeft={-1.5}
                    >
                      <Grid item>
                        <Checkbox checked={onlySku} />
                      </Grid>
                      <Grid item>
                        <ListItemText primary="Apenas SKU" />
                      </Grid>
                    </Grid>
                  }
                  submitSearch={submitSearchProducts}
                  labelSearch="Pesquisar produto"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarProducts);
