import React, { memo, useCallback } from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import UserAutocomplete from '../../Common/UserAutocomplete';
import CustomDatePicker from '../../CustomDatePicker';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';

const useStyles = makeStyles((theme) => ({
  contrastText: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    width: 180,
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function TopBarFinances({
  type,
  setType,
  company,
  setCompany,
  user,
  setUser,
  startDate,
  setStartDate,
  // endDate,
  setEndDate,
  payed,
  setPayed,
}) {
  const classes = useStyles();

  const handleChangeMonth = useCallback((date) => {
    setStartDate(dayjs(date).startOf('month'));
    setEndDate(dayjs(date).endOf('month'));
  }, []);

  return (
    <Toolbar>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h5" className={classes.contrastText}>
            Financeiro
          </Typography>
        </Grid>

        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <UserAutocomplete
                handleOnChange={(event, newValue) => {
                  if (newValue && newValue.userId) {
                    setUser(newValue);
                  } else {
                    setUser(null);
                  }
                }}
                selectedUser={user}
                label="Usuário"
                variant="filled"
                className={classes.selectBackground}
              />
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Tipo</InputLabel>
                  <Select onChange={(event) => setType(event.target.value)} value={type}>
                    <MenuItem value="Recebimento">Recebimento</MenuItem>
                    <MenuItem value="Pagamento">Pagamento</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item>
              <CompanyAutocomplete
                handleOnChange={(event, newValue) => setCompany(newValue)}
                selectedCompany={company}
                variant="filled"
                className={classes.selectBackground}
              />
            </Grid>
            <Grid item>
              <CustomDatePicker
                label="Mês"
                variant="filled"
                size="small"
                openTo="month"
                views={['year', 'month']}
                value={startDate}
                onChange={handleChangeMonth}
                format="MM/YYYY"
                className={classes.selectBackground}
              />
            </Grid>
            <Grid item>
              <Box className={classes.selectBackground}>
                <FormControl fullWidth variant="filled" size="small">
                  <InputLabel>Situação</InputLabel>
                  <Select onChange={(event) => setPayed(event.target.value)} value={payed}>
                    <MenuItem value="Todos">Todos</MenuItem>
                    <MenuItem value="Não pago">Não pago</MenuItem>
                    <MenuItem value="Pago">Pago</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default memo(TopBarFinances);
