import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const createInvoices = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  ordersIds,
  previaDanfe = false,
}) => {
  try {
    setLoadingInvoices(true);
    const response = await Axios.post('/invoices', { ordersIds, previaDanfe });
    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao emitir NF-e',
      error,
    });

    return null;
  }
};

export const createInvoicesDevolutions = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  devolutionsIds,
}) => {
  try {
    setLoadingInvoices(true);
    const response = await Axios.post('/invoices/devolutions', { devolutionsIds });
    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao emitir NF-e de devolução',
      error,
    });

    return null;
  }
};

export const createInvoiceCorrection = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  orderId,
  correction,
}) => {
  try {
    setLoadingInvoices(true);

    const response = await Axios.post('/invoices/correction', {
      orderId,
      correction,
    });

    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar carta de correção da NF-e',
      error,
    });

    return null;
  }
};

export const cancelInvoice = async ({
  setSnackbar,
  setLoadingInvoices = () => {},
  orderId,
  reason,
}) => {
  try {
    setLoadingInvoices(true);

    const response = await Axios.put('/invoices/cancel', {
      orderId,
      reason,
    });

    setLoadingInvoices(false);
    return response.data;
  } catch (error) {
    setLoadingInvoices(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao cancelar NF-e',
      error,
    });

    return null;
  }
};
