import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React from 'react';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';

const useStyles = makeStyles((theme) => ({
  buttonAddImage: {
    width: 140,
    top: -17,
    left: -200,
    cursor: 'pointer',
  },
  typographyAddImage: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  box: {
    minWidth: 850,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
  },
}));

function EmailDialog({
  openEmailDialog,
  handleOpenEmailDialog,
  handleCloseEmailDialog,
  fieldsTemplate,
  setFieldsTemplate,
}) {
  const classes = useStyles();

  const handleSaveTemplateEmail = async () => {
    if (fieldsTemplate.templateEmailId) {
      await Axios.put(`/emails/${fieldsTemplate.templateEmailId}`, {
        templateEmail: {
          name: fieldsTemplate.name,
          matter: fieldsTemplate.matter,
          htmlMessage: Buffer.from(fieldsTemplate.htmlMessage).toString('base64'),
        },
      });
    } else {
      await Axios.post('/emails', {
        templateEmail: {
          name: fieldsTemplate.name,
          matter: fieldsTemplate.matter,
          htmlMessage: Buffer.from(fieldsTemplate.htmlMessage).toString('base64'),
        },
      });
    }
    handleCloseEmailDialog();
    window.location.reload();
  };

  const handleChange = (event) => {
    const fieldValue = event.target.value;
    const fieldName = event.target.name;

    setFieldsTemplate((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  };

  return (
    <SimpleDialog
      dialogTitle="Novo Template"
      openDialog={openEmailDialog}
      handleOpenDialog={handleOpenEmailDialog}
      handleClose={handleCloseEmailDialog}
      disableConfirmButton={
        !fieldsTemplate.name || !fieldsTemplate.matter || !fieldsTemplate.htmlMessage
      }
      content={
        <Box className={classes.box}>
          <Grid container className={classes.container}>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <Typography>Nome do Template</Typography>
              <Grid className={classes.gridDescriptionInput}>
                <CustomInput
                  name="name"
                  label="Nomeie o template"
                  rows={1}
                  value={fieldsTemplate.name}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <Typography>Assunto</Typography>
              <Grid className={classes.gridDescriptionInput}>
                <CustomInput
                  name="matter"
                  label="Informe o assunto do e-mail"
                  rows={1}
                  value={fieldsTemplate.matter}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: '100%',
              }}
            >
              <Typography>HTML do Email</Typography>
              <Grid className={classes.gridDescriptionInput}>
                <CustomInput
                  name="htmlMessage"
                  label="Insira o código HTML do e-mail"
                  rows={20}
                  value={fieldsTemplate.htmlMessage}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Salvar Template"
      handleCancelButton={handleCloseEmailDialog}
      handleConfirmButton={handleSaveTemplateEmail}
    />
  );
}
export default EmailDialog;
