import Axios from 'axios';
import { Alert, Box, Dialog, Grid, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import filterProductOptionsInitial from '../../../../constant/filterProductOptionsInitial';
import { searchProducts } from '../../../../services/melhorGestao/products';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import FilterSelectOptions from '../../../Common/FilterSelectOptions';
import QuoteProductTable from './Table';
import { formatProductToOrder } from '../../../../helpers/formatData';
import filterProductStatusInitial from '../../../../constant/filterProductStatusInitial';
import CompanyAutocomplete from '../../../Common/CompanyAutocomplete';
import UserAutocomplete from '../../../Common/UserAutocomplete';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '90vh',
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function QuoteProductDialog({
  openDialog,
  handleCloseDialog,
  handleConfirmSelect,
  selectSupplierDocument,
  alreadySelectedProducts,
  sendToEntryOrder,
}) {
  const classes = useStyles();
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [countProducts, setProductsCount] = useState(0);
  const [alreadySelectedProductsList, setAlreadySelectedProductsList] = useState([]);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(selectSupplierDocument);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);

  useEffect(() => {
    searchProducts({
      searchText: searchTextSubmited,
      skip: page * limit - limit,
      limit,
      sortDirection: 'asc',
      setLoadingProductsList,
      setProductsList,
      setProductsCount,
      filterByCompany: selectedCompany?._id,
      filterBySupplier: selectedSupplier?.userId,
      filtersOptions: [...filtersOptions, ...filtersStatus],
    });
  }, [
    page,
    limit,
    selectedCompany,
    selectedSupplier,
    filtersOptions,
    filtersStatus,
    searchTextSubmited,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (alreadySelectedProducts && alreadySelectedProducts.length > 0) {
        const productsIds = alreadySelectedProducts.map((product) => product.productId);

        const response = await Axios.get('/catalog/products/list/id', {
          params: {
            productsIds,
          },
        });

        const sortedProductsList = response.data.productsList
          .map((product) => ({
            ...product,
            positionOnList: productsIds.indexOf(product.productId),
          }))
          .sort((a, b) => a.positionOnList - b.positionOnList);

        setAlreadySelectedProductsList(
          sortedProductsList.map((product) => {
            let positionOnList = {};

            if (product.positionOnList) {
              positionOnList = {
                positionOnList: product.positionOnList,
              };
            }

            const { quantity, selectedPrice } = alreadySelectedProducts.find(
              (productFind) => product.productId === productFind.productId,
            );

            const onlySelectedSupplier = product.suppliers.find(
              (supplier) => supplier.userId === selectedSupplier?.userId,
            );

            return {
              ...formatProductToOrder(product),
              ...positionOnList,
              quantity,
              selectedPrice,
              supplierOwnId: onlySelectedSupplier ? onlySelectedSupplier.factoryId : '',
            };
          }),
        );
      }
    };
    fetchData();
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, productsList],
  );

  useEffect(() => {
    setPage(1);
  }, [selectedCompany, selectedSupplier, filtersOptions, filtersStatus]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openDialog}
      onClose={handleCloseDialog}
      classes={{ paper: classes.paper }}
    >
      <Box margin={2}>
        {snackbar.open && (
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        )}
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(event, newValue) => setSelectedCompany(newValue)}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={200}>
                <UserAutocomplete
                  handleOnChange={(event, newValue) => {
                    setSelectedSupplier(newValue);
                  }}
                  selectedUser={selectedSupplier}
                  setSelectedUser={setSelectedSupplier}
                  label="Fornecedor"
                  variant="filled"
                  roles={['supplier']}
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Estoque"
                filtersOptions={filtersOptions}
                setFiltersOptions={setFiltersOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <QuoteProductTable
          selectedCompany={selectedCompany}
          productsList={productsList}
          countProducts={countProducts}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          submitSearchProducts={setSearchTextSubmited}
          filterSupplierCode={selectedSupplier?.userId}
          handleConfirmSelect={handleConfirmSelect}
          handleCloseDialog={handleCloseDialog}
          alreadySelectedProducts={alreadySelectedProductsList}
          sendToEntryOrder={sendToEntryOrder}
        />
      </Box>
      <SimpleBackdrop loading={loadingProductsList} />
    </Dialog>
  );
}

export default memo(QuoteProductDialog);
