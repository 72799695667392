import React, { useState } from 'react';
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import { debitUserCredit, addUserCredit } from '../../../../services/melhorGestao/users';
import {
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../helpers/formatData';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 3,
  },
});

function CreditDialog({
  openCreditDialog,
  handleCloseCreditDialog,
  userForm,
  setUserForm,
  setInitialUserForm,
  refreshOnSave,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [loading, setLoading] = useState(false);
  const [creditMovement, setCreditMovement] = useState('Adicionar');
  const [value, setValue] = useState(0);

  const handleChangeValue = (event) => {
    const newTotalValue = formatReal(onlyNumbersValue(event.target.value));
    setValue(newTotalValue);
  };

  const handleAddCredit = async () => {
    let credit;
    setLoading(true);

    if (creditMovement === 'Adicionar') {
      credit = await addUserCredit({
        userId: userForm.userId,
        value,
        description: 'Crédito adicionado a partir do cadastro do usuário',
        setSnackbar,
      });
    }

    if (creditMovement === 'Debitar') {
      credit = await debitUserCredit({
        userId: userForm.userId,
        value,
        description: 'Crédito debitado a partir do cadastro do usuário',
        setSnackbar,
      });
    }

    setLoading(false);

    if (credit) {
      setUserForm((oldFields) => ({
        ...oldFields,
        credit,
      }));

      setInitialUserForm((oldFields) => ({
        ...oldFields,
        credit,
      }));

      refreshOnSave();
      handleCloseCreditDialog();
    }
  };

  return (
    <SimpleDialog
      openDialog={openCreditDialog}
      handleClose={handleCloseCreditDialog}
      dialogTitle="Gerenciar crédito"
      confirmButtonLoading={loading}
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseCreditDialog}
      handleConfirmButton={handleAddCredit}
      content={
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="standard" size="small">
              <InputLabel>Movimento</InputLabel>
              <Select
                onChange={(event) => setCreditMovement(event.target.value)}
                value={creditMovement}
              >
                <MenuItem value="Adicionar">Adicionar</MenuItem>
                <MenuItem value="Debitar">Debitar</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              label="Valor"
              value={value ? numberToRealWithPeriod(value) : '0,00'}
              onChange={handleChangeValue}
              InputStartAdornment="R$"
              InputAdornmentStyle={classes.inputAdornment}
              variant="standard"
            />
          </Grid>
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Grid>
      }
      disableConfirmButton={value <= 0}
    />
  );
}

export default CreditDialog;
