import {
  Alert,
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import SimpleDialog from '../../Common/SimpleDialog';
import CustomInput from '../../CustomInput';
import { formatHandleError, formatWeight, onlyNumbersValue } from '../../../helpers/formatData';
import getAutoBalancesWeight from '../../../services/melhorGestao/weighingBalances';

function CheckByWeightDialog({
  openCheckByWeightDialog,
  handleCloseCheckByWeight,
  nfeKey,
  handleRefreshAll,
}) {
  const nfeInput = useRef();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [values, setValues] = useState({
    nfeKey: nfeKey || '',
    weight: '0.000',
  });

  const [selectedOrder, setSelectedOrder] = useState();

  useEffect(() => {
    const fetchData = async () => {
      if (values.nfeKey.length === 44) {
        try {
          const response = await Axios.get(`/orders/nfeKey/${values.nfeKey}`);
          const { order } = response.data;

          setSelectedOrder(order);
        } catch (error) {
          formatHandleError({
            setSnackbar,
            defaultMessage: 'Algum erro ocorreu ao buscar pedido com a chave informada',
            error,
          });
        }
      }
    };
    fetchData();
  }, [values.nfeKey]);

  const handleChangeNfeKey = (event) => {
    setValues({ ...values, nfeKey: event.target.value });
  };

  const handleChangeWeight = (event) => {
    const fieldValue = onlyNumbersValue(event.target.value);
    setValues({ ...values, weight: formatWeight(fieldValue) });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchProductText, setSearchProductText] = useState('');
  const [productsList, setProductsList] = useState([]);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [timeoutProductsSearchId, setTimeoutProductsSearchId] = useState();

  const handleConfirmCheckWeight = async () => {
    try {
      const response = await Axios.put('/orders/check-by-weigth', {
        nfeKey: onlyNumbersValue(values.nfeKey),
        weight: values.weight,
        packageId: selectedProduct && selectedProduct.productId ? selectedProduct.productId : null,
      });

      const { orders } = response.data;

      await orders.reduce(async (promise, order) => {
        await promise;

        await Axios.put(`/orders/protect/${order.orderId}`, {
          order: {
            'shipping.packageWeight': values.weight,
          },
        });
      }, Promise.resolve());

      await Axios.post('/orders/stamps', {
        ordersIds: orders.map((order) => order.orderId),
        labelType: 'weight',
        text: values.weight,
      });

      setValues({
        nfeKey: '',
        weight: '0.000',
      });

      setSnackbar({
        message: 'Pedido marcado como embalado',
        open: true,
        type: 'success',
      });

      handleRefreshAll();
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao processar as informações',
        error,
      });
    }
  };

  const handleProductOnChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const searchAllProducts = async () => {
    try {
      setLoadingProductsList(true);
      const response = await Axios.get('/catalog/products/search-admin', {
        params: {
          limit: 20,
          skip: 0,
          searchText: searchProductText,
          sortBy: 'package.weight',
          sortDirection: 'asc',
          filterByTags: ['embalagem'],
        },
      });
      setProductsList(response.data.productsList);
      setLoadingProductsList(false);
    } catch (error) {
      setLoadingProductsList(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao processar as informações',
        error,
      });
    }
  };

  useEffect(() => {
    setLoadingProductsList(true);
    if (timeoutProductsSearchId) {
      clearTimeout(timeoutProductsSearchId);
    }
    setTimeoutProductsSearchId(setTimeout(() => searchAllProducts(), 1000));
  }, [searchProductText]);

  const filterProduct = createFilterOptions({
    stringify: (option) => option.name + option.productId,
  });

  const [loadingBalanceWeight, setLoadingBalanceWeight] = useState(false);

  const handleGetBalancesWeight = async () => {
    setLoadingBalanceWeight(true);
    const weight = await getAutoBalancesWeight({ setSnackbar });
    setValues({ ...values, weight: formatWeight(weight) });
    return setLoadingBalanceWeight(false);
  };

  return (
    <SimpleDialog
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              name="nfeKey"
              label="Chave da NF-e"
              variant="outlined"
              value={values.nfeKey}
              onChange={handleChangeNfeKey}
              inputRef={nfeInput}
              error={values.nfeKey.split('').length !== 44}
            />
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <CustomInput
                  name="weight"
                  label="Peso"
                  variant="outlined"
                  value={values.weight.replace(/\./g, ',')}
                  onChange={handleChangeWeight}
                  error={values.weight === '0.000'}
                  InputEndAdornment="Kg"
                />
              </Grid>
              <Grid item>
                {loadingBalanceWeight ? (
                  <CircularProgress variant="indeterminate" size={20} />
                ) : (
                  <Tooltip
                    placement="right"
                    title={<Typography align="center">Buscar peso da balança</Typography>}
                  >
                    <IconButton size="small" onClick={handleGetBalancesWeight}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              {selectedOrder && selectedOrder.items && selectedOrder.items.length > 0 ? (
                <Grid item>
                  <Grid container spacing={2} justifyContent="flex-end" alignItems="baseline">
                    <Grid item>Peso tabelado:</Grid>
                    <Grid item>
                      <Typography variant="h3">
                        {selectedOrder.items
                          .map((item) => item.quantity * parseFloat(item.package.weight))
                          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
                          .toFixed(3)
                          .replace(/\./g, ',')}{' '}
                        Kg
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item>
            <Autocomplete
              size="small"
              options={productsList}
              filterOptions={filterProduct}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.productId === value.productId}
              renderOption={(props, option) => (
                <Typography {...{ ...props, key: option._id }} variant="caption">
                  {option.name}
                </Typography>
              )}
              onChange={handleProductOnChange}
              noOptionsText="Embalagem não encontrada"
              onInputChange={(event, newInputValue) => {
                setSearchProductText(newInputValue);
              }}
              loading={loadingProductsList}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Embalagem"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingProductsList ? (
                          <CircularProgress variant="indeterminate" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Grid>
      }
      dialogTitle="Conferir pedido por peso"
      dialogText="Informe a chave da NF-e e o peso mostrado na balança"
      openDialog={openCheckByWeightDialog}
      handleClose={handleCloseCheckByWeight}
      cancelButtonText="Cancelar"
      confirmButtonText="Conferir"
      handleCancelButton={handleCloseCheckByWeight}
      handleConfirmButton={handleConfirmCheckWeight}
    />
  );
}

export default memo(CheckByWeightDialog);
