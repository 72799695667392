import { Table, TableContainer } from '@mui/material';
import React from 'react';
import UsersTableFooter from './TableFooter';
import UsersTableHead from './TableHead';
import UsersTableBody from './TableBody';
import SimpleBackdrop from '../../Common/SimpleBackdrop';

function UsersTable({
  usersList,
  totalUsers,
  selectedUser,
  handleClickUser,
  page,
  handleChangePage,
  limit,
  selectedUserInList,
  loadingUsersList,
  handleOnlyOpenDetail,
  docAlongWithName,
}) {
  return (
    <TableContainer>
      <SimpleBackdrop loading={loadingUsersList} absolutePosition />

      <Table>
        <UsersTableHead docAlongWithName={docAlongWithName} />
        <UsersTableBody
          usersList={usersList}
          selectedUser={selectedUser}
          handleClickUser={handleClickUser}
          page={page}
          handleChangePage={handleChangePage}
          selectedUserInList={selectedUserInList}
          loadingUsersList={loadingUsersList}
          handleOnlyOpenDetail={handleOnlyOpenDetail}
          docAlongWithName={docAlongWithName}
        />
        <UsersTableFooter
          page={page}
          totalUsers={totalUsers}
          limit={limit}
          handleChangePage={handleChangePage}
        />
      </Table>
    </TableContainer>
  );
}

export default UsersTable;
