import { Box, Grid } from '@mui/material';
import Axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import DateIcon from '@mui/icons-material/DateRange';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import newPayment from '../../constant/newPayment';
import { searchPayments } from '../../services/melhorGestao/payments';
import { getUsers } from '../../services/melhorGestao/users';
import PaymentDialog from './PaymentDialog';
import PaymentsTable from './PaymentsTable';
import SimpleDialog from '../Common/SimpleDialog';
import FabGroupButtons from '../Common/FabGroupButtons';
import TransferBetweenWalletsDialog from './TransferBetweenWalletsDialog';
import WalletsDetails from './WalletsDetails';
import CustomDatePicker from '../CustomDatePicker';

function Payments() {
  const [paymentList, setPaymentsList] = useState([]);
  const [countPayments, setCountPayments] = useState(0);
  const [filterWallet, setFilterWallet] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(newPayment);
  const [refreshToken, setRefreshToken] = useState();
  const [blockEditPayment, setBlockEditPayment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [payed, setPayed] = useState();
  const [limit] = useState(30);
  const [page, setPage] = useState(1);
  const [type, setType] = useState('');

  const refreshWallet = () => {
    setRefreshToken(Math.random());
  };

  const getPaymentCategory = async (id) => {
    try {
      const response = await Axios.get(`/payments/categories/${id}`);
      const category = response.data;
      return {
        _id: category._id,
        categoryId: category.categoryId,
        name: category.name,
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const getWallet = async (id) => {
    try {
      const response = await Axios.get(`/wallets/list/${id}`);
      const wallet = response.data;
      return wallet;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const wallets = filterWallet.filter((wallet) => wallet.active);

        const payments = await searchPayments({
          setLoading,
          setCountPayments,
          page,
          limit,
          wallets: wallets.map((wallet) => wallet._id),
          searchTextSubmited,
          startDate,
          endDate,
          payed,
          type,
        });

        if (!payments) {
          return false;
        }

        setPage(page);
        const paymentsWithUsers = [];

        const payeeIds = payments.map((payment) =>
          payment.payee && payment.payee.userId ? payment.payee.userId : `${payment.payee || ''}`
        );
        const payerIds = payments.map((payment) =>
          payment.payer && payment.payer.userId ? payment.payer.userId : `${payment.payer || ''}`
        );

        const users = await getUsers([...payeeIds, ...payerIds]);

        const findById = (id) => users.find((user) => user._id === id);
        const findByUserId = (userId) => users.find((user) => user.userId === userId);

        const response = await Axios.get('/orders/list/id', {
          params: {
            ordersIds: payments.map((payment) => payment.orderId || -1),
          },
        });
        const orders = response.data.ordersList;

        const findByOrderId = (orderId) => orders.find((order) => order.orderId === orderId);

        payments.forEach((payment) => {
          let nfe = {};

          if (payment.orderId) {
            const order = findByOrderId(payment.orderId);
            if (order?.invoice?.number) {
              nfe = { nfeId: order.invoice.number };
            }
          }

          paymentsWithUsers.push({
            ...payment,
            ...nfe,
            payee:
              payment.payee && payment.payee.userId
                ? findByUserId(payment.payee.userId)
                : findById(`${payment.payee || ''}`),
            payer:
              payment.payer && payment.payer.userId
                ? findByUserId(payment.payer.userId)
                : findById(`${payment.payer || ''}`),
          });
        });

        setPaymentsList(paymentsWithUsers);
        setLoading(false);
        return true;
      } catch (error) {
        setLoading(false);
        console.log(error);
        return false;
      }
    };
    fetchData();
  }, [filterWallet, searchTextSubmited, payed, endDate, startDate, page, type, refreshToken]);

  const [openDialogPayment, setOpenDialogPayment] = useState(false);

  const handleOpenDialogPayment = async (payment) => {
    setLoading(true);
    const category = await getPaymentCategory(payment.category);
    const wallet = await getWallet(payment.wallet);

    setBlockEditPayment(true);
    setSelectedPayment({
      ...newPayment,
      paymentId: payment.paymentId,
      type: payment.type,
      value: payment.value.base,
      additionalValue: payment.value.deduction,
      discountValue: payment.value.discount,
      finalValue: payment.value.received,
      valuePaid: payment.value.payed,
      condition: payment.condition,
      payDate: payment.payDate,
      dueDate: payment.dueDate,
      payer: payment.payer,
      payee: payment.payee,
      payed: payment.payed,
      category,
      wallet,
      orderId: payment.orderId || null,
      paymentMethod: payment.paymentMethod,
      description: payment.description,
    });

    setLoading(false);
    return setOpenDialogPayment(true);
  };

  const handleOpenDialogNewPayment = () => {
    setBlockEditPayment(false);
    setSelectedPayment(newPayment);
    return setOpenDialogPayment(true);
  };

  const handleCloseDialogPayment = () => {
    setSelectedPayment(newPayment);
    setBlockEditPayment(true);
    return setOpenDialogPayment(false);
  };

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, paymentList]
  );

  const handleChangeStartDate = (date) => setStartDate(new Date(date));
  const handleChangeEndDate = (date) => setEndDate(new Date(date));

  const [openDialogDate, setOpenDialogDate] = useState(false);
  const handleOpenDialogDate = () => setOpenDialogDate(true);
  const handleCloseDialogDate = () => {
    setOpenDialogDate(false);
    setPage(1);
  };

  const [openWalletsDetails, setOpenWalletsDetails] = useState(false);
  const handleOpenWalletsDetails = () => setOpenWalletsDetails(true);
  const handleCloseWalletsDetails = () => setOpenWalletsDetails(false);

  const [openDialogTransferBetweenWallets, setOpenDialogTransferBetweenWallets] = useState(false);
  const handleOpenDialogTransferBetweenWallets = () => setOpenDialogTransferBetweenWallets(true);
  const handleCloseDialogTransferBetweenWallets = () => setOpenDialogTransferBetweenWallets(false);

  const [fabButtons, setFabButtons] = useState([]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Buscar por data',
        onClick: handleOpenDialogDate,
        icon: <DateIcon />,
      },
      {
        title: 'Ver saldos das carteiras',
        onClick: handleOpenWalletsDetails,
        icon: <AccountBalanceWalletIcon />,
      },
      {
        title: 'Transferência entre carteiras',
        onClick: handleOpenDialogTransferBetweenWallets,
        icon: <img src="/assets/imgs/currency-exchange-icon.svg" width="24" alt="" />,
      },
      {
        title: 'Novo pagamento',
        onClick: handleOpenDialogNewPayment,
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <PaymentsTable
          paymentsList={paymentList}
          countPayments={countPayments}
          selectedPayment={selectedPayment}
          handleClickItem={handleOpenDialogPayment}
          setSearchTextSubmited={setSearchTextSubmited}
          setPayed={setPayed}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleChangePage={handleChangePage}
          page={page}
          setPage={setPage}
          limit={limit}
          type={type}
          setType={setType}
          loading={loading}
        />
      </Box>

      <FabGroupButtons fabButtons={fabButtons} />

      {openWalletsDetails ? (
        <WalletsDetails
          openWalletsDetails={openWalletsDetails}
          closeWalletsDetails={handleCloseWalletsDetails}
          filterWallet={filterWallet}
          setFilterWallet={setFilterWallet}
          setPage={setPage}
        />
      ) : null}

      {openDialogTransferBetweenWallets ? (
        <TransferBetweenWalletsDialog
          openDialog={openDialogTransferBetweenWallets}
          handleClose={handleCloseDialogTransferBetweenWallets}
          refreshWallet={refreshWallet}
        />
      ) : null}

      {openDialogPayment ? (
        <PaymentDialog
          payment={selectedPayment}
          openDialog={openDialogPayment}
          handleClose={handleCloseDialogPayment}
          refreshWallet={refreshWallet}
          blockEdit={blockEditPayment}
          setLoading={setLoading}
        />
      ) : null}

      {openDialogDate ? (
        <SimpleDialog
          maxWidth="xs"
          content={
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <CustomDatePicker
                  margin="normal"
                  label="Data inicial"
                  format="DD/MM/YYYY"
                  value={startDate || null}
                  onChange={handleChangeStartDate}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  margin="normal"
                  label="Data final"
                  format="DD/MM/YYYY"
                  value={endDate || null}
                  onChange={handleChangeEndDate}
                />
              </Grid>
            </Grid>
          }
          dialogTitle="Selecionar data para filtrar"
          dialogText="Escolha a data inicial e a final para filtrar lançamentos pela data."
          handleClose={handleCloseDialogDate}
          openDialog={openDialogDate}
        />
      ) : null}
    </Box>
  );
}

export default Payments;
