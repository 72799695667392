import { Grid, MenuItem, Paper, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import {
  DashboardOutlined,
  CategoryOutlined,
  DiscountOutlined,
  ScaleOutlined,
  IntegrationInstructionsOutlined,
  HomeWorkOutlined,
} from '@mui/icons-material';
import TopBarSettings from './TopBarSettings';
import SettingsCms from './SettingsCms';
import SettingsCategory from './SettingsCategory';
import FixedOffers from './FixedOffers';
import IntegrationsErrors from './IntegrationsErrors';
import Companies from './Companies';
import WeighingBalance from './WeighingBalance';

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#000',
  },
  menuButtom: {
    padding: '5px',
    width: '70px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomActive: {
    padding: '5px',
    width: '70px',
    height: '64px',
    borderBottom: '2px solid #ffb000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButtomInside: {
    '&:hover': {
      backgroundColor: 'rgb(0 0 0 / 16%)',
    },
    borderRadius: 6,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuIconButton: {
    width: '1.4em',
    height: '1.4em',
    color: '#fff',
  },
  menuIcon: {
    width: '1.4em',
    height: '1.4em',
    color: '#1C325F',
    marginRight: 8,
  },
  menuIconActive: {
    width: '1.4em',
    height: '1.4em',
    color: '#ffb000',
    marginRight: 8,
  },
  menuIconSmall: {
    color: '#1C325F',
  },
  menuIconActiveSmall: {
    color: '#ffb000',
  },
});

const menuItems = [
  {
    name: 'Empresas',
    icon: HomeWorkOutlined,
  },
  {
    name: 'Websites',
    icon: DashboardOutlined,
  },
  {
    name: 'Categorias',
    icon: CategoryOutlined,
  },
  {
    name: 'Promoções',
    icon: DiscountOutlined,
  },
  {
    name: 'Erros de Integrações',
    icon: IntegrationInstructionsOutlined,
  },
  {
    name: 'Balanças',
    icon: ScaleOutlined,
  },
];

function Settings() {
  const classes = useStyles();

  const [activeMenu, setActiveMenu] = useState(menuItems[0].name);

  return (
    <>
      <TopBarSettings />
      <Paper>
        <Grid container alignItems="center" justifyContent="space-between">
          {menuItems.map((menuItem) => (
            <Grid key={menuItem.name} item xs={6} sm={4} lg={2}>
              <MenuItem onClick={() => setActiveMenu(menuItem.name)}>
                <SvgIcon
                  component={menuItem.icon}
                  className={
                    activeMenu === menuItem.name ? classes.menuIconActive : classes.menuIcon
                  }
                />
                {menuItem.name}
              </MenuItem>
            </Grid>
          ))}
        </Grid>
      </Paper>
      {activeMenu === 'Empresas' && <Companies />}
      {activeMenu === 'Websites' && <SettingsCms />}
      {activeMenu === 'Categorias' && <SettingsCategory />}
      {activeMenu === 'Promoções' && <FixedOffers />}
      {activeMenu === 'Erros de Integrações' && <IntegrationsErrors />}
      {activeMenu === 'Balanças' && <WeighingBalance />}
    </>
  );
}

export default Settings;
