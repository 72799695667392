import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HistoryIcon from '@mui/icons-material/History';
import Axios from 'axios';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  cleanUpDuplicateSpaces,
  formatCpfCpnj,
  formatDateWithHour,
  formatHandleError,
  formatPhone,
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../helpers/formatData';
import TagsButtonGroup from '../../Common/TagsButtonGroup';
import CustomInput from '../../CustomInput';
import AddressCard from './AddressCard';
import checkValidCpfCnpj from '../../../helpers/checkValidCpfCnpj';
import SimpleDialog from '../../Common/SimpleDialog';
import AddressDialog from './AddressDialog';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import PhoneDialog from './PhoneDialog';
import DrawerSubmitArea from '../../Common/DrawerSubmitArea/DrawerSubmitArea';
import { createUser, getUser, updateUser } from '../../../services/melhorGestao/users';
import UserStaffDialog from './userStaffDialog';
import OthersDocumentsCard from './OthersDocumentsCard';
import OtherDocumentDialog from './OtherDocumentDialog';
import CreditHistoryDialog from './CreditHistoryDialog';
import UserAutocomplete from '../../Common/UserAutocomplete';
import getModifiedKeys from '../../../helpers/getModifiedKeys';
import getTasks from '../../../services/melhorGestao/tasks';
import { useAuth } from '../../../contexts/auth';
import CustomDivider from '../../CustomDivider';
import CreditDialog from './CreditDialog';
import simulateMongoObjectId from '../../../helpers/simulateMongoObjectId';
import TagsAutocomplete from '../../Common/TagsAutocomplete';
import CustomDatePicker from '../../CustomDatePicker';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
  chipPhones: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  inputAdornment: {
    marginBottom: 4,
  },
  smallTypography: {
    fontSize: '0.85rem',
  },
  addObservationIcon: {
    width: '1.2em',
    height: '1.2em',
    color: '#1C325F',
  },
  headTableReason: {
    width: 325,
  },
}));

function UsersDetails({
  openDialogDetail,
  handleCloseDialogDetail,
  selectedUser,
  refreshOnSave = () => {},
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const loggedUser = useAuth().user;
  const [initialUserForm, setInitialUserForm] = useState(selectedUser);
  const [userForm, setUserForm] = useState(selectedUser);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [formChanged, setFormChanged] = useState(false);

  const [temporaryRegistrationObservation, setTemporaryRegistrationObservation] = useState({
    blockedCustomer: false,
    showMessageInList: false,
    reason: '',
  });

  useEffect(() => {
    if (getModifiedKeys(userForm, initialUserForm).length > 0) {
      setFormChanged(true);
    } else {
      setFormChanged(false);
    }
  }, [userForm, initialUserForm]);

  const handleSwitchRoles = useCallback(
    ({ roleFieldName }) =>
      setUserForm((oldValues) => ({
        ...oldValues,
        [roleFieldName]: !oldValues[roleFieldName],
      })),
    [],
  );

  const userRoles = [
    {
      value: userForm.customer,
      label: 'Cliente',
      onClick: () => handleSwitchRoles({ roleFieldName: 'customer' }),
    },
    {
      value: userForm.supplier,
      label: 'Fornecedor',
      onClick: () => handleSwitchRoles({ roleFieldName: 'supplier' }),
    },
    {
      value: userForm.exporter,
      label: 'Exportador',
      onClick: () => handleSwitchRoles({ roleFieldName: 'exporter' }),
    },
    {
      value: userForm.shippingCompany,
      label: 'Transportadora',
      onClick: () => handleSwitchRoles({ roleFieldName: 'shippingCompany' }),
    },
    {
      value: userForm.staff,
      label: 'Colaborador',
      onClick: () => handleSwitchRoles({ roleFieldName: 'staff' }),
    },
  ];

  const [seller, setSeller] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (userForm.othersIds?.sellerUserId) {
        const users = await getUser(userForm.othersIds?.sellerUserId);
        if (users && users.userId) {
          setSeller(users);
        }
      }
    };
    fetchData();
  }, []);

  const handleChangeNegativeCreditLimit = (event) => {
    const newTotalValue = onlyNumbersValue(event.target.value);

    setUserForm((oldFields) => ({
      ...oldFields,
      credit: {
        ...oldFields.credit,
        negativeCreditLimit: formatReal(newTotalValue),
      },
    }));
  };

  const handleChangeSeller = useCallback(
    (_, newValue) => {
      if (newValue && newValue.userId) {
        setSeller(newValue);

        setUserForm((oldFields) => ({
          ...oldFields,
          othersIds: {
            ...oldFields.othersIds,
            sellerUserId: newValue.userId,
          },
        }));
      } else {
        setSeller(null);

        setUserForm((oldFields) => ({
          ...oldFields,
          othersIds: {
            ...oldFields.othersIds,
            sellerUserId: null,
          },
        }));
      }
    },
    [seller],
  );

  const handleChangeBirthday = (date) => {
    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        birthday: date,
      },
    }));
  };

  const handleChangeDocuments = useCallback((event) => {
    let fieldValue = event.target.value;
    const fieldType = event.target.type;
    const fieldName = event.target.name;

    if (fieldType === 'checkbox') {
      if (fieldValue === 'false') {
        fieldValue = true;
      } else {
        fieldValue = false;
      }
    }

    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        [fieldName]: fieldValue,
      },
    }));
  }, []);

  const handleChange = useCallback((event) => {
    let fieldValue = event.target.value;
    const fieldType = event.target.type;
    const fieldName = event.target.name;

    if (fieldType === 'checkbox') {
      if (fieldValue === 'false') {
        fieldValue = true;
      } else {
        fieldValue = false;
      }
    }

    setUserForm((oldFields) => ({
      ...oldFields,
      [fieldName]: fieldValue,
    }));
  }, []);

  const handleSwitchType = ({ type }) =>
    setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        pessoa: type,
      },
    }));

  const handleChangeTags = useCallback((event, value) => {
    setUserForm((oldFields) => ({
      ...oldFields,
      tags: value,
    }));
  }, []);

  const handleGetCnpj = useCallback(async () => {
    try {
      const cnpjNumbers = onlyNumbersValue(userForm.documents?.cnpj || '');

      if (cnpjNumbers.length === 14) {
        if (checkValidCpfCnpj(userForm.documents.cnpj)) {
          setLoadingCompanyData(true);

          const response = await Axios.get(
            `/users/receitaws/${onlyNumbersValue(userForm.documents.cnpj)}`,
          );

          const { companyData } = response.data;

          const address = {
            street: companyData.logradouro,
            streetNumber: companyData.numero,
            district: companyData.bairro,
            postalCode: onlyNumbersValue(companyData.cep),
            complement: companyData.complemento,
            city: companyData.municipio,
            state: companyData.uf,
          };

          setUserForm((oldFields) => ({
            ...oldFields,
            name: companyData.fantasia === '' ? companyData.nome : companyData.fantasia,
            phones: [
              ...oldFields.phones,
              {
                number: companyData.telefone,
              },
            ],
            documents: {
              ...oldFields.documents,
              razaoSocial: companyData.nome,
            },
            addresses: [
              ...oldFields.addresses,
              {
                ...address,
              },
            ],
          }));

          setLoadingCompanyData(false);
        } else {
          setSnackbar({
            message: 'O CNPJ inserido é inválido.',
            open: true,
            type: 'error',
          });
        }
      } else {
        setSnackbar({
          message: 'Digite um CNPJ válido.',
          open: true,
          type: 'error',
        });
      }
    } catch (error) {
      setLoadingCompanyData(false);
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Não conseguimos puxar os dados. Aguarde um minuto para tentar novamente.',
        error,
      });
    }
  }, [userForm.documents]);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleDeletePhone = useCallback(
    ({ deleteIndex }) => {
      const updatedPhones = userForm.phones.filter((phone, index) => index !== deleteIndex);
      return setUserForm((oldFields) => ({
        ...oldFields,
        phones: updatedPhones,
      }));
    },
    [userForm.phones],
  );

  const handleDeleteAddress = useCallback(
    ({ deleteIndex }) => {
      const updatedAddresses = userForm.addresses.filter((address, index) => index !== deleteIndex);
      return setUserForm((oldFields) => ({
        ...oldFields,
        addresses: updatedAddresses,
      }));
    },
    [userForm.addresses],
  );

  const handleDeleteOthersDocuments = useCallback(
    ({ deleteIndex }) => {
      const updatedOthersDocuments = userForm.documents.othersDocuments.filter(
        (name, index) => index !== deleteIndex,
      );

      return setUserForm((oldFields) => ({
        ...oldFields,
        documents: {
          ...oldFields.documents,
          othersDocuments: updatedOthersDocuments,
        },
      }));
    },
    [userForm.documents.othersDocuments],
  );

  const [openDialogOtherDocument, setOpenDialogOtherDocument] = useState(false);
  const handleOpenDialogOtherDocument = () => setOpenDialogOtherDocument(true);
  const handleCloseDialogOtherDocument = () => setOpenDialogOtherDocument(false);

  const handleAddOtherDocument = ({ otherDocument }) => {
    if (userForm.documents.othersDocuments && userForm.documents.othersDocuments.length > 0) {
      return setUserForm((oldFields) => ({
        ...oldFields,
        documents: {
          ...oldFields.documents,
          othersDocuments: [
            ...oldFields.documents.othersDocuments,
            {
              name: otherDocument.name
                ? cleanUpDuplicateSpaces(otherDocument.name).toUpperCase()
                : '',
              cpf: otherDocument.cpf ? onlyNumbersValue(otherDocument.cpf) : '',
            },
          ],
        },
      }));
    }
    return setUserForm((oldFields) => ({
      ...oldFields,
      documents: {
        ...oldFields.documents,
        othersDocuments: [
          {
            name: otherDocument.name
              ? cleanUpDuplicateSpaces(otherDocument.name).toUpperCase()
              : '',
            cpf: otherDocument.cpf ? onlyNumbersValue(otherDocument.cpf) : '',
          },
        ],
      },
    }));
  };

  const [openDialogAddress, setOpenDialogAddress] = useState(false);
  const handleOpenDialogAddress = () => setOpenDialogAddress(true);
  const handleCloseDialogAddress = () => setOpenDialogAddress(false);

  const handleAddAddress = ({ address }) =>
    setUserForm((oldFields) => ({
      ...oldFields,
      addresses: [...oldFields.addresses, { ...address }],
    }));

  const [openDialogPhone, setOpenDialogPhone] = useState(false);
  const handleOpenDialogPhone = () => setOpenDialogPhone(true);
  const handleCloseDialogPhone = () => setOpenDialogPhone(false);

  const handleAddPhone = ({ phone }) =>
    setUserForm((oldFields) => ({
      ...oldFields,
      phones: [...oldFields.phones, { ...phone }],
    }));

  const [openDialogStaff, setOpenDialogStaff] = useState(false);
  const handleOpenDialogStaff = () => setOpenDialogStaff(true);
  const handleCloseDialogStaff = () => setOpenDialogStaff(false);

  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const handleOpenCreditDialog = () => setOpenCreditDialog(true);
  const handleCloseCreditDialog = () => setOpenCreditDialog(false);

  const [openDialogCreditHistory, setOpenDialogCreditHistory] = useState(false);
  const handleOpenDialogCreditHistory = () => setOpenDialogCreditHistory(true);
  const handleCloseDialogCreditHistory = () => setOpenDialogCreditHistory(false);

  const [loadingSaveUser, setLoadingSaveUser] = useState(false);

  const handleSaveForm = async ({ updateFields = {} }) => {
    try {
      setFormChanged(false);
      setLoadingSaveUser(true);
      const formatedUser = {
        ...userForm,
        ...updateFields,
        name: userForm.name ? cleanUpDuplicateSpaces(userForm.name).toUpperCase() : '',
        email: userForm.email ? userForm.email.trim().toLowerCase() : '',
        documents: {
          ...userForm.documents,
          razaoSocial: userForm.documents?.razaoSocial
            ? cleanUpDuplicateSpaces(userForm.documents.razaoSocial).toUpperCase()
            : '',
          cpf: userForm.documents?.cpf ? onlyNumbersValue(userForm.documents.cpf) : '',
          cnpj: userForm.documents?.cnpj ? onlyNumbersValue(userForm.documents.cnpj) : '',
          inscricaoEstadual: userForm.documents?.inscricaoEstadual
            ? onlyNumbersValue(userForm.documents.inscricaoEstadual)
            : '',
        },
      };

      if (userForm.userId) {
        const updatedUser = await updateUser({ user: formatedUser, setSnackbar });
        if (updatedUser) {
          setUserForm(updatedUser);
          setInitialUserForm(updatedUser);
        }
      } else {
        const createdUser = await createUser({ user: formatedUser, setSnackbar });
        if (createdUser) {
          setUserForm(createdUser);
          setInitialUserForm(createdUser);
        }
      }

      setLoadingSaveUser(false);
      return refreshOnSave();
    } catch (error) {
      setLoadingSaveUser(false);

      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Algum erro ocorreu ao tentar salvar o usuário',
        error,
      });
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleCheckCloseDetail = () => {
    if (!formChanged) {
      return handleCloseDialogDetail();
    }
    return setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => setConfirmDialogOpen(false);

  const [creditManagerTask, setCreditManagerTask] = useState(false);

  useEffect(() => {
    if (userForm.userId) {
      const fetchData = async () => {
        if (loggedUser) {
          const tasksStaff = await getTasks({ userForm: loggedUser });

          const creditManager = tasksStaff.find((task) => task.name === 'Gerenciar financeiro');
          if (creditManager && creditManager.active) {
            setCreditManagerTask(creditManager.active);
          }
        }
      };
      fetchData();
    }
  }, []);

  const handleChangeTemporaryRegistrationObservation = (fieldName) => {
    setTemporaryRegistrationObservation((oldFields) => ({
      ...oldFields,
      [fieldName]: !oldFields[fieldName],
    }));
  };

  const handleAddRegistrationObservation = () => {
    if (!temporaryRegistrationObservation.reason) {
      return setSnackbar({
        message: 'Informe a descrição da observação',
        open: true,
        type: 'error',
      });
    }

    setUserForm((oldFields) => ({
      ...oldFields,
      registrationObservations: [
        ...(oldFields?.registrationObservations || []),
        {
          ...temporaryRegistrationObservation,
          updatedAt: new Date(),
        },
      ],
    }));

    return setTemporaryRegistrationObservation({
      blockedCustomer: false,
      showMessageInList: false,
      reason: '',
    });
  };

  const handleRemoveRegistrationObservation = (index) => {
    const updatedRegistrationObservations = userForm.registrationObservations.filter(
      (_, observationIndex) => observationIndex !== index,
    );

    setUserForm((oldFields) => ({
      ...oldFields,
      registrationObservations: updatedRegistrationObservations,
    }));
  };

  return (
    <Drawer
      sx={{
        zIndex: 1300,
      }}
      anchor="right"
      open={openDialogDetail}
      onClose={handleCheckCloseDetail}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Chip
                  color="primary"
                  label={selectedUser.userId ? `COD: ${selectedUser.userId}` : 'Novo usuário'}
                />
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="flex-end">
                  <Grid item>
                    Último login no site:{' '}
                    {selectedUser.customerLoggedAt &&
                    formatDateWithHour(selectedUser.customerLoggedAt) !== formatDateWithHour(0)
                      ? formatDateWithHour(selectedUser.customerLoggedAt)
                      : 'Nunca'}
                  </Grid>
                  <Grid item>
                    Último login no admin:{' '}
                    {selectedUser.staffLoggedAt &&
                    formatDateWithHour(selectedUser.staffLoggedAt) !== formatDateWithHour(0)
                      ? formatDateWithHour(selectedUser.staffLoggedAt)
                      : 'Nunca'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
              <Grid item>
                <Tooltip title={<Typography align="center">Tipo de pessoa</Typography>}>
                  <ButtonGroup variant="contained" size="small">
                    <Button
                      onClick={() => handleSwitchType({ type: 'PF' })}
                      color={userForm.documents?.pessoa === 'PF' ? 'primary' : 'default'}
                    >
                      Física
                    </Button>
                    <Button
                      onClick={() => handleSwitchType({ type: 'PJ' })}
                      color={userForm.documents?.pessoa === 'PJ' ? 'primary' : 'default'}
                    >
                      Jurídica
                    </Button>
                  </ButtonGroup>
                </Tooltip>
              </Grid>
              <Grid item>
                <TagsButtonGroup chipButtons={userRoles} />
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Dados básicos</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialogStaff}
                  disabled={!userForm.staff}
                >
                  Permissões
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChange}
                  name="name"
                  value={userForm.name}
                  label={userForm.documents?.pessoa === 'PJ' ? 'Nome Fantasia' : 'Nome'}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChange}
                  name="email"
                  value={userForm.email}
                  label="E-mail"
                />
              </Grid>
            </Grid>
          </Grid>
          {userForm.documents?.pessoa === 'PJ' ? (
            <Grid item>
              <CustomInput
                onChange={handleChangeDocuments}
                name="razaoSocial"
                value={userForm.documents.razaoSocial || ''}
                label="Razão Social"
              />
            </Grid>
          ) : null}
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container alignItems="flex-end">
                  <Grid item xs={9}>
                    {userForm.documents?.pessoa === 'PJ' ? (
                      <CustomInput
                        onChange={handleChangeDocuments}
                        name="cnpj"
                        value={formatCpfCpnj(userForm.documents?.cnpj)}
                        label="CNPJ"
                        length="18"
                      />
                    ) : (
                      <CustomInput
                        onChange={handleChangeDocuments}
                        name="cpf"
                        value={formatCpfCpnj(userForm.documents?.cpf)}
                        label="CPF"
                      />
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleGetCnpj}
                      disabled={userForm.documents?.pessoa === 'PF'}
                    >
                      Buscar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <CustomInput
                  onChange={handleChangeDocuments}
                  name="inscricaoEstadual"
                  value={userForm.documents?.inscricaoEstadual || ''}
                  label="Inscrição Estadual"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <UserAutocomplete
                  setSnackbar={setSnackbar}
                  handleOnChange={handleChangeSeller}
                  selectedUser={seller}
                  label="Vendedor responsável"
                  variant="filled"
                  roles={['staff']}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomDatePicker
                  size="small"
                  label="Aniversário"
                  format="DD/MM/YYYY"
                  variant="filled"
                  value={userForm.documents?.birthday || null}
                  onChange={handleChangeBirthday}
                />
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Financeiro</Typography>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={userForm.monthlyPayer || false}
                            onChange={() =>
                              setUserForm(() => ({
                                ...userForm,
                                monthlyPayer: !userForm.monthlyPayer,
                              }))
                            }
                          />
                        }
                        label="Mensalista"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Tooltip title={<Typography align="center">Gerenciar crédito</Typography>}>
                        <Grid item>
                          <IconButton
                            onClick={handleOpenCreditDialog}
                            disabled={!creditManagerTask}
                          >
                            <MonetizationOnOutlinedIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                      <Tooltip
                        title={<Typography align="center">Movimentação de crédito</Typography>}
                      >
                        <Grid item>
                          <IconButton onClick={handleOpenDialogCreditHistory}>
                            <HistoryIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CustomInput
                  label="Limite de crédito a prazo"
                  value={numberToRealWithPeriod(userForm.credit?.negativeCreditLimit || 0)}
                  onChange={handleChangeNegativeCreditLimit}
                  InputStartAdornment="R$"
                  InputAdornmentStyle={classes.inputAdornment}
                  disabled={!creditManagerTask}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end" spacing={4}>
                  <Grid item>
                    <Typography variant="caption">Débito a pagar</Typography>
                    <Typography variant="h3" style={{ color: '#ff2222' }}>
                      R$ {numberToRealWithPeriod(userForm.credit?.totalPending || 0)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Crédito</Typography>
                    <Typography
                      variant="h3"
                      style={{ color: userForm.credit?.totalValue < 0 ? '#ff2222' : '#0d00fc' }}
                    >
                      R$ {numberToRealWithPeriod(userForm.credit?.totalValue || 0)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CustomDivider />
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Outros dados fiscais</Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleOpenDialogOtherDocument} variant="contained" color="primary">
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {userForm.documents.othersDocuments && userForm.documents.othersDocuments.length > 0 ? (
            <Grid item>
              <Grid container>
                {userForm.documents.othersDocuments.map((otherDocument, index) => (
                  <OthersDocumentsCard
                    key={otherDocument.cpf}
                    otherDocument={otherDocument}
                    otherDocumentIndex={index}
                    handleDeleteOthersDocuments={handleDeleteOthersDocuments}
                  />
                ))}
              </Grid>
            </Grid>
          ) : null}
          <CustomDivider />

          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Telefones</Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleOpenDialogPhone}>
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {userForm.phones && userForm.phones.length > 0 ? (
            <Grid item>
              <Grid container spacing={1} justifyContent="flex-start">
                {userForm.phones
                  ? userForm.phones.map((phone, index) => (
                      <Grid item key={JSON.stringify(phone)}>
                        <Chip
                          className={classes.chipPhones}
                          size="small"
                          label={
                            <Grid container justifyContent="space-between" alignItems="center">
                              <Grid item>
                                <Typography>{formatPhone(phone.number)}</Typography>
                              </Grid>

                              <Grid item>
                                <IconButton
                                  size="small"
                                  onClick={() => handleDeletePhone({ deleteIndex: index })}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Grid>
                    ))
                  : null}
              </Grid>
            </Grid>
          ) : null}
          <CustomDivider />

          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">Endereços</Typography>
              </Grid>
              <Grid item>
                <Button onClick={handleOpenDialogAddress} variant="contained" color="primary">
                  Adicionar
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {userForm.addresses && userForm.addresses.length > 0 ? (
            <Grid item>
              <Grid container>
                {userForm.addresses.map((address, index) => (
                  <AddressCard
                    key={address._id || simulateMongoObjectId()}
                    address={address}
                    addressIndex={index}
                    handleDeleteAddress={handleDeleteAddress}
                  />
                ))}
              </Grid>
            </Grid>
          ) : null}

          {userForm.customer ? (
            <>
              <CustomDivider />
              <Grid item>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h6">Perfil do cliente</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TagsAutocomplete
                  type="users"
                  handleOnChange={handleChangeTags}
                  selectedValue={userForm.tags || []}
                  setSnackbar={setSnackbar}
                />
              </Grid>
            </>
          ) : null}
          {userForm.customer ? (
            <>
              <CustomDivider />
              <Grid item>
                <Typography variant="h6">Observações cadastrais</Typography>
              </Grid>
              <Grid item>
                {userForm?.registrationObservations ? (
                  <Grid container flexDirection="column" marginBottom={2}>
                    <Grid item>
                      <Paper>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.headTableReason} align="center">
                                  Observações
                                </TableCell>
                                <TableCell align="center">Cliente bloqueado</TableCell>
                                <TableCell align="center">Exibir mensagem</TableCell>
                                <TableCell align="center">
                                  <DeleteOutlineIcon />
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userForm.registrationObservations.length > 0 ? (
                                userForm.registrationObservations.map((observation, index) => (
                                  <TableRow key={`${observation.reason}${observation.updatedAt}`}>
                                    <TableCell align="center">
                                      <Typography fontSize={12}>{observation.reason}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography fontSize={12}>
                                        {observation.blockedCustomer ? 'Sim' : 'Não'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography fontSize={12}>
                                        {observation.showMessageInList ? 'Sim' : 'Não'}
                                      </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton
                                        onClick={() => handleRemoveRegistrationObservation(index)}
                                      >
                                        <DeleteOutlineIcon />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow>
                                  <TableCell colSpan={4} align="center">
                                    Sem observações
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                            <TableFooter>
                              <TableRow>
                                <TableCell />
                              </TableRow>
                            </TableFooter>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  <Grid item xs={7.5}>
                    <CustomInput
                      label="Descrição"
                      variant="outlined"
                      value={temporaryRegistrationObservation.reason}
                      onChange={(event) =>
                        setTemporaryRegistrationObservation((oldFields) => ({
                          ...oldFields,
                          reason: event.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={3.5}>
                    <Grid container flexDirection="column">
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              value={temporaryRegistrationObservation.blockedCustomer}
                              onChange={() =>
                                handleChangeTemporaryRegistrationObservation('blockedCustomer')
                              }
                              checked={temporaryRegistrationObservation.blockedCustomer}
                              name="status"
                              color="primary"
                              size="small"
                            />
                          }
                          label={<Box className={classes.smallTypography}>Cliente bloqueado</Box>}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Switch
                              value={temporaryRegistrationObservation.showMessageInList}
                              onChange={() =>
                                handleChangeTemporaryRegistrationObservation('showMessageInList')
                              }
                              checked={temporaryRegistrationObservation.showMessageInList}
                              name="status"
                              color="primary"
                              size="small"
                            />
                          }
                          label={<Box className={classes.smallTypography}>Exibir mensagem</Box>}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton onClick={handleAddRegistrationObservation}>
                      <AddCircleIcon className={classes.addObservationIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>

      <DrawerSubmitArea
        handleClose={handleCheckCloseDetail}
        handleConfirm={handleSaveForm}
        formChanged={formChanged}
      />

      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}

      <SimpleDialog
        openDialog={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        content={<SimpleBackdrop loading={loadingSaveUser} />}
        dialogTitle="Você não salvou as alterações"
        dialogText="Você irá perder todas as alterações que realizou caso deseje sair"
        cancelButtonText="Sair sem salvar"
        confirmButtonText="Salvar alterações"
        handleCancelButton={handleCloseDialogDetail}
        handleConfirmButton={() => {
          handleCloseDialogDetail();
          handleSaveForm({});
        }}
      />
      <AddressDialog
        openDialogAddress={openDialogAddress}
        handleCloseDialogAddress={handleCloseDialogAddress}
        handleAddAddress={handleAddAddress}
      />
      <OtherDocumentDialog
        openDialogOtherDocument={openDialogOtherDocument}
        handleCloseDialogOtherDocument={handleCloseDialogOtherDocument}
        handleAddOtherDocument={handleAddOtherDocument}
      />
      <PhoneDialog
        openDialogPhone={openDialogPhone}
        handleCloseDialogPhone={handleCloseDialogPhone}
        handleAddPhone={handleAddPhone}
      />
      <UserStaffDialog
        openDialogStaff={openDialogStaff}
        handleCloseDialogStaff={handleCloseDialogStaff}
        userForm={userForm}
        handleSaveForm={handleSaveForm}
      />
      <CreditDialog
        openCreditDialog={openCreditDialog}
        handleCloseCreditDialog={handleCloseCreditDialog}
        userForm={userForm}
        setUserForm={setUserForm}
        setInitialUserForm={setInitialUserForm}
        refreshOnSave={refreshOnSave}
      />
      <CreditHistoryDialog
        openDialogCreditHistory={openDialogCreditHistory}
        handleCloseDialogCreditHistory={handleCloseDialogCreditHistory}
        userForm={userForm}
      />
      <SimpleBackdrop loading={loadingCompanyData} />
    </Drawer>
  );
}

export default memo(UsersDetails);
