import { Alert, Box, Paper, Snackbar } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import InputIcon from '@mui/icons-material/Input';
import RefreshIcon from '@mui/icons-material/Refresh';
import Axios from 'axios';
import newUser from '../../../constant/newUser';
import { searchUsers } from '../../../services/melhorGestao/users';
import UsersTable from '../Table';
import UsersTopBar from '../TopBar';
import UsersDetails from '../Details';
import FabGroupButtons from '../../Common/FabGroupButtons';
import filterProductOptionsInitial from '../../../constant/filterUserOptionsInitial';
import userRolesFiltersInitials from '../../../constant/userRolesFiltersInitials';

function UsersPage({
  autoSelectUser = false,
  sendToOrderFabButton,
  handleSelectCustomerToOrder,
  userRolesFilters = userRolesFiltersInitials,
  hiddenTitle,
}) {
  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [openDialogDetail, setOpenDialogDetail] = useState(false);
  const [selectedUserDetail, setSelectedUserDetail] = useState(newUser);
  const [refreshToken, setRefreshToken] = useState();
  const [rolesFilters, setRolesFilters] = useState(userRolesFilters);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);
  const [selectedCity, setSelectedCity] = useState();
  const [tagsOptions, setTagsOptions] = useState([]);

  const refreshSearch = useCallback(() => setRefreshToken(Math.random()), []);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  useEffect(() => {
    const fetchData = () => {
      searchUsers({
        limit,
        skip: page * limit - limit,
        searchText: searchTextSubmited,
        setLoadingUsersList,
        setUsersList,
        setTotalUsers,
        roles: rolesFilters.filter((option) => option.active).map((option) => option.role),
        filtersOptions,
        tag: tagsOptions.filter((option) => option.active).map((option) => option.id),
        selectedCity,
      });
    };
    fetchData();
  }, [
    page,
    limit,
    searchTextSubmited,
    rolesFilters,
    filtersOptions,
    refreshToken,
    tagsOptions,
    selectedCity,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tags = await Axios.get('/mobile-admin/autocomplete', {
          params: {
            type: 'userTags',
          },
        });
        setTagsOptions(tags.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = useCallback(
    (_, value) => {
      setPage(value);
    },
    [limit, page, usersList],
  );

  const handleOpenDialogDetail = useCallback(() => setOpenDialogDetail(true), []);
  const handleCloseDialogDetail = useCallback(() => setOpenDialogDetail(false), []);

  const handleClickNewUser = useCallback(() => {
    setSelectedUserDetail(newUser);
    return setOpenDialogDetail(true);
  }, []);

  const [fabButtons, setFabButtons] = useState([]);
  const [selectedCustomerToOrder, setSelectCustomerToOrder] = useState();

  const handleClickUser = useCallback(
    (user) => {
      if (sendToOrderFabButton) {
        return setSelectCustomerToOrder(user);
      }
      setSelectedUserDetail(user);
      return handleOpenDialogDetail();
    },
    [handleOpenDialogDetail, sendToOrderFabButton],
  );

  const handleSendUserToOrder = useCallback(() => {
    if (selectedCustomerToOrder) {
      if (
        selectedCustomerToOrder?.registrationObservations &&
        selectedCustomerToOrder?.registrationObservations.length > 0
      ) {
        const findBlockUser = selectedCustomerToOrder.registrationObservations.find(
          (observation) => observation.blockedCustomer,
        );

        if (findBlockUser) {
          return setSnackbar({
            message: findBlockUser.showMessageInList
              ? findBlockUser.reason
              : 'Este cliente possui pendências, verifique com o financeiro antes de prosseguir',
            open: true,
            type: 'error',
          });
        }
      }
      return handleSelectCustomerToOrder({ user: selectedCustomerToOrder });
    }

    return null;
  }, [selectedCustomerToOrder]);

  useEffect(() => {
    if (autoSelectUser) {
      handleSendUserToOrder();
    }
  }, [selectedCustomerToOrder]);

  useEffect(() => {
    if (sendToOrderFabButton && handleSelectCustomerToOrder) {
      setFabButtons([
        {
          title: 'Atualizar listagem',
          onClick: refreshSearch,
          icon: <RefreshIcon />,
        },
        {
          title: 'Novo cliente',
          onClick: handleClickNewUser,
          icon: <AddIcon />,
          featured: true,
        },
        {
          title: 'Enviar usuário selecionado para o pedido',
          onClick: handleSendUserToOrder,
          icon: <InputIcon />,
          featured: true,
        },
      ]);
    }
  }, [selectedCustomerToOrder]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Atualizar listagem',
        onClick: refreshSearch,
        icon: <RefreshIcon />,
      },
      {
        title: 'Novo cliente',
        onClick: handleClickNewUser,
        icon: <AddIcon />,
        featured: true,
      },
    ];

    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <>
      <Box position="relative">
        <Box marginTop={2}>
          <UsersTopBar
            submitSearch={setSearchTextSubmited}
            filtersOptions={filtersOptions}
            setFiltersOptions={setFiltersOptions}
            rolesFilters={rolesFilters}
            setRolesFilters={setRolesFilters}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            tagsOptions={tagsOptions}
            setTagsOptions={setTagsOptions}
            hiddenTitle={hiddenTitle}
          />
        </Box>
        <Box marginTop={2} marginBottom={5}>
          <Paper>
            <UsersTable
              usersList={usersList}
              totalUsers={totalUsers}
              selectedUser={selectedUserDetail}
              handleClickUser={handleClickUser}
              page={page}
              handleChangePage={handleChangePage}
              limit={limit}
              selectedUserInList={selectedCustomerToOrder}
              loadingUsersList={loadingUsersList}
            />
          </Paper>
        </Box>

        <FabGroupButtons fabButtons={fabButtons} />

        {openDialogDetail && (
          <UsersDetails
            openDialogDetail={openDialogDetail}
            handleCloseDialogDetail={handleCloseDialogDetail}
            selectedUser={selectedUserDetail}
            refreshOnSave={refreshSearch}
          />
        )}
      </Box>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default memo(UsersPage);
