import Axios from 'axios';
import { Alert, Box, Dialog, Fab, Grid, Paper, Snackbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState, useEffect, memo } from 'react';
import UsersTable from '../../Users/Table';
import UsersDetails from '../../Users/Details';
import OrderDetails from '../../Orders/Details';
import { searchUsers } from '../../../services/melhorGestao/users';
import newUser from '../../../constant/newUser';
import filterProductOptionsInitial from '../../../constant/filterUserOptionsInitial';
import SearchField from '../../Common/SearchField';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import userRolesFiltersInitials from '../../../constant/userRolesFiltersInitials';
import OrdersTable from './OrdersTable';
import { numberToRealWithPeriod } from '../../../helpers/formatData';
import newOrder from '../../../constant/newOrder';
import ProcessOrdersDialog from '../../Orders/Dialogs/ProcessOrdersInPreparation';
import newFinance from '../../../constant/newFinance';

const useStyles = makeStyles({
  paper: {
    height: '90vh',
  },
  paperTable: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
  fabLeftBelow: {
    position: 'fixed',
    bottom: 20,
  },
  searchTopRight: {
    position: 'fixed',
    top: 20,
  },
  fabRight: {
    position: 'fixed',
    bottom: 20,
    right: 50,
  },
});

function OrdersTermModal({
  openOrdersTermModal,
  handleCloseOrdersTermModal,
  selectFinance,
  setType,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [page, setPage] = useState(1);
  const [limit] = useState(20);
  const [selectedUser, setSelectedUser] = useState(newUser);
  const [selectedOrder, setSelectedOrder] = useState(newOrder);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [formatedRolesFilters, setFormatedRolesFilters] = useState([]);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);
  const [rolesFilters, setRolesFilters] = useState(userRolesFiltersInitials);

  const [refreshTokenUsers, setRefreshTokenUsers] = useState(Math.random());
  const [refreshTokenOrders, setRefreshTokenOrders] = useState(Math.random());

  const handleRefreshAll = useCallback(() => {
    setRefreshTokenUsers(Math.random());
    setRefreshTokenOrders(Math.random());
  });

  useEffect(() => {
    setFormatedRolesFilters(
      rolesFilters.filter((option) => option.active).map((option) => option.role),
    );
  }, [rolesFilters]);

  useEffect(() => {
    const fetchData = () => {
      searchUsers({
        limit,
        skip: page * limit - limit,
        searchText: searchTextSubmited,
        setLoadingUsersList,
        setUsersList,
        setTotalUsers,
        roles: formatedRolesFilters,
        filtersOptions,
      });
    };
    fetchData();
  }, [page, limit, searchTextSubmited, formatedRolesFilters, filtersOptions, refreshTokenUsers]);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, usersList],
  );

  const handleClickUser = useCallback((user) => {
    setSelectedOrders([]);
    setSelectedUser(user);
  }, []);

  const getSelectedOrdersReceiptsTotal = () => {
    const getFinalValue = (payment) => {
      const value = payment.value || 0;
      const decreaseValue = payment.decreaseValue || 0;
      const additionalValue = payment.additionalValue || 0;

      return value - decreaseValue + additionalValue;
    };

    let totalValue = 0;

    selectedOrders.forEach((order) => {
      let haveReceipt = false;

      order.payments.forEach((payment) => {
        if (payment.type === 'Recebimento') {
          totalValue += getFinalValue(payment);
          haveReceipt = true;
        }
      });

      if (!haveReceipt) {
        totalValue += order.orderTotal;
      }
    });

    return totalValue;
  };

  const [openUserDetails, setOpenUserDetails] = useState(false);
  const handleOpenUserDetails = useCallback(() => setOpenUserDetails(true), []);
  const handleCloseUserDetails = useCallback(() => setOpenUserDetails(false), []);

  const handleOnlyOpenUserDetail = useCallback((user) => {
    setSelectedUser(user);
    handleOpenUserDetails();
  }, []);

  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const handleOpenOrderDetails = useCallback(() => setOpenOrderDetails(true), []);
  const handleCloseOrderDetails = useCallback(() => {
    setOpenOrderDetails(false);
    handleRefreshAll();
  }, []);

  const handleOnlyOpenOrderDetail = useCallback((order) => {
    setSelectedOrder(order);
    handleOpenOrderDetails();
  }, []);

  const [openDialogProcessOrders, setOpenDialogProcessOrders] = useState();
  const handleOpenDialogProcessOrders = () => setOpenDialogProcessOrders(true);
  const handleCloseDialogProcessOrders = () => setOpenDialogProcessOrders(false);

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openOrdersTermModal}
      onClose={handleCloseOrdersTermModal}
      classes={{ paper: classes.paper }}
    >
      <Box margin={2}>
        <Grid container direction="column">
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <FilterSelectOptions
                  inputLabel="Filtrar clientes com"
                  filtersOptions={filtersOptions}
                  setFiltersOptions={setFiltersOptions}
                />
              </Grid>
              <Grid item>
                <FilterSelectOptions
                  inputLabel="Filtrar por papéis"
                  filtersOptions={rolesFilters}
                  setFiltersOptions={setRolesFilters}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={2}>
              <Box position="relative" right="10px">
                <Box className={classes.fabLeftBelow}>
                  <Fab variant="extended" size="small">
                    Total dos pedidos: R$ {numberToRealWithPeriod(getSelectedOrdersReceiptsTotal())}
                  </Fab>
                </Box>
              </Box>

              <Grid item xs={6}>
                <Grid container justifyContent="space-between" alignItems="baseline">
                  <Typography variant="h6">Selecionar usuário:</Typography>
                  <Typography variant="caption">Exibindo {usersList.length} usuários</Typography>
                </Grid>

                <Paper className={classes.paperTable}>
                  <UsersTable
                    usersList={usersList}
                    totalUsers={totalUsers}
                    selectedUser={selectedUser}
                    handleClickUser={handleClickUser}
                    page={page}
                    handleChangePage={handleChangePage}
                    limit={limit}
                    selectedUserInList={selectedUser}
                    loadingUsersList={loadingUsersList}
                    handleOnlyOpenDetail={handleOnlyOpenUserDetail}
                    docAlongWithName
                  />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="space-between" alignItems="baseline">
                  <Typography variant="h6">Pedidos do usuário:</Typography>
                  <Typography variant="caption">{selectedOrders.length} selecionados</Typography>
                </Grid>

                <Paper className={classes.paperTable}>
                  <OrdersTable
                    key={refreshTokenOrders}
                    selectedUser={selectedUser}
                    selectedOrders={selectedOrders}
                    setSelectedOrders={setSelectedOrders}
                    filtersOptions={filtersOptions}
                    handleOnlyOpenDetail={handleOnlyOpenOrderDetail}
                    refreshTokenOrders={refreshTokenOrders}
                  />
                </Paper>
              </Grid>
              <Box position="relative" right="235px">
                <Box className={classes.searchTopRight}>
                  <SearchField
                    submitSearch={setSearchTextSubmited}
                    labelSearch="Pesquisar usuário"
                  />
                </Box>
              </Box>
              {selectedOrders.length > 0 ? (
                <Box position="relative" right="300px">
                  <Box className={classes.fabRight}>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Fab
                          variant="extended"
                          color="primary"
                          onClick={async () => {
                            const responseCompanies = await Axios.get('/configuration/companies');
                            const getCompanies = responseCompanies.data;

                            const company = getCompanies.find(
                              (findCompany) => findCompany._id === selectedOrders[0].company,
                            );

                            if (!company) {
                              return setSnackbar({
                                message: 'Empresa não encontrada no cadastro do sistema',
                                open: true,
                                type: 'error',
                              });
                            }

                            if (!selectedOrders.every((order) => order.company === company._id)) {
                              return setSnackbar({
                                message: 'Selecione somente pedidos da mesma empresa',
                                open: true,
                                type: 'error',
                              });
                            }

                            setType('Recebimento');
                            return selectFinance({
                              ...newFinance,
                              company,
                              user: selectedUser,
                              type: 'Recebimento',
                              value: getSelectedOrdersReceiptsTotal(),
                              documents: [
                                ...selectedOrders.map((order) => ({
                                  type: 'Pedido',
                                  number: order.orderId,
                                })),
                              ],
                            });
                          }}
                        >
                          Criar financeiro
                        </Fab>
                      </Grid>
                      <Grid item>
                        <Fab
                          variant="extended"
                          color="primary"
                          onClick={() => handleOpenDialogProcessOrders()}
                        >
                          Processar pedidos selecionados
                        </Fab>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        {openUserDetails ? (
          <UsersDetails
            openDialogDetail={openUserDetails}
            handleCloseDialogDetail={handleCloseUserDetails}
            selectedUser={selectedUser}
            refreshOnSave={handleRefreshAll}
          />
        ) : null}
        {openOrderDetails ? (
          <OrderDetails
            openOrderDetails={openOrderDetails}
            closeOrderDetails={handleCloseOrderDetails}
            selectedOrder={selectedOrder}
          />
        ) : null}
        {openDialogProcessOrders ? (
          <ProcessOrdersDialog
            handleCloseDialogProcessOrders={handleCloseDialogProcessOrders}
            openDialogProcessOrders={openDialogProcessOrders}
            checkedOrders={selectedOrders.map((order) => order.orderId)}
            handleRefreshAll={handleRefreshAll}
          />
        ) : null}

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Dialog>
  );
}

export default memo(OrdersTermModal);
