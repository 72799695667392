import { Alert, Box, Grid, Snackbar } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import SimpleDialog from '../../../Common/SimpleDialog';
import CustomInput from '../../../CustomInput';
import { toFixedFloat } from '../../../../helpers/formatData';

const useStyles = makeStyles({
  inputAdornment: {
    marginBottom: 0,
  },
});

function DiscountDialog({
  openDialogDiscount,
  handleCloseDialogDiscount,
  selectedProducts,
  selectedProduct,
  setSelectedProducts,
}) {
  const classes = useStyles();

  const selectedPercentage = (1 - selectedProduct.selectedPrice / selectedProduct.price) * 100;
  const selectedDiscountValue = selectedProduct.price - selectedProduct.selectedPrice;

  const [discountPercentage, setDiscountPercentage] = useState(toFixedFloat(selectedPercentage));
  const [discountValue, setDiscountValue] = useState(toFixedFloat(selectedDiscountValue));
  const [priceNumber, setPriceNumber] = useState(selectedProduct.selectedPrice);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleChangeDiscountPercentage = (event) => {
    const { value } = event.target;
    setDiscountPercentage(value);

    const valueWithDiscount = selectedProduct.price * (1 - value / 100);
    const valueDiscount = selectedProduct.price - valueWithDiscount;

    setDiscountValue(toFixedFloat(valueDiscount));
    setPriceNumber(toFixedFloat(valueWithDiscount));
  };

  const handleChangeDiscountValue = (event) => {
    const { value } = event.target;
    setDiscountValue(value);

    const valueWithDiscount = selectedProduct.price - value;
    const percentageDiscount = (1 - valueWithDiscount / selectedProduct.price) * 100;

    setPriceNumber(toFixedFloat(valueWithDiscount));
    setDiscountPercentage(toFixedFloat(percentageDiscount));
  };

  const handleChangePriceNumber = (event) => {
    const { value } = event.target;
    setPriceNumber(value);

    const valueDiscount = selectedProduct.price - value;
    const percentageDiscount = (1 - value / selectedProduct.price) * 100;

    setDiscountValue(toFixedFloat(valueDiscount));
    setDiscountPercentage(toFixedFloat(percentageDiscount));
  };

  const handleConfirmDiscount = () => {
    if (priceNumber <= 0) {
      return setSnackbar({
        message: 'O preço de venda está zerado ou abaixo de zero.',
        open: true,
        type: 'error',
      });
    }

    if (priceNumber > 2 * selectedProduct.price) {
      return setSnackbar({
        message: 'O preço de venda está a cima do dobro do valor do produto.',
        open: true,
        type: 'error',
      });
    }

    setSelectedProducts(
      selectedProducts.map((product) => {
        if (product.productId === selectedProduct.productId) {
          return {
            ...product,
            selectedPrice: priceNumber,
          };
        }
        return product;
      }),
    );

    return handleCloseDialogDiscount();
  };

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  return (
    <SimpleDialog
      content={
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Box width="140px">
              <CustomInput
                label="Desconto em %"
                value={discountPercentage}
                onChange={handleChangeDiscountPercentage}
                InputEndAdornment="%"
                InputAdornmentStyle={classes.inputAdornment}
                size="medium"
                number
              />
            </Box>
          </Grid>
          <Grid item>
            <Box width="140px">
              <CustomInput
                label="Valor do desconto"
                value={discountValue}
                onChange={handleChangeDiscountValue}
                InputStartAdornment="R$"
                InputAdornmentStyle={classes.inputAdornment}
                size="medium"
                number
              />
            </Box>
          </Grid>
          <Grid item>
            <Box width="140px">
              <CustomInput
                label="Preço de venda"
                value={priceNumber}
                onChange={handleChangePriceNumber}
                InputStartAdornment="R$"
                InputAdornmentStyle={classes.inputAdornment}
                size="medium"
                number
              />
            </Box>
          </Grid>
          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogDiscount}
      handleConfirmButton={handleConfirmDiscount}
      dialogTitle="Alterar preço promocional"
      dialogText="Escolha o valor do desconto"
      handleClose={handleCloseDialogDiscount}
      openDialog={openDialogDiscount}
      maxWidth="sm"
    />
  );
}

export default DiscountDialog;
