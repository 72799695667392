import React, { memo } from 'react';
import { Box, Grid, Paper, Toolbar, Typography } from '@mui/material';
import SearchField from '../Common/SearchField';
import CompanyAutocomplete from '../Common/CompanyAutocomplete';
import FilterSelectOptions from '../Common/FilterSelectOptions';

function TopBarProducts({
  mercadoLivreConfig,
  setMercadoLivreConfig,
  mercadoLivreConfigsList,
  setMercadoLivreConfigsList,
  submitSearchProducts,
  filtersOptions,
  setFiltersOptions,
}) {
  return (
    <Paper>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">Anúncios do Mercado Livre</Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Box width={250}>
                  {mercadoLivreConfigsList && !setMercadoLivreConfigsList ? (
                    <CompanyAutocomplete
                      handleOnChange={(event, newValue) => {
                        if (newValue) {
                          setMercadoLivreConfig(
                            mercadoLivreConfigsList.find(
                              (mercadoLivreConfigList) =>
                                mercadoLivreConfigList.company === newValue?._id,
                            ) || null,
                          );
                        }
                      }}
                      selectedCompany={mercadoLivreConfig?.company || null}
                      filterCompaniesByIds={mercadoLivreConfigsList.map(
                        (mlConfig) => mlConfig.company,
                      )}
                      label="Contas"
                      variant="filled"
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid item>
                <FilterSelectOptions
                  inputLabel="Filtrar anúncios"
                  filtersOptions={filtersOptions}
                  setFiltersOptions={setFiltersOptions}
                />
              </Grid>
              <Grid item>
                <SearchField submitSearch={submitSearchProducts} labelSearch="Pesquisar anúncio" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </Paper>
  );
}

export default memo(TopBarProducts);
