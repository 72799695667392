import Axios from 'axios';
import { formatHandleError } from '../../helpers/formatData';

export const searchUsers = async ({
  limit,
  skip,
  searchText,
  setLoadingUsersList,
  setUsersList,
  setTotalUsers = () => {},
  setSnackbar = () => {},
  roles = [],
  filtersOptions = [],
  tag = [],
  selectedCity,
}) => {
  try {
    setLoadingUsersList(true);

    const response = await Axios.get('/users/search', {
      params: {
        limit,
        skip,
        searchText,
        roles,
        filtersOptions,
        tag,
        selectedCity,
      },
    });

    const { totalResults, usersList } = response.data;
    setUsersList(usersList);
    setTotalUsers(totalResults);
    return setLoadingUsersList(false);
  } catch (error) {
    setLoadingUsersList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar os usuários',
      error,
    });
  }
};

export const searchAllSuppliers = async ({
  setLoadingSuppliersList,
  setSuppliersList,
  setSnackbar = () => {},
}) => {
  try {
    setLoadingSuppliersList(true);

    const response = await Axios.get('/users/search', {
      params: {
        limit: 2000,
        roles: ['supplier'],
      },
    });

    const { usersList } = response.data;
    setLoadingSuppliersList(false);
    return setSuppliersList(usersList);
  } catch (error) {
    setLoadingSuppliersList(false);
    return formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao tentar carregar os fornecedores',
      error,
    });
  }
};

export const createUser = async ({ user, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/users', user);
    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao criar usuário',
      error,
    });

    return null;
  }
};

export const updateUser = async ({ user, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.put(`/users/${user.userId}`, user);
    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao atualizar usuário',
      error,
    });

    return null;
  }
};

export const getUser = async (id) => {
  try {
    const response = await Axios.get(`/users/${id}`);

    const user = response.data.findUser;
    return user;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao carregar usuário');
    return null;
  }
};

export const getUsers = async (ids) => {
  try {
    const response = await Axios.patch(`/users`, {
      ids,
    });

    const users = response.data;
    return users;
  } catch (error) {
    console.log(error.response?.data?.message || 'Algum erro ocorreu ao carregar usuários');
    return [];
  }
};

export const getCities = async ({ setLoadingCitiesList, setCitiesList, setSnackbar }) => {
  try {
    setLoadingCitiesList(true);
    const response = await Axios.get('/users/cities');

    const cities = response.data;
    setCitiesList(cities);
    setLoadingCitiesList(false);
  } catch (error) {
    setLoadingCitiesList(false);
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao carregar cidades',
      error,
    });
  }
};

export const addUserCredit = async ({ userId, value, description, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/users/credit/add', { userId, value, description });
    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao adicionar crédito para usuário',
      error,
    });

    return null;
  }
};

export const debitUserCredit = async ({ userId, value, description, setSnackbar = () => {} }) => {
  try {
    const response = await Axios.post('/users/credit/debit', { userId, value, description });
    return response.data;
  } catch (error) {
    formatHandleError({
      setSnackbar,
      defaultMessage: 'Algum erro ocorreu ao debitar o crédito do usuário',
      error,
    });

    return null;
  }
};
