import { Box, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import React, { memo, useState } from 'react';
import { formatCpfCpnj } from '../../../../../helpers/formatData';
import SimpleDialog from '../../../../Common/SimpleDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 25,
  },
  smallCell: {
    width: 70,
  },
  mediumCell: {
    width: 125,
  },
  tableHead: {
    backgroundColor: grey[100],
  },
  typographyCursor: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelectedMain: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.grey[300],
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function OtherDocumentDialog({
  openDialogOtherDocument,
  handleCloseOtherDocumentDialog,
  orderForm,
  setOrderForm,
  officialDocument,
  othersDocuments,
}) {
  const classes = useStyles();

  const [selectedOtherDocumentIndex, setSelectedOtherDocumentIndex] = useState('');
  const handleConfirmSelectOtherDocument = () => handleCloseOtherDocumentDialog();

  const handleChange = (event) => {
    setSelectedOtherDocumentIndex(event.target.value);

    if (event.target.value === 'officialCpf') {
      return setOrderForm((oldFields) => ({
        ...oldFields,
        customer: {
          ...oldFields.customer,
          name: officialDocument.name,
          documents: {
            ...oldFields.customer.documents,
            pessoa: 'PF',
            cpf: officialDocument.cpf,
            inscricaoEstadual: 'ISENTO',
          },
        },
      }));
    }

    if (event.target.value === 'officialCnpj') {
      return setOrderForm((oldFields) => ({
        ...oldFields,
        customer: {
          ...oldFields.customer,
          name: officialDocument.name,
          documents: {
            ...oldFields.customer.documents,
            pessoa: 'PJ',
            cnpj: officialDocument.cnpj,
            inscricaoEstadual: officialDocument.inscricaoEstadual,
          },
        },
      }));
    }

    return setOrderForm((oldFields) => ({
      ...oldFields,
      customer: {
        ...oldFields.customer,
        name: othersDocuments[event.target.value].name,
        documents: {
          ...oldFields.customer.documents,
          pessoa: 'PF',
          cpf: othersDocuments[event.target.value].cpf,
          inscricaoEstadual: 'ISENTO',
        },
      },
    }));
  };

  return (
    <SimpleDialog
      content={
        <Box position="relative" minWidth={300}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <FormControl fullWidth variant="filled" size="small">
                <InputLabel>Selecione o documento</InputLabel>
                <Select
                  className={classes.select}
                  value={selectedOtherDocumentIndex}
                  onChange={handleChange}
                >
                  {orderForm.customer.documents.cpf && !orderForm.customer.documents.cnpj ? (
                    <MenuItem value="officialCpf">
                      {officialDocument.name}, {formatCpfCpnj(officialDocument.cpf)}
                    </MenuItem>
                  ) : null}

                  {orderForm.customer.documents.cnpj ? (
                    <MenuItem value="officialCnpj">
                      {officialDocument.name}, {formatCpfCpnj(officialDocument.cnpj)}
                    </MenuItem>
                  ) : null}

                  {othersDocuments.map((otherDocument, index) => (
                    <MenuItem value={index} key={otherDocument.cpf}>
                      {otherDocument.name}, {formatCpfCpnj(otherDocument.cpf)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      }
      cancelButtonText="Cancelar"
      dialogTitle="Dados fiscais"
      handleClose={handleCloseOtherDocumentDialog}
      handleCancelButton={handleCloseOtherDocumentDialog}
      openDialog={openDialogOtherDocument}
      confirmButtonText="Confirmar"
      handleConfirmButton={handleConfirmSelectOtherDocument}
    />
  );
}

export default memo(OtherDocumentDialog);
