import Axios from 'axios';
import React, { memo, useEffect, useState } from 'react';
import {
  Alert,
  Autocomplete,
  Button,
  ButtonGroup,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import dayjs from 'dayjs';
import SimpleDialog from '../../../../Common/SimpleDialog';
import {
  formatHandleError,
  formatReal,
  numberToRealWithPeriod,
  onlyNumbersValue,
} from '../../../../../helpers/formatData';
import CustomInput from '../../../../CustomInput';
import PaymentMethods from '../../../../Payments/PaymentMethods';
import CustomDatePicker from '../../../../CustomDatePicker';
import paymentMethodList from '../../../../../constant/paymentMethodList';
import { getUser } from '../../../../../services/melhorGestao/users';
import getUserCreditLimit from '../../../../../helpers/getUserCreditLimit';

const useStyles = makeStyles({
  typePaper: {
    padding: 10,
  },
});

const gatewayPaymentsIds = [
  {
    gatewayId: 'Sicoob Checkout Transparente',
    methods: ['Pix', 'Boleto'],
  },
  {
    gatewayId: 'Bradesco Checkout Transparente',
    methods: ['Pix', 'Boleto'],
  },
  {
    gatewayId: 'Mercado Pago Checkout Pro',
    methods: [],
  },
  {
    gatewayId: 'Crédito Loja',
    methods: [],
  },
];

function PaymentDialog({
  openDialogPayment,
  handleCloseDialogPayment,
  paymentForm,
  setPaymentForm,
  orderForm,
  setOrderForm,
  setInitialOrderForm,
  devolutions,
  blockEdit,
}) {
  const classes = useStyles();

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const getOrderTotal = () => {
    let orderTotal = 0;

    if (paymentForm.type === 'Recebimento') {
      orderTotal = orderForm.orderTotal;
    }
    if (paymentForm.type === 'Pagamento') {
      orderTotal = devolutions.reduce((total, devol) => total + devol.devolutionTotal, 0);
    }

    return orderTotal;
  };

  const getValueAlreadyAdded = () =>
    orderForm.payments
      .filter((payment) => payment.type === paymentForm.type)
      .reduce((total, payment) => total + payment.value, 0);

  const getValueAlreadyPaid = (type) =>
    orderForm.payments
      .filter((payment) => payment.type === type)
      .reduce((total, payment) => total + payment.valuePaid / 100, 0);

  const getFinalValue = () =>
    paymentForm.value - paymentForm.decreaseValue + paymentForm.additionalValue;

  const handleChangeStatus = (status) => {
    const valuePaid = status === 'Pago' ? getFinalValue() : 0;

    const updatedFields = {
      status,
      payDate: status === 'Pago' ? dayjs() : null,
      valuePaid: parseInt(valuePaid.toFixed(2).replace(/\./g, ''), 10),
    };

    if (status === 'Não pago' && paymentForm.condition === 'A PRAZO') {
      return setPaymentForm((oldFields) => ({
        ...oldFields,
        ...updatedFields,
        dueDate: dayjs().add(30, 'day'),
        observation: '',
      }));
    }
    if (status === 'Não pago' && paymentForm.condition === 'A VISTA') {
      return setPaymentForm((oldFields) => ({
        ...oldFields,
        ...updatedFields,
        dueDate: dayjs().add(8, 'day'),
        observation: 'Receber na entrega',
      }));
    }
    return setPaymentForm((oldFields) => ({
      ...oldFields,
      ...updatedFields,
      dueDate: dayjs().add(4, 'day'),
      observation: '',
    }));
  };

  const handleChangeCondition = (condition) => {
    if (condition === 'A PRAZO') {
      return setPaymentForm((oldFields) => ({
        ...oldFields,
        condition,
        status: 'Não pago',
        dueDate: dayjs().add(30, 'day'),
        observation: '',
      }));
    }
    if (condition === 'A VISTA' && paymentForm.status === 'Não pago') {
      return setPaymentForm((oldFields) => ({
        ...oldFields,
        condition,
        dueDate: dayjs().add(8, 'day'),
        observation: 'Receber na entrega',
      }));
    }
    return setPaymentForm((oldFields) => ({
      ...oldFields,
      condition,
      dueDate: dayjs().add(4, 'day'),
      observation: '',
    }));
  };

  const handleChangeValue = (event) => {
    const value = onlyNumbersValue(event.target.value);
    const parsedValue = parseFloat(formatReal(value));

    if (parsedValue <= getOrderTotal()) {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        value: parsedValue,
      }));
    } else {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        value: getOrderTotal() - getValueAlreadyAdded(),
      }));
    }
  };

  const handleChangeDiscount = (event) => {
    const value = onlyNumbersValue(event.target.value);
    const parsedValue = parseFloat(formatReal(value));

    if (parsedValue >= 0 && parsedValue <= paymentForm.value) {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        additionalValue: 0,
        decreaseValue: parsedValue,
      }));
    }
  };

  const handleChangeAddition = (event) => {
    const value = onlyNumbersValue(event.target.value);
    const parsedValue = parseFloat(formatReal(value));

    if (parsedValue >= 0) {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        decreaseValue: 0,
        additionalValue: parsedValue,
      }));
    }
  };

  const handleChangeObservation = (event) => {
    setPaymentForm((oldFields) => ({
      ...oldFields,
      observation: event.target.value,
    }));
  };

  const handleChangeDueDate = (date) => {
    setPaymentForm((oldFields) => ({
      ...oldFields,
      dueDate: date,
    }));
  };

  const handleChangePayDate = (date) => {
    setPaymentForm((oldFields) => ({
      ...oldFields,
      payDate: date,
    }));
  };

  const handleChangeGatewayPaymentId = (event, newValue) => {
    if (newValue) {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        method: 'Aguardando financeiro',
        othersIds: {
          ...oldFields.othersIds,
          gatewayPaymentId: newValue.gatewayId,
        },
      }));
    } else {
      setPaymentForm((oldFields) => ({
        ...oldFields,
        method: 'Aguardando financeiro',
        othersIds: {
          ...oldFields.othersIds,
          gatewayPaymentId: undefined,
        },
      }));
    }
  };

  const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);

  const handleConfirmPayment = async () => {
    if (paymentForm.othersIds?.gatewayPaymentId) {
      try {
        setConfirmButtonLoading(true);
        const response = await Axios.post(`/orders/payment/${orderForm.orderId}`, {
          gatewayPaymentId: paymentForm.othersIds.gatewayPaymentId,
          type: paymentForm.type,
          paymentMethod: paymentForm.method,
          totalValue: getFinalValue(),
          dueDate: paymentForm.dueDate,
        });

        setConfirmButtonLoading(false);
        if (response.data.success) {
          setOrderForm(response.data.updatedOrder);
          setInitialOrderForm(response.data.updatedOrder);
          return handleCloseDialogPayment();
        }

        return setSnackbar({
          message: 'Não foi possível gerar o link de pagamento no momento, tente mais tarde.',
          open: true,
          type: 'error',
        });
      } catch (error) {
        setConfirmButtonLoading(false);
        return formatHandleError({
          setSnackbar,
          defaultMessage: 'Algum erro ocorreu ao tentar gerar o link de pagamento',
          error,
        });
      }
    }

    if (paymentForm.editIndex >= 0) {
      setOrderForm((oldFields) => ({
        ...oldFields,
        payments: oldFields.payments.map((payment, index) => {
          if (index === paymentForm.editIndex) {
            return { ...payment, ...paymentForm };
          }
          return payment;
        }),
      }));
    } else {
      setOrderForm((oldFields) => ({
        ...oldFields,
        payments: [...oldFields.payments, paymentForm],
      }));
    }
    return handleCloseDialogPayment();
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [disableConfirmButton, setDisableConfirmButton] = useState(true);
  const [excludeMethods, setExcludeMethods] = useState(['Crédito', 'Link de pagamento']);

  useEffect(() => {
    const selectedGateway = gatewayPaymentsIds.find(
      (gateway) => gateway.gatewayId === paymentForm.othersIds?.gatewayPaymentId,
    );

    if (selectedGateway) {
      const methodsExcluded = paymentMethodList
        .filter((method) => !selectedGateway.methods.includes(method.name))
        .map((method) => method.name);

      setExcludeMethods([...methodsExcluded, 'Crédito', 'Link de pagamento']);
    } else {
      setExcludeMethods(['Crédito', 'Link de pagamento']);
    }

    const fetchData = async () => {
      if (blockEdit) {
        setErrorMessage('');
        return setDisableConfirmButton(true);
      }

      if (paymentForm.value <= 0) {
        setErrorMessage('Valor tem que ser maior que zero');
        return setDisableConfirmButton(true);
      }

      if (paymentForm.type === 'Pagamento') {
        if (paymentForm.editIndex) {
          if (getValueAlreadyAdded() > getValueAlreadyPaid('Recebimento')) {
            setErrorMessage('Valor de devolução não pode ultrapassar o recebido');
            return setDisableConfirmButton(true);
          }
        }
        if (!paymentForm.editIndex) {
          if (paymentForm.value + getValueAlreadyAdded() > getValueAlreadyPaid('Recebimento')) {
            setErrorMessage('Valor de devolução não pode ultrapassar o recebido');
            return setDisableConfirmButton(true);
          }
        }
      }

      if (!selectedGateway || selectedGateway.methods.length > 0) {
        if (paymentForm.method === 'Aguardando financeiro' && paymentForm.status === 'Pago') {
          setErrorMessage('Escolha uma forma de pagamento');
          return setDisableConfirmButton(true);
        }
      }

      if (paymentForm.othersIds?.gatewayPaymentId === 'Crédito Loja') {
        if (paymentForm.type === 'Recebimento' && orderForm.customer.userId) {
          const user = await getUser(orderForm.customer.userId);

          if (user && user.credit) {
            const creditLimit = getUserCreditLimit(user);

            if (getFinalValue() > creditLimit) {
              setErrorMessage(`Crédito disponível: R$ ${numberToRealWithPeriod(creditLimit)}`);
              return setDisableConfirmButton(true);
            }
          }
          if (user && !user.credit) {
            setErrorMessage('Crédito insuficiente');
            return setDisableConfirmButton(true);
          }
        }
      }

      setErrorMessage('');
      return setDisableConfirmButton(false);
    };
    fetchData();
  }, [
    blockEdit,
    paymentForm.type,
    paymentForm.value,
    paymentForm.method,
    paymentForm.status,
    paymentForm.decreaseValue,
    paymentForm.additionalValue,
    paymentForm.othersIds?.gatewayPaymentId,
  ]);

  return (
    <SimpleDialog
      maxWidth="xs"
      openDialog={openDialogPayment}
      handleClose={handleCloseDialogPayment}
      dialogTitle={
        <Grid container spacing={1}>
          {paymentForm.type === 'Pagamento' ? (
            <Grid item>
              <RemoveIcon sx={{ color: '#ff2222', marginTop: 0.5 }} />
            </Grid>
          ) : (
            <Grid item>
              <AddIcon sx={{ color: '#00c600', marginTop: 0.5 }} />
            </Grid>
          )}
          <Grid item>{paymentForm.type || 'Recebimento'}</Grid>
        </Grid>
      }
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Paper elevation={2} className={classes.typePaper}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography>Valor do {paymentForm.type}:</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h1">
                        R$ {numberToRealWithPeriod(getOrderTotal())}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            value={paymentForm.status === 'Pago'}
                            onChange={() =>
                              handleChangeStatus(
                                paymentForm.status === 'Pago' ? 'Não pago' : 'Pago',
                              )
                            }
                            checked={paymentForm.status === 'Pago'}
                            color="primary"
                            disabled={blockEdit}
                          />
                        }
                        label={paymentForm.status}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonGroup variant="contained" disabled={blockEdit}>
                        <Button
                          onClick={() => handleChangeCondition('A VISTA')}
                          color={paymentForm.condition === 'A VISTA' ? 'primary' : 'default'}
                        >
                          A vista
                        </Button>
                        <Button
                          onClick={() => handleChangeCondition('A PRAZO')}
                          color={paymentForm.condition === 'A PRAZO' ? 'primary' : 'default'}
                        >
                          A prazo
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <CustomInput
              label="Observações"
              value={paymentForm.observation}
              onChange={handleChangeObservation}
              variant="outlined"
              disabled={blockEdit}
            />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CustomInput
                  label="Valor"
                  value={numberToRealWithPeriod(paymentForm.value)}
                  onChange={handleChangeValue}
                  error={paymentForm.value <= 0}
                  variant="outlined"
                  disabled={blockEdit}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  label="Desconto"
                  value={numberToRealWithPeriod(paymentForm.decreaseValue)}
                  onChange={handleChangeDiscount}
                  variant="outlined"
                  disabled={blockEdit}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  label="Acréscimo"
                  value={numberToRealWithPeriod(paymentForm.additionalValue)}
                  onChange={handleChangeAddition}
                  variant="outlined"
                  disabled={blockEdit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <CustomDatePicker
                  size="small"
                  label="Data de vencimento"
                  format="DD/MM/YYYY"
                  value={paymentForm.dueDate}
                  onChange={handleChangeDueDate}
                  disabled={blockEdit}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomDatePicker
                  size="small"
                  label="Data de pagamento"
                  format="DD/MM/YYYY"
                  value={paymentForm.payDate}
                  onChange={handleChangePayDate}
                  disabled={blockEdit || paymentForm.status === 'Não pago'}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <Autocomplete
                  size="small"
                  options={gatewayPaymentsIds}
                  disabled={blockEdit}
                  getOptionLabel={(option) => option.gatewayId}
                  value={
                    gatewayPaymentsIds.find(
                      (gateway) => gateway.gatewayId === paymentForm.othersIds?.gatewayPaymentId,
                    ) || null
                  }
                  onChange={handleChangeGatewayPaymentId}
                  noOptionsText="Opção não encontrada"
                  renderInput={(params) => (
                    <TextField {...params} label="Processar pagamento com" variant="outlined" />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <CustomInput
                  label="Valor pago"
                  value={numberToRealWithPeriod(paymentForm.valuePaid / 100)}
                  variant="outlined"
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <PaymentMethods
              blockEdit={blockEdit}
              paymentMethod={paymentForm.method}
              setPaymentMethod={(method) => {
                setPaymentForm((oldFields) => ({
                  ...oldFields,
                  method,
                }));
              }}
              excludeMethods={excludeMethods}
            />
          </Grid>

          {errorMessage ? (
            <Grid item>
              <Typography color="error" align="center">
                {errorMessage}
              </Typography>
            </Grid>
          ) : (
            <Grid item>
              <Typography align="center">{paymentForm.method}</Typography>
            </Grid>
          )}

          {snackbar.open && (
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
                {snackbar.message}
              </Alert>
            </Snackbar>
          )}
        </Grid>
      }
      cancelButtonText="Cancelar"
      confirmButtonText="Confirmar"
      handleCancelButton={handleCloseDialogPayment}
      handleConfirmButton={handleConfirmPayment}
      confirmButtonLoading={confirmButtonLoading}
      disableConfirmButton={disableConfirmButton}
    />
  );
}

export default memo(PaymentDialog);
