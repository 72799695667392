import { Table, TableContainer } from '@mui/material';
import React, { memo, useCallback, useEffect, useState } from 'react';
import OrdersTableFooter from './TableFooter';
import OrdersTableHead from './TableHead';
import OrdersTableBody from './TableBody';
import SimpleBackdrop from '../../../Common/SimpleBackdrop';
import { searchOrders } from '../../../../services/melhorGestao/orders';

function OrdersTable({
  selectedUser,
  selectedOrders,
  setSelectedOrders,
  filtersOptions,
  handleOnlyOpenDetail,
  refreshTokenOrders,
}) {
  const [ordersList, setOrdersList] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [loadingOrdersList, setLoadingOrdersList] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(20);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, ordersList],
  );

  useEffect(() => {
    const fetchData = () => {
      searchOrders({
        skip: page * limit - limit,
        limit,
        setLoadingOrdersList,
        setOrdersList,
        setCountOrders: setTotalOrders,
        filterByUserId: selectedUser.userId || -1,
        filtersOptions,
      });
    };
    fetchData();
  }, [page, limit, selectedUser, refreshTokenOrders]);

  return (
    <TableContainer>
      <SimpleBackdrop loading={loadingOrdersList} absolutePosition />

      <Table>
        <OrdersTableHead />
        <OrdersTableBody
          ordersList={ordersList}
          selectedOrders={selectedOrders}
          setSelectedOrders={setSelectedOrders}
          page={page}
          handleChangePage={handleChangePage}
          loadingOrdersList={loadingOrdersList}
          handleOnlyOpenDetail={handleOnlyOpenDetail}
        />
        <OrdersTableFooter
          page={page}
          totalOrders={totalOrders}
          limit={limit}
          handleChangePage={handleChangePage}
        />
      </Table>
    </TableContainer>
  );
}

export default memo(OrdersTable);
