import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Link as LinkMaterial,
  Typography,
  Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import LinkIcon from '@mui/icons-material/Link';
import CircularProgressWithLabel from '../Common/CircularProgressWithLabel';
import { numberToReal } from '../../helpers/formatData';
import TableCellControlled from './TableCellControlled';
import CustomPagination from '../Common/CustomPagination';
import SimpleImage from '../Common/SimpleImage';
import DialogIconDeleteMercadoLivreAd from './MarketplacesDetails/MercadoLivreDetails/DialogIconDeleteMercadoLivreAd';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: 25,
  },
  typographyCursor: {
    cursor: 'pointer',
  },
  sellerLogoGrid: {
    height: 48,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.main,
  },
  sellerLogoImg: {
    width: 110,
    margin: '0px 10px',
  },
  cellStatus: {
    width: 150,
  },
  cellPrice: {
    width: 110,
  },
  cellControlled: {
    width: 140,
  },
  errorChip: {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  closeIcon: {
    color: '#ddd',
  },
}));

function TableMercadoLivreAds({
  selectAd,
  mercadoLivreConfig,
  mercadoLivreAdsList,
  mercadoLivreAdsCount,
  setRefreshSearch,
  handleChangePage,
  page,
  limit,
  setSnackbar,
}) {
  const classes = useStyles();

  const getTag = ({ product, tagName }) => {
    let tagMatched = false;
    if (product.tags) {
      product.tags.forEach((tag) => {
        if (tag === tagName) {
          tagMatched = true;
        }
      });
    }

    return tagMatched;
  };

  return (
    <Paper className={classes.paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TableCell>Vendedor</TableCell>
            <TableCell>Imagem</TableCell>
            <TableCell>Título</TableCell>
            <TableCell align="center">SKU</TableCell>
            <TableCell align="center" className={classes.cellControlled}>
              Sincronismo
            </TableCell>
            <TableCell align="center" className={classes.cellPrice}>
              Preço
            </TableCell>
            <TableCell align="center" className={classes.cellStatus}>
              Situação
            </TableCell>
            <TableCell align="center">Qualidade</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mercadoLivreAdsList.length > 0 &&
            mercadoLivreAdsList.map((ad) => (
              <TableRow key={ad.mlb}>
                <TableCell align="center">
                  <Tooltip title={ad.mlb}>
                    <LinkMaterial
                      href={ad.permalink}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <IconButton size="small" className={classes.smallIcon}>
                        <LinkIcon />
                      </IconButton>
                    </LinkMaterial>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.sellerLogoGrid}
                  >
                    {mercadoLivreConfig?.srcLogo ? (
                      <SimpleImage
                        src={mercadoLivreConfig.srcLogo}
                        alt="Logo da empresa"
                        className={classes.sellerLogoImg}
                      />
                    ) : null}
                  </Grid>
                </TableCell>
                <TableCell>
                  <SimpleImage src={ad.thumbnail} imgHeight={50} imgWidth={50} alt={ad.title} />
                </TableCell>
                <TableCell>
                  <>
                    <Typography>{ad.title}</Typography>
                    {getTag({ product: ad, tagName: 'best_price_eligible' }) ? (
                      <Chip size="small" label="Elegível para Promoção" />
                    ) : null}
                    {getTag({ product: ad, tagName: 'poor_quality_picture' }) ||
                    getTag({ product: ad, tagName: 'poor_quality_thumbnail' }) ? (
                      <Chip
                        size="small"
                        label="Imagem não adequada"
                        className={classes.errorChip}
                      />
                    ) : null}
                  </>
                </TableCell>
                <TableCell align="center">
                  {ad.productId ? (
                    <Typography>{ad.productId}</Typography>
                  ) : (
                    <CloseIcon className={classes.closeIcon} />
                  )}
                </TableCell>
                <TableCell align="center">
                  <TableCellControlled ad={ad} selectAd={selectAd} />
                </TableCell>
                <TableCell align="center">{`R$ ${numberToReal(ad.price)}`}</TableCell>
                <TableCell align="center">
                  <Grid container direction="column">
                    <Typography variant="caption">
                      {ad.status === 'active' ? 'Ativo' : null}
                      {ad.status === 'paused' ? 'Pausado' : null}
                      {ad.status === 'closed' ? 'Finalizado' : null}
                      {ad.status === 'under_review' ? 'A revisar' : null}
                    </Typography>
                    <Typography variant="caption">{ad.soldQuantity} vendas</Typography>
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  {ad.catalogListing ? (
                    <Typography>Catálogo</Typography>
                  ) : (
                    <CircularProgressWithLabel value={ad.health * 100} />
                  )}
                </TableCell>
                <TableCell align="center">
                  <DialogIconDeleteMercadoLivreAd
                    mercadoLivreAds={ad}
                    setRefreshSearch={setRefreshSearch}
                    setSnackbar={setSnackbar}
                  />
                </TableCell>
              </TableRow>
            ))}

          {page !== 1 && mercadoLivreAdsList.length === 0 && (
            <TableRow>
              <TableCell colSpan={10}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography
                      onClick={() => handleChangePage()}
                      className={classes.typographyCursor}
                    >
                      Nenhum anúncio nesta página, clique aqui para voltar a página.
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}

          {page === 1 && mercadoLivreAdsList.length === 0 && (
            <TableRow>
              <TableCell colSpan={10}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography>Nenhum anúncio para exibir</Typography>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={10}>
              <CustomPagination
                page={page}
                total={mercadoLivreAdsCount}
                limit={limit}
                handleChangePage={handleChangePage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </Paper>
  );
}

export default memo(TableMercadoLivreAds);
