import React, { useState, useCallback, useEffect, memo } from 'react';
import { Box, Fab, Grid, Slide, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RefreshIcon from '@mui/icons-material/Refresh';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import TopBarOrders from './TopBar';
import TableOrders from './Table';
import { searchOrders } from '../../services/melhorGestao/orders';
import OrderDetails from './Details';
import newOrder from '../../constant/newOrder';
import ProcessOrdersDialog from './Dialogs/ProcessOrdersInPreparation';
import filterOrderSourceInitial from '../../constant/filterOrderSourceInitial';
import filterOrderStatusInitial from '../../constant/filterOrderStatusInitial';
import FabGroupButtons from '../Common/FabGroupButtons';
import SituationsFiltersPapers from '../Common/SituationsFiltersPapers';
import filterOrderSituationsInitial from '../../constant/filterOrderSituationsInitial';
import CheckByWeightDialog from './Dialogs/CheckByWeightDialog';

const useStyles = makeStyles((theme) => ({
  textLink: {
    cursor: 'pointer',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(6),
    right: theme.spacing(12),
  },
  boxCheckedQuantity: {
    bottom: -15,
    right: 0,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 20,
    width: 30,
    height: 30,
    position: 'absolute',
  },
}));

function Orders() {
  const classes = useStyles();

  const [filterOrderSource, setFilterOrderSource] = useState(filterOrderSourceInitial);
  const [filterOrderStatus, setFilterOrderStatus] = useState(filterOrderStatusInitial);
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTextSubmited, setSearchTextSubmited] = useState('');
  const [ordersList, setOrdersList] = useState([]);
  const [countOrders, setCountOrders] = useState(0);
  const [loadingOrdersList, setLoadingOrdersList] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(40);
  const [refreshSearchToken, setRefreshSearchToken] = useState(Math.random());
  const [situationsFilters, setSituationsFilters] = useState(filterOrderSituationsInitial);

  useEffect(() => {
    if (filterOrderCompany.length > 0) {
      searchOrders({
        searchText: searchTextSubmited,
        skip: page * limit - limit,
        limit,
        setLoadingOrdersList,
        setOrdersList,
        setCountOrders,
        filtersOptions: situationsFilters,
        filterOrderSource,
        filterOrderStatus,
        filterOrderCompany,
      });
    }
  }, [
    searchTextSubmited,
    page,
    limit,
    refreshSearchToken,
    filterOrderSource,
    filterOrderStatus,
    filterOrderCompany,
  ]);

  const selectOrder = (order) => {
    setSelectedOrder(order);
    setOpenOrderDetails(true);
  };

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, ordersList],
  );

  const handleRefreshAll = () => setRefreshSearchToken(Math.random());

  const closeOrderDetails = useCallback(() => {
    setOpenOrderDetails(false);
    handleRefreshAll();
  }, []);

  const checkIfAnyActive = (filterArray) => {
    let anyActive = false;
    filterArray.forEach((filter) => {
      if (
        filter.active &&
        filter.id !== 'Bloqueio de desconto' &&
        filter.id !== 'Ocorrências pendentes'
      ) {
        anyActive = true;
      }
    });
    return anyActive;
  };

  const handleClickFilter = () => {
    if (checkIfAnyActive(situationsFilters)) {
      setFilterOrderStatus(() =>
        filterOrderStatus.map((orderStatus) => {
          const situationFilter = situationsFilters.find(
            (filter) => filter.active && filter.id === orderStatus.name,
          );

          if (situationFilter) {
            return {
              ...orderStatus,
              active: true,
            };
          }

          return {
            ...orderStatus,
            active: false,
          };
        }),
      );
    }

    if (!checkIfAnyActive(situationsFilters)) {
      setFilterOrderStatus(() =>
        filterOrderStatus.map((orderStatus) => ({
          ...orderStatus,
          active: true,
        })),
      );
    }
  };

  const [openDialogProcessOrders, setOpenDialogProcessOrders] = useState();
  const handleOpenDialogProcessOrders = () => setOpenDialogProcessOrders(true);
  const handleCloseDialogProcessOrders = () => setOpenDialogProcessOrders(false);
  const [checkedOrders, setCheckedOrders] = useState([]);

  const [nfeKey, setNfeKey] = useState('');
  const [openCheckByWeightDialog, setCheckByWeightDialog] = useState(false);

  const handleOpenCheckByWeight = (nfeKeyValue) => {
    closeOrderDetails();
    setNfeKey(nfeKeyValue);
    setCheckByWeightDialog(true);
  };

  const handleCloseCheckByWeight = () => setCheckByWeightDialog(false);

  return (
    <Box position="relative">
      <Box marginTop={2}>
        <TopBarOrders
          submitSearchOrders={setSearchTextSubmited}
          filterOrderStatus={filterOrderStatus}
          filterOrderSource={filterOrderSource}
          filterOrderCompany={filterOrderCompany}
          setFilterOrderStatus={setFilterOrderStatus}
          setFilterOrderSource={setFilterOrderSource}
          setFilterOrderCompany={setFilterOrderCompany}
          setPage={setPage}
        />
      </Box>
      <Box marginTop={2}>
        <SituationsFiltersPapers
          refreshFiltersWithQuantity={refreshSearchToken}
          situationsFilters={situationsFilters}
          setSituationsFilters={setSituationsFilters}
          handleClickFilter={handleClickFilter}
          orders
        />
      </Box>
      <Box marginTop={2}>
        <TableOrders
          ordersList={ordersList}
          countOrders={countOrders}
          selectOrder={selectOrder}
          selectedOrder={selectedOrder}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          checkedOrders={checkedOrders}
          setCheckedOrders={setCheckedOrders}
          loadingOrdersList={loadingOrdersList}
        />
      </Box>

      {openOrderDetails ? (
        <Box>
          <OrderDetails
            openOrderDetails={openOrderDetails}
            selectedOrder={selectedOrder}
            closeOrderDetails={closeOrderDetails}
            handleOpenCheckByWeight={handleOpenCheckByWeight}
            submitSearchOrders={setSearchTextSubmited}
            setFilterOrderStatus={setFilterOrderStatus}
            setFilterOrderSource={setFilterOrderSource}
            setFilterOrderCompany={setFilterOrderCompany}
          />
        </Box>
      ) : null}

      {openCheckByWeightDialog ? (
        <CheckByWeightDialog
          openCheckByWeightDialog={openCheckByWeightDialog}
          handleCloseCheckByWeight={handleCloseCheckByWeight}
          nfeKey={nfeKey}
          handleRefreshAll={handleRefreshAll}
        />
      ) : null}

      <Box className={classes.fab}>
        <Slide
          direction="down"
          in={checkedOrders.length > 0}
          mountOnEnter
          unmountOnExit
          timeout={500}
        >
          <Grid item>
            <Tooltip
              placement="left"
              title={<Typography align="center">Processar pedidos selecionados</Typography>}
            >
              <Box position="relative">
                <Fab onClick={handleOpenDialogProcessOrders} color="primary">
                  <DoneAllIcon />
                  <Box className={classes.boxCheckedQuantity}>
                    <Typography color="black" marginTop="4px">
                      {checkedOrders.length}
                    </Typography>
                  </Box>
                </Fab>
              </Box>
            </Tooltip>
            {openDialogProcessOrders ? (
              <ProcessOrdersDialog
                selectOrder={selectOrder}
                handleCloseDialogProcessOrders={handleCloseDialogProcessOrders}
                openDialogProcessOrders={openDialogProcessOrders}
                checkedOrders={checkedOrders}
                handleRefreshAll={handleRefreshAll}
              />
            ) : null}
          </Grid>
        </Slide>
      </Box>
      <FabGroupButtons
        fabButtons={[
          {
            title: 'Atualizar listagem',
            onClick: handleRefreshAll,
            icon: <RefreshIcon />,
          },
          {
            title: 'Conferir pedido por peso',
            onClick: () => handleOpenCheckByWeight(''),
            icon: <OfflinePinIcon />,
          },
          {
            title: 'Novo pedido',
            onClick: () => selectOrder(newOrder),
            icon: <AddIcon />,
            featured: true,
          },
        ]}
      />
    </Box>
  );
}

export default memo(Orders);
