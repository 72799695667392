import { Box, Drawer, Grid, Snackbar, Alert, FormControlLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import newEntryOrder from '../../../constant/newEntryOrder';
import calculateProductCost from '../../../helpers/calculateProductCost';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatHandleError,
} from '../../../helpers/formatData';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import PaymentsTab from './PaymentsTab';
import ProductsTab from './ProductsTab';
import ShippingTab from './ShippingTab';
import StatusTab from './StatusTab';
import SubmitArea from './SubmitArea';
import SupplierTab from './SupplierTab';
import TopTab from './TopTab';
import CustomDivider from '../../CustomDivider';
import getTasks from '../../../services/melhorGestao/tasks';
import { useAuth } from '../../../contexts/auth';
import checkValidCpfCnpj from '../../../helpers/checkValidCpfCnpj';
import CustomInput from '../../CustomInput';
import userRolesFiltersInitials from '../../../constant/userRolesFiltersInitials';
import TaxesTab from './TaxesTab';
import EntryInvoiceEmissionButton from './EntryInvoiceEmissionButton';

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down('xs')]: {
      width: 365,
    },
    width: 620,
    marginBottom: 120,
  },
  select: {
    minWidth: 190,
  },
}));
function ProductEntry({
  openProductEntry,
  handleCloseProductEntry,
  selectedEntryOrder = newEntryOrder,
  handleRefresh = () => {},
}) {
  const classes = useStyles();
  const loggedUser = useAuth().user;

  const [supplierRolesFilters, setSupplierRolesFilters] = useState(userRolesFiltersInitials);
  const [blockFields] = useState(selectedEntryOrder.status === 'Pedido concluído');
  const [initialEntryOrder, setInitialEntryOrder] = useState(selectedEntryOrder);
  const [entryOrder, setEntryOrder] = useState(selectedEntryOrder);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loadingUploadXml, setLoadingUploadXml] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });

  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const handleChangeCompany = useCallback(
    (event, newValue) =>
      setEntryOrder((oldFields) => ({
        ...oldFields,
        company: newValue,
      })),
    [],
  );

  const handleChangeType = useCallback((type) => {
    setEntryOrder((oldFields) => ({
      ...oldFields,
      type,
    }));

    if (type === 'Importação para revenda') {
      return setSupplierRolesFilters(
        userRolesFiltersInitials.map((initialRole) => {
          if (initialRole.role === 'exporter') {
            return { ...initialRole, active: true };
          }
          return { ...initialRole, active: false };
        }),
      );
    }

    return setSupplierRolesFilters(
      userRolesFiltersInitials.map((initialRole) => {
        if (initialRole.role === 'supplier') {
          return { ...initialRole, active: true };
        }
        return { ...initialRole, active: false };
      }),
    );
  }, []);

  useEffect(() => {
    const totalValueProducts = entryOrder.products.reduce(
      (total, product) => total + product.entryUnitaryCost * product.entryQuantity,
      0,
    );

    if (entryOrder.invoice?.key) {
      return setEntryOrder((oldFields) => ({
        ...oldFields,
        totalValueProducts,
      }));
    }

    const totalIpiProducts = entryOrder.products.reduce(
      (total, product) => total + product.ipi * product.quantity,
      0,
    );

    const shippingValue = parseFloat(entryOrder.shipping.value) || 0;
    const shippingInsurance = parseFloat(entryOrder.shipping.insurance) || 0;

    return setEntryOrder((oldFields) => ({
      ...oldFields,
      totalValueProducts,
      totalValue: totalValueProducts + totalIpiProducts + shippingValue + shippingInsurance,
    }));
  }, [entryOrder.products, entryOrder.shipping]);

  const saveProductCost = useCallback(
    (updatedProduct) => {
      if (entryOrder.products) {
        const products = entryOrder.products.map((product) => {
          if (product.positionOnList === updatedProduct.positionOnList) {
            return updatedProduct;
          }
          return product;
        });

        setEntryOrder((oldFields) => ({
          ...oldFields,
          products,
        }));
      }
    },
    [entryOrder],
  );

  const handleResetTaxesPrice = useCallback(
    (taxesFields) => {
      const { importTaxes, afrmmValue, accessories, customs } = taxesFields;

      const productsTotalQuantity = entryOrder.products
        .map((product) => product.entryQuantity)
        .reduce((total, quantity) => total + quantity, 0);

      const getAdditionPerUnity = (itemQuantity, additionValue) => {
        const percentage = itemQuantity / productsTotalQuantity;
        const additionPerItem = additionValue * percentage;
        return additionPerItem / itemQuantity;
      };

      const totalProductsValue = entryOrder.products.reduce(
        (total, product) => total + product.entryUnitaryCost * product.entryQuantity,
        0,
      );

      const updatedProducts = entryOrder.products.map((product) => {
        const othersCostTotalValue = importTaxes + accessories + customs;
        const othersCostRate = (othersCostTotalValue / totalProductsValue) * 100;

        const productWithCalculatedValues = {
          ...product,
          othersCostRate,
          priceConfirmed: false,
          importData: {
            ...product.importData,
            afrmmValue: getAdditionPerUnity(product.entryQuantity, afrmmValue),
          },
        };

        const calculateCost = calculateProductCost(productWithCalculatedValues);
        return { ...productWithCalculatedValues, ...calculateCost };
      });

      setEntryOrder((oldFields) => ({
        ...oldFields,
        products: updatedProducts,
      }));
    },
    [entryOrder.products],
  );

  const handleChangeTaxes = useCallback(
    (taxesFields) => {
      handleResetTaxesPrice(taxesFields);

      return setEntryOrder((oldFields) => ({
        ...oldFields,
        taxes: taxesFields,
      }));
    },
    [handleResetTaxesPrice],
  );

  const handleResetShippingPrice = useCallback(
    (shippingValue) => {
      const totalProductsValue = entryOrder.products.reduce(
        (total, product) => total + product.entryUnitaryCost * product.entryQuantity,
        0,
      );

      const updatedProducts = entryOrder.products.map((product) => {
        const shippingRate = (shippingValue / totalProductsValue) * 100;

        const productWithCalculatedValues = {
          ...product,
          shippingRate,
          priceConfirmed: false,
        };

        const calculateCost = calculateProductCost(productWithCalculatedValues);
        return { ...productWithCalculatedValues, ...calculateCost };
      });

      setEntryOrder((oldFields) => ({
        ...oldFields,
        products: updatedProducts,
      }));
    },
    [entryOrder.products],
  );

  const handleChangeShipping = useCallback(
    (shippingFields) => {
      const { value, insurance, volume, grossWeight, netWeight, payer, company } = shippingFields;
      handleResetShippingPrice(value + insurance);

      let pessoa = {};
      let shipping = {};

      if (company?.userId) {
        if (company.documents.pessoa === 'PJ') {
          pessoa = {
            cnpj: company.documents.cnpj,
            ie: company.documents.inscricaoEstadual,
          };
        } else {
          pessoa = {
            cpf: company.documents.cpf,
          };
        }

        shipping = {
          ...shipping,
          company: {
            ...pessoa,
            name: company.documents.razaoSocial || company.name,
            street: company.addresses[0]
              ? `${company.addresses[0].street}, ${company.addresses[0].streetNumber}, ${company.addresses[0].complement}`
              : '',
            city: company.addresses[0]?.city || '',
            uf: company.addresses[0]?.state || '',
            cep: company.addresses[0]?.postalCode || '',
          },
        };
      }

      shipping = {
        ...shipping,
        payer,
        value,
        insurance,
        volume,
        grossWeight,
        netWeight,
      };

      return setEntryOrder((oldFields) => ({
        ...oldFields,
        shipping,
      }));
    },
    [handleResetShippingPrice],
  );

  const handleUploadXml = useCallback(async (event) => {
    try {
      setLoadingUploadXml(true);
      const formData = new FormData();
      formData.append('xml', event.target.files[0]);

      const response = await Axios.post('/entries/xml', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      setLoadingUploadXml(false);
      const xmlResult = response.data;

      if (!xmlResult.supplier.cnpj) {
        return setSnackbar({
          message: 'O XML está sem CNPJ, importe em um novo pedido',
          open: true,
          type: 'error',
        });
      }

      if (entryOrder.supplier.cnpj) {
        if (xmlResult.supplier.cnpj !== entryOrder.supplier.cnpj) {
          return setSnackbar({
            message: 'O CNPJ do XML é diferente do pedido',
            open: true,
            type: 'error',
          });
        }
      }

      return setEntryOrder((oldFields) => ({ ...oldFields, ...xmlResult }));
    } catch (error) {
      setLoadingUploadXml(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Um erro ocorreu ao processar o XML.',
        error,
      });
    }
  }, []);

  const handleSaveEntryOrder = useCallback(async () => {
    try {
      let entryOrderCreated = {};

      if (entryOrder.entryOrderId) {
        const response = await Axios.put('/entries', entryOrder);
        entryOrderCreated = response.data;
      } else {
        const response = await Axios.post('/entries', entryOrder);
        entryOrderCreated = response.data;
      }

      setEntryOrder((oldFields) => ({ ...oldFields, ...entryOrderCreated }));
      setInitialEntryOrder((oldFields) => ({ ...oldFields, ...entryOrderCreated }));
      handleRefresh();

      return setSnackbar({
        message: `Pedido salvo com sucesso`,
        open: true,
        type: 'success',
      });
    } catch (error) {
      setLoadingUploadXml(false);
      return formatHandleError({
        setSnackbar,
        defaultMessage: 'Um erro ocorreu ao tentar salvar o pedido.',
        error,
      });
    }
  }, [entryOrder]);

  const [companyManagerTask, setCompanyManagerTask] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (loggedUser) {
        const tasksStaff = await getTasks({ userForm: loggedUser });

        const creditManager = tasksStaff.find((task) => task.name === 'Gerenciar financeiro');
        if (creditManager && creditManager.active) {
          setCompanyManagerTask(creditManager.active);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Drawer
      sx={{
        zIndex: 1300,
      }}
      anchor="right"
      open={openProductEntry}
      onClose={handleCloseProductEntry}
    >
      <Box marginLeft={2} marginTop={2} marginRight={2} marginBottom={12} className={classes.box}>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <TopTab
              entryOrderId={entryOrder.entryOrderId}
              type={entryOrder.type}
              company={entryOrder.company}
              handleChangeCompany={handleChangeCompany}
              handleChangeType={handleChangeType}
              setSelectedCompany={setSelectedCompany}
            />
          </Grid>
          <Grid item>
            <StatusTab
              status={entryOrder.status}
              statusPending={entryOrder.statusPending}
              handleUploadXml={handleUploadXml}
              invoice={entryOrder.invoice}
            />
          </Grid>
          <CustomDivider />
          <Grid item xs={12}>
            <ProductsTab
              entryOrder={entryOrder}
              setEntryOrder={setEntryOrder}
              saveProductCost={saveProductCost}
              blockFields={blockFields}
            />
          </Grid>
          <CustomDivider />
          <Grid item xs={12}>
            <SupplierTab
              supplier={entryOrder.supplier}
              supplierRolesFilters={supplierRolesFilters}
              setEntryOrder={setEntryOrder}
              blockFields={blockFields}
            />
          </Grid>
          {entryOrder.supplier?.cnpj && entryOrder.type === 'Importação para revenda' ? (
            <Grid item>
              <CustomInput
                label="Observações na nota fiscal"
                value={entryOrder.invoice?.obs || ''}
                onChange={(event) =>
                  setEntryOrder((oldFields) => ({
                    ...oldFields,
                    invoice: { ...oldFields.invoice, obs: event.target.value },
                  }))
                }
                variant="outlined"
                disabled={entryOrder.invoice?.status === 'aprovado'}
              />
            </Grid>
          ) : null}
          <CustomDivider />
          <Grid item xs={12}>
            <TaxesTab taxes={entryOrder.taxes} handleChangeTaxes={handleChangeTaxes} />
          </Grid>
          <CustomDivider />
          <Grid item xs={12}>
            <ShippingTab
              shipping={entryOrder.shipping}
              handleChangeShipping={handleChangeShipping}
            />
          </Grid>
          <CustomDivider />
          <Grid item xs={12}>
            <PaymentsTab
              products={entryOrder.products}
              payments={entryOrder.payments}
              totalValueProducts={entryOrder.totalValueProducts}
              totalValue={entryOrder.totalValue}
              setEntryOrder={setEntryOrder}
              blockFields={false}
            />
          </Grid>
          <CustomDivider />
          {selectedCompany && checkValidCpfCnpj(selectedCompany.cnpj) ? (
            <Grid item>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={entryOrder.onlyCompanyStock || false}
                        onChange={() =>
                          setEntryOrder((oldFields) => ({
                            ...oldFields,
                            onlyCompanyStock: !entryOrder.onlyCompanyStock,
                          }))
                        }
                        checked={entryOrder.onlyCompanyStock || false}
                        disabled={!companyManagerTask}
                        color="secondary"
                      />
                    }
                    label={`Somente ${capitalizeString(
                      extractFirstAndLastName(selectedCompany.name),
                    )}`}
                  />
                </Grid>
                {entryOrder.entryOrderId && entryOrder.type === 'Importação para revenda' ? (
                  <Grid item>
                    <EntryInvoiceEmissionButton
                      entryOrder={entryOrder}
                      setEntryOrder={setEntryOrder}
                      setInitialEntryOrder={setInitialEntryOrder}
                      setSnackbar={setSnackbar}
                      previewOnly={entryOrder.status === 'Orçamento'}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <SubmitArea
        selectedCompany={selectedCompany}
        entryOrder={entryOrder}
        initialEntryOrder={initialEntryOrder}
        setEntryOrder={setEntryOrder}
        setInitialEntryOrder={setInitialEntryOrder}
        handleSaveEntryOrder={handleSaveEntryOrder}
        handleCloseProductEntry={handleCloseProductEntry}
        handleRefresh={handleRefresh}
        setSnackbar={setSnackbar}
      />
      <SimpleBackdrop loading={loadingUploadXml} />
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={12000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </Drawer>
  );
}

export default ProductEntry;
