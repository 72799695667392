import React, { useState, useEffect } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Axios from 'axios';
import { Alert, Snackbar } from '@mui/material';
import EmailsTopBar from './TopBar';
import TemplatesTable from './TemplatesTable';
import EmailDialog from './EmailDialog';
import UsersTable from './UsersTable';
import SimpleDialog from '../Common/SimpleDialog';
import newTemplateEmail from '../../constant/newTemplateEmail';
import { formatHandleError } from '../../helpers/formatData';

function EmailsPage() {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    type: 'info',
  });
  const handleCloseSnackbar = () => {
    setSnackbar((oldState) => ({
      ...oldState,
      open: false,
    }));
  };

  const [fieldsTemplate, setFieldsTemplate] = useState();
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const handleOpenEmailDialog = (template) => {
    setOpenEmailDialog(true);
    setFieldsTemplate(template);
  };
  const handleCloseEmailDialog = () => setOpenEmailDialog(false);

  const handleOpenNewEmailDialog = () => {
    setOpenEmailDialog(true);
    setFieldsTemplate(newTemplateEmail);
  };

  const [openUsersTable, setOpenUsersTable] = useState(false);
  const handleOpenUsersTable = () => setOpenUsersTable(true);
  const handleCloseUsersTable = () => setOpenUsersTable(false);
  const [selectedUsersEmails, setSelectedUsersEmails] = useState([]);

  const [openDialogConfirmSendEmail, setOpenDialogConfirmSendEmail] = useState(false);
  const handleOpenDialogConfirmSendEmail = (emailsToSend) => {
    setSelectedUsersEmails(emailsToSend);
    setOpenDialogConfirmSendEmail(true);
  };
  const handleCloseDialogConfirmSendEmail = () => {
    setOpenDialogConfirmSendEmail(false);
  };

  const handleSendEmail = async () => {
    handleCloseDialogConfirmSendEmail();

    try {
      await Axios.post('/emails/sendEmailMarketing', {
        emails: selectedUsersEmails,
        templateEmailId: fieldsTemplate,
      });

      setSnackbar({
        open: true,
        message: 'E-mail enviado com sucesso!',
        type: 'success',
      });
    } catch (error) {
      formatHandleError({
        setSnackbar,
        defaultMessage: 'Ocorreu algum erro ao enviar o e-mail',
        error,
      });
    }
  };

  const [fabButtons, setFabButtons] = useState([]);

  useEffect(() => {
    const fabButtonsToShow = [
      {
        title: 'Novo Template',
        onClick: handleOpenNewEmailDialog,
        icon: <AddIcon />,
        featured: true,
      },
    ];
    setFabButtons(fabButtonsToShow);
  }, []);

  return (
    <>
      <EmailsTopBar />
      <TemplatesTable
        fabButtons={fabButtons}
        handleOpenUsersTable={handleOpenUsersTable}
        handleOpenEmailDialog={handleOpenEmailDialog}
        setFieldsTemplate={setFieldsTemplate}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        handleCloseSnackbar={handleCloseSnackbar}
      />
      {openEmailDialog ? (
        <EmailDialog
          openEmailDialog={openEmailDialog}
          handleOpenEmailDialog={handleOpenEmailDialog}
          handleCloseEmailDialog={handleCloseEmailDialog}
          fieldsTemplate={fieldsTemplate}
          setFieldsTemplate={setFieldsTemplate}
        />
      ) : null}
      {openUsersTable ? (
        <UsersTable
          openUsersTable={openUsersTable}
          handleOpenUsersTable={handleOpenUsersTable}
          handleCloseUsersTable={handleCloseUsersTable}
          handleOpenDialogConfirmSendEmail={handleOpenDialogConfirmSendEmail}
        />
      ) : null}
      {openDialogConfirmSendEmail ? (
        <SimpleDialog
          openDialog={openDialogConfirmSendEmail}
          handleClose={handleCloseDialogConfirmSendEmail}
          dialogTitle="Enviar E-mail"
          dialogText="Confirma o envio do e-mail? (A ação não poderá ser desfeita)"
          cancelButtonText="Cancelar"
          confirmButtonText="Confirmar"
          handleCancelButton={handleCloseDialogConfirmSendEmail}
          handleConfirmButton={handleSendEmail}
        />
      ) : null}
      {snackbar.open && (
        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={snackbar.type}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default EmailsPage;
