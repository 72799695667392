import Axios from 'axios';
import { Box, Dialog, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { searchProducts } from '../../../services/melhorGestao/products';
import SimpleBackdrop from '../../Common/SimpleBackdrop';
import TableProductsModal from './TableProductsModal';
import { formatProductToOrder } from '../../../helpers/formatData';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';
import UserAutocomplete from '../../Common/UserAutocomplete';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import filterProductStatusInitial from '../../../constant/filterProductStatusInitial';
import filterProductOptionsInitial from '../../../constant/filterProductOptionsInitial';

const useStyles = makeStyles({
  paper: {
    height: '90vh',
  },
});

function ProductsModal({
  openProductsModal,
  handleCloseProductsModal,
  handleConfirmSelect,
  productsToDevolution,
  alreadySelectedProducts,
  userItemsBought,
  orderSource,
  editQuantityAndPrice = true,
}) {
  const classes = useStyles();
  const [submitedSearchText, setSubmitedSearchText] = useState('');
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [countProducts, setProductsCount] = useState(0);
  const [alreadySelectedProductsList, setAlreadySelectedProductsList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);

  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [filtersStatus, setFiltersStatus] = useState(filterProductStatusInitial);
  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);

  useEffect(() => {
    if (productsToDevolution) {
      setProductsList(productsToDevolution);
    } else {
      searchProducts({
        searchText: submitedSearchText,
        skip: page * limit - limit,
        limit,
        sortDirection: 'asc',
        setLoadingProductsList,
        setProductsList,
        setProductsCount,
        filterByCompany: selectedCompany?._id || selectedCompany,
        filterBySupplier: selectedSupplier?.userId,
        filtersOptions: [...filtersOptions, ...filtersStatus],
      });
    }
  }, [
    page,
    limit,
    selectedCompany,
    selectedSupplier,
    filtersOptions,
    filtersStatus,
    submitedSearchText,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (alreadySelectedProducts && alreadySelectedProducts.length > 0) {
        const productsIds = alreadySelectedProducts.map((product) => product.productId);

        const response = await Axios.get('/catalog/products/list/id', {
          params: {
            productsIds,
          },
        });

        const sortedProductsList = response.data.productsList
          .map((product) => ({
            ...product,
            positionOnList: productsIds.indexOf(product.productId),
          }))
          .sort((a, b) => a.positionOnList - b.positionOnList);

        setAlreadySelectedProductsList(
          sortedProductsList.map((product) => {
            let positionOnList = {};

            if (product.positionOnList) {
              positionOnList = {
                positionOnList: product.positionOnList,
              };
            }

            const { quantity, selectedPrice } = alreadySelectedProducts.find(
              (productFind) => product.productId === productFind.productId,
            );

            return {
              ...formatProductToOrder(product),
              ...positionOnList,
              ...(selectedPrice ? { selectedPrice } : { selectedPrice: product.price }),
              quantity,
            };
          }),
        );
      }
    };
    fetchData();
  }, []);

  const handleChangePage = useCallback(
    (event, value) => {
      setPage(value);
    },
    [limit, page, productsList],
  );

  useEffect(() => {
    setPage(1);
  }, [selectedCompany, selectedSupplier, filtersOptions, filtersStatus]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openProductsModal}
      onClose={handleCloseProductsModal}
      classes={{ paper: classes.paper }}
    >
      <Box margin={2}>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Box width={200}>
                <CompanyAutocomplete
                  handleOnChange={(_, newValue) => setSelectedCompany(newValue)}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                  variant="filled"
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={200}>
                <UserAutocomplete
                  handleOnChange={(_, newValue) => {
                    setSelectedSupplier(newValue);
                  }}
                  selectedUser={selectedSupplier}
                  setSelectedUser={setSelectedSupplier}
                  label="Fornecedor"
                  variant="filled"
                  roles={['supplier']}
                  className={classes.selectBackground}
                />
              </Box>
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Estoque"
                filtersOptions={filtersOptions}
                setFiltersOptions={setFiltersOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Status"
                filtersOptions={filtersStatus}
                setFiltersOptions={setFiltersStatus}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableProductsModal
          productsList={productsList}
          countProducts={countProducts}
          handleChangePage={handleChangePage}
          page={page}
          limit={limit}
          handleConfirmSelect={handleConfirmSelect}
          productsToDevolution={productsToDevolution}
          alreadySelectedProducts={alreadySelectedProductsList}
          onClose={handleCloseProductsModal}
          submitSearchProducts={setSubmitedSearchText}
          userItemsBought={userItemsBought}
          orderCompany={selectedCompany}
          orderSource={orderSource}
          editQuantityAndPrice={editQuantityAndPrice}
        />
      </Box>
      <SimpleBackdrop loading={loadingProductsList} />
    </Dialog>
  );
}

export default memo(ProductsModal);
