import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { formatDateWithHour, numberToRealWithPeriod } from '../../helpers/formatData';
import InvoiceInquiryLink from '../Common/InvoiceInquiryLink';
import LimitTypography from '../Common/LimitTypography';
import SimpleBackdrop from '../Common/SimpleBackdrop';
import OrderStatusTypography from '../Orders/elements/OrderStatusTypography';
import OrderCompanyLogo from '../Orders/elements/OrderCompanyLogo';
import CustomPagination from '../Common/CustomPagination';

const useStyles = makeStyles((theme) => ({
  headTableCellId: {
    width: 65,
  },
  headTableCellCompany: {
    width: 65,
  },
  headTableCellType: {
    width: 100,
  },
  companyBadge: {
    width: 35,
  },
  typographyCursor: {
    cursor: 'pointer',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  tableRowSelected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

function TableEntries({
  entriesList,
  countEntries,
  selectEntryOrder,
  selectedEntryOrder,
  loadingEntriesList,
  handleChangePage,
  page,
  limit,
}) {
  const classes = useStyles();

  const getTableRowColor = (entryOrderSelected, entry) =>
    entryOrderSelected && entryOrderSelected.entryOrderId === entry.entryOrderId
      ? classes.tableRowSelected
      : classes.cursorPointer;

  return (
    <Box position="relative" marginBottom={5}>
      <SimpleBackdrop loading={loadingEntriesList} absolutePosition />

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headTableCellId} align="center">
                  Código
                </TableCell>
                <TableCell className={classes.headTableCellCompany} align="center">
                  Empresa
                </TableCell>
                <TableCell className={classes.headTableCellType} align="center">
                  Tipo
                </TableCell>
                <TableCell>Fornecedor</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>NF-e</TableCell>
                <TableCell>Situação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entriesList.length > 0 &&
                entriesList.map((entry) => (
                  <TableRow
                    key={entry._id}
                    onClick={() => selectEntryOrder(entry)}
                    className={getTableRowColor(selectedEntryOrder, entry)}
                  >
                    <TableCell align="center">{entry.entryOrderId}</TableCell>
                    <TableCell align="center">
                      <OrderCompanyLogo orderCompany={entry.company} />
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="caption">{entry.type}</Typography>
                    </TableCell>
                    <TableCell>
                      <Grid container direction="column">
                        {entry.supplier.name !== entry.supplier.razaoSocial ? (
                          <Grid item>
                            <LimitTypography
                              style={{ fontSize: 11, color: 'grey' }}
                              text={entry.supplier.razaoSocial}
                              maxLength={70}
                            />
                          </Grid>
                        ) : null}
                        <Grid item>
                          <LimitTypography
                            style={!entry.supplier.name ? { color: '#ff2222' } : null}
                            text={entry.supplier.name || 'FORNECEDOR NÃO INFORMADO'}
                            maxLength={60}
                            variant="caption"
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{`R$ ${numberToRealWithPeriod(entry.totalValue)}`}</TableCell>
                    <TableCell>{formatDateWithHour(entry.updatedAt)}</TableCell>
                    <TableCell>
                      <InvoiceInquiryLink invoice={entry.invoice} />
                    </TableCell>
                    <TableCell>
                      <OrderStatusTypography orderStatus={entry.status || 'Pedido concluído'} />
                      <Typography variant="caption">{entry.statusPending || ''}</Typography>
                    </TableCell>
                  </TableRow>
                ))}

              {page !== 1 && entriesList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography
                          onClick={() => handleChangePage()}
                          className={classes.typographyCursor}
                        >
                          Nenhum pedido nesta página, clique aqui para voltar a página.
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}

              {page === 1 && entriesList.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item>
                        <Typography>
                          {loadingEntriesList ? 'Carregando' : 'Nenhum pedido para exibir'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10}>
                  <CustomPagination
                    page={page}
                    total={countEntries}
                    limit={limit}
                    handleChangePage={handleChangePage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

export default memo(TableEntries);
