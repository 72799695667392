import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrintIcon from '@mui/icons-material/Print';
import React, { memo, useEffect, useState } from 'react';
import getModifiedKeys from '../../../../helpers/getModifiedKeys';
import { getProducts } from '../../../../services/melhorGestao/products';
import ChangeOrderStatusButton from './ChangeOrderStatusButton';
import SimpleDialog from '../../../Common/SimpleDialog';
import PrintQuote from '../../../Products/Dialogs/QuoteProductDialog/PrintQuote';

const useStyles = makeStyles((theme) => ({
  submitArea: {
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    bottom: 0,
    width: 662,
    height: 70,
  },
  containerSubmitArea: {
    height: 70,
  },
}));

function SubmitArea({
  selectedCompany,
  entryOrder,
  initialEntryOrder,
  setEntryOrder = () => {},
  setInitialEntryOrder = () => {},
  handleSaveEntryOrder,
  handleCloseProductEntry,
  handleRefresh = () => {},
  setSnackbar = () => {},
}) {
  const classes = useStyles();

  const [productsToPrintPdf, setProductsToPrintPdf] = useState([]);
  const [openDialogPrintPdf, setOpenDialogPrintPdf] = useState(false);

  const handleOpenDialogPrintPdf = async () => {
    const updatedProducts = await getProducts({
      limit: entryOrder.products.length,
      productsIds: entryOrder.products.map((productEntry) => productEntry.sincProductId),
      allProducts: true,
    });

    setProductsToPrintPdf(
      entryOrder.products.map((productEntry) => {
        const getUpdatedProduct = updatedProducts.find(
          (updatedProduct) => updatedProduct.productId === productEntry.sincProductId,
        );

        const onlySelectedSupplier = getUpdatedProduct?.suppliers?.find(
          (productSupplier) => productSupplier.factoryId === productEntry.supplierId,
        );

        return {
          ...productEntry,
          productId: getUpdatedProduct?.productId,
          images: getUpdatedProduct?.images,
          technicalName: getUpdatedProduct?.technicalName,
          supplierOwnId: onlySelectedSupplier?.factoryId,
          suppliers: getUpdatedProduct?.suppliers || [],
        };
      }),
    );

    setOpenDialogPrintPdf(true);
  };

  const handleCloseDialogPrintPdf = () => setOpenDialogPrintPdf(false);

  const [blockSave, setBlockSave] = useState(true);
  const [blockSaveMessage, setBlockSaveMessage] = useState('');

  useEffect(() => {
    if (!entryOrder.company) {
      setBlockSaveMessage('Escolha a empresa que fará a compra');
      return setBlockSave(true);
    }
    if (!entryOrder.type) {
      setBlockSaveMessage('Escolha o tipo de pedido');
      return setBlockSave(true);
    }
    if (entryOrder.products && entryOrder.products.length === 0) {
      setBlockSaveMessage('Escolha os produtos');
      return setBlockSave(true);
    }
    if (getModifiedKeys(entryOrder, initialEntryOrder).length === 0) {
      setBlockSaveMessage('Nenhuma alteração foi feita');
      return setBlockSave(true);
    }
    setBlockSaveMessage('Salvar o pedido');
    return setBlockSave(false);
  }, [entryOrder, initialEntryOrder]);

  const [blockButtonStatus, setBlockButton] = useState(true);
  const [blockButtonStatusMessage, setBlockButtonStatusMessage] = useState('');

  useEffect(() => {
    if (entryOrder.entryOrderId) {
      if (entryOrder.status === 'Orçamento') {
        if (!(entryOrder.supplier && entryOrder.supplier.name)) {
          setBlockButtonStatusMessage('Escolha um fornecedor');
          return setBlockButton(true);
        }
        if (!(entryOrder.shipping && entryOrder.shipping.payer)) {
          setBlockButtonStatusMessage('Escolha a entrega');
          return setBlockButton(true);
        }
        if (entryOrder.payments && entryOrder.payments.length === 0) {
          setBlockButtonStatusMessage('Adicione o pagamento');
          return setBlockButton(true);
        }
      }
      if (entryOrder.status === 'Pedido confirmado') {
        if (entryOrder.type !== 'Ativo imobilizado') {
          if (!(entryOrder.invoice && entryOrder.invoice.key)) {
            setBlockButtonStatusMessage('Adicione o XML para faturar o pedido');
            return setBlockButton(true);
          }
        }
        if (!entryOrder.products.every((product) => product.priceConfirmed === true)) {
          setBlockButtonStatusMessage('Confirme o preço dos produtos');
          return setBlockButton(true);
        }
        if (!entryOrder.products.every((product) => product.entryUnityId && product.unityId)) {
          setBlockButtonStatusMessage('Selecione as unidades de conversão de estoque nos produtos');
          return setBlockButton(true);
        }
      }
      if (blockSave) {
        setBlockButtonStatusMessage('Alterar status do pedido');
        return setBlockButton(false);
      }
    }

    setBlockButtonStatusMessage('Salve o pedido primeiro');
    return setBlockButton(true);
  }, [entryOrder, blockSave]);

  return (
    <Paper className={classes.submitArea} elevation={5}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.containerSubmitArea}
      >
        <Grid item>
          <Box marginLeft={2}>
            <Button onClick={handleCloseProductEntry} variant="contained" color="default">
              Fechar
            </Button>
          </Box>
        </Grid>
        <Tooltip title={<Typography align="center">{blockButtonStatusMessage}</Typography>}>
          <Grid item>
            <ChangeOrderStatusButton
              entryOrder={entryOrder}
              blockButton={blockButtonStatus}
              setEntryOrder={setEntryOrder}
              setInitialEntryOrder={setInitialEntryOrder}
              handleRefresh={handleRefresh}
              setSnackbar={setSnackbar}
            />
          </Grid>
        </Tooltip>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Tooltip
              title={
                <Typography align="center">
                  {entryOrder.products.length === 0
                    ? 'Para imprimir, você precisa de selecionar os produtos.'
                    : null}
                  {entryOrder.products.length > 0 ? 'Imprimir produtos do pedido' : null}
                </Typography>
              }
            >
              <Grid item>
                <IconButton
                  onClick={handleOpenDialogPrintPdf}
                  disabled={!selectedCompany || entryOrder.products.length === 0}
                >
                  <PrintIcon />
                </IconButton>
              </Grid>
            </Tooltip>
            <Tooltip title={<Typography align="center">{blockSaveMessage}</Typography>}>
              <Grid item>
                <Box marginRight={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={blockSave}
                    onClick={handleSaveEntryOrder}
                  >
                    Salvar
                  </Button>
                </Box>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      {openDialogPrintPdf && (
        <SimpleDialog
          openDialog={openDialogPrintPdf}
          handleClose={handleCloseDialogPrintPdf}
          dialogTitle="Imprimir cotação"
          content={
            <PrintQuote
              selectedCompany={selectedCompany}
              selectedProducts={productsToPrintPdf}
              orderId={entryOrder.entryOrderId}
            />
          }
        />
      )}
    </Paper>
  );
}
export default memo(SubmitArea);
