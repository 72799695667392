import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { useAuth } from './contexts/auth';
import SalesResume from './components/SalesResume';
import Products from './components/Products';
import MarketplacesAds from './components/MarketplacesAds';
import Orders from './components/Orders';
import UsersPage from './components/Users/Page';
import Settings from './components/Settings';
import Login from './components/Login';
import Payments from './components/Payments';
import Reports from './components/Reports';
import OrdersStatusResume from './components/OrdersStatusResume';
import Finances from './components/Finances';
import FinanceResume from './components/Finances/FinanceResume';
import WhatsApp from './components/WhatsApp';
import EmailMarketing from './components/EmailMarketing';
import ProductEntries from './components/ProductEntries';
import Header from './components/Header';
import FinanceResumeCategories from './components/Finances/FinanceResume/Categories';

function Pages() {
  const { signed } = useAuth();

  return (
    <BrowserRouter>
      <Box>
        {signed && <Header />}

        {signed && (
          <Container>
            <Routes>
              <Route path="/" element={<OrdersStatusResume />} />
              <Route path="/produtos" element={<Products />} />
              <Route path="/produtos/entradas" element={<ProductEntries />} />
              <Route path="/mercado-livre" element={<MarketplacesAds />} />
              <Route path="/pedidos" element={<Orders />} />
              <Route path="/vendas" element={<SalesResume />} />
              <Route path="/pagamentos" element={<Payments />} />
              <Route path="/relatorios" element={<Reports />} />
              <Route path="/usuarios" element={<UsersPage />} />
              <Route path="/configuracoes" element={<Settings />} />
              <Route path="/financeiro" element={<Finances />} />
              <Route path="/financeiro/resumo" element={<FinanceResume />} />
              <Route path="/financeiro/resumo/categorias" element={<FinanceResumeCategories />} />
              <Route path="/whatsapp" element={<WhatsApp />} />
              <Route path="/emailmarketing" element={<EmailMarketing />} />
            </Routes>
          </Container>
        )}

        {!signed && (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </Box>
    </BrowserRouter>
  );
}

export default Pages;
