import { Box, Grid, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import SearchField from '../../Common/SearchField';
import CityAutocomplete from '../../Common/CityAutocomplete';

const useStyles = makeStyles((theme) => ({
  topBarTitle: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  selectBackground: {
    backgroundColor: theme.palette.common.white,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
}));

function UsersTopBar({
  submitSearch,
  filtersOptions,
  setFiltersOptions,
  rolesFilters,
  setRolesFilters,
  tagsOptions,
  setTagsOptions,
  selectedCity,
  setSelectedCity = () => {},
  hiddenTitle,
}) {
  const classes = useStyles();

  return (
    <Toolbar>
      <Grid container direction="row" spacing={1} marginTop={1}>
        {!hiddenTitle ? (
          <Grid item xs={1}>
            <Typography variant="h5" className={classes.topBarTitle}>
              Usuários
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={!hiddenTitle ? 11 : 12}>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar clientes com"
                filtersOptions={filtersOptions}
                setFiltersOptions={setFiltersOptions}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar por papéis"
                filtersOptions={rolesFilters}
                setFiltersOptions={setRolesFilters}
              />
            </Grid>
            <Grid item>
              <FilterSelectOptions
                inputLabel="Filtrar por tags"
                filtersOptions={tagsOptions}
                setFiltersOptions={setTagsOptions}
                multiple={false}
              />
            </Grid>
            <Grid item>
              <Box width={180} className={classes.selectBackground}>
                <CityAutocomplete
                  variant="filled"
                  selectedCity={selectedCity}
                  handleOnChange={(_, newValue) => setSelectedCity(newValue)}
                />
              </Box>
            </Grid>
            <Grid item>
              <SearchField submitSearch={submitSearch} labelSearch="Pesquisar usuário" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default UsersTopBar;
